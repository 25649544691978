import { UrlConstants } from "../../global/UrlConstants";
import { getRequest } from "../../global/utils";

export const getHarwareCountsAPI = () => {
    const url = `${UrlConstants.baseUrl}/getDashboard/hardwareCount/loggedInUserId/${localStorage.getItem("id")}`;
    return getRequest(url)
}

export const getNewsAPI = () => {
    const url = `${UrlConstants.baseUrl}/getNews/loggedInUserId/${localStorage.getItem("id")}`
    return getRequest(url)
}

export const getTicketCountsAPI = () => {
    const url = `${UrlConstants.baseUrl}/getDashboard/count/loggedInUserId/${localStorage.getItem("id")}`
    return getRequest(url)
}
