import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { approveTicketByAdminInBulkAPI, approveTicketByAeitInBulkAPI } from "./QuaterlyReportService";

interface CustomProps {
  dateRange?: any[];
  circleOptions?: any[];
  handleSLALocationChange?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "&&": {
        marginTop: "0.7rem",
        marginBottom: "0.7rem",
        marginRight: "0.6rem",
      },
      backgroundColor: "#FFFFFF",
    },
    TypographyHeading: {
      color: "black",
      paddingTop: "1.2rem",
      paddingBottom: "0.7rem",
      paddingLeft: "2rem",
      textAlign: "left",
    },
    Typography: {
      color: "black",
      paddingTop: "0.3rem",
      paddingLeft: "0.5rem",
      textAlign: "left",
    },
    input: {
      minWidth: 290,
      minHeight: 20,
    },
    select: {
      marginTop: "0.7rem",
      width: 270,
      height: 40,
      marginRight: 10,
    },
    mainBox: {
      backgroundColor: "#f2f1ed",
    },
    root: {
      justifyContent: "center",
      width: 360,
      marginLeft: "35rem",
      alignContent: "left",
    },
  })
);

export default function SLAVerification(props: CustomProps) {
  const classes = useStyles();
  const storage = localStorage;
  const [verifyAll, setVerifyAll] = useState(false);
  const [remarks, setRemarks] = useState();
  const [selectedCircle, setSelectedCircle] = useState();
  const isAeit = localStorage.getItem("role") === "aeit";
  const handleChange = (event: any) => {
    setVerifyAll(event.target.checked);
  };

  const handleSubmit = (e: any) => {
    if (remarks && (verifyAll || selectedCircle) && props?.dateRange) {
      if (isAeit) {
        const data = {
          approvedBy: storage.getItem("userName") ?? "",
          approvedByPhone: storage.getItem("phone") ?? "",
          approvedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
          remarks: remarks ?? "",
          otherStringData: storage.getItem("circle") ?? "",
        };
        e.preventDefault();
        const response = approveTicketByAeitInBulkAPI(props, data);
        response.then(function (response) {
          toast.success(
            `Ticket Verified of Date-Range ${props?.dateRange?.[0]} - ${props?.dateRange?.[1]}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        })
          .catch(function (error) {
            toast.error("Error while updating!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } else {
        const data = {
          approvedBy: storage.getItem("userName") ?? "",
          approvedByPhone: storage.getItem("phone") ?? "",
          approvedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
          remarks: remarks ?? "",
          otherStringData: selectedCircle ?? "",
        };
        e.preventDefault();
        const response = approveTicketByAdminInBulkAPI(props, data)
        response.then(function (response) {
          toast.success(
            `Ticket Verified of Date-Range ${props?.dateRange?.[0]} - ${props?.dateRange?.[1]}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        })
          .catch(function (error) {
            toast.error("Error while updating!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }

      setTimeout(() => window.location.reload(), 3000);
    } else {
      if(!verifyAll){
        toast.error("Please Check Verify All!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if(!remarks){
        toast.error("Please Add Remarks!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      
    }
  };

  const handleLocationChange = async (event: any) => {
    setSelectedCircle(event.target.value.toUpperCase());
    props?.handleSLALocationChange(event);
  };

  return (
    <>
      <Card
        sx={{ boxShadow: 15 }}
        style={{
          paddingTop: "2rem",
          width: "auto",
          height: "auto",
          backgroundColor: "#FFFFFF",
          color: "#141414",
          flexDirection: "column",
          padding: 1,
          cursor: "pointer",
        }}
      >
        <Grid item xs style={{ margin: "2rem", alignSelf: "left" }}>
          <Typography variant="h5">Tickets</Typography>
        </Grid>
        <Grid
          container
          spacing={0}
          style={{ padding: "2rem", alignContent: "center" }}
        >
          {isAeit ? (
            <>
              <FormGroup>
                <FormControlLabel
                  name="verifyAll"
                  disabled={
                    props?.dateRange?.[0] && props?.dateRange?.[1]
                      ? false
                      : true
                  }
                  control={
                    <Checkbox checked={verifyAll} onChange={handleChange} />
                  }
                  label="Verify All"
                />
              </FormGroup>
            </>
          ) : (
            <Grid>
              <select
                className={classes.select}
                id="circle"
                name="circle"
                onChange={handleLocationChange}
              >
                <option value="pleaseSelect">Please Select Circle</option>
                <option value="ALL">ALL</option>
                {props?.circleOptions?.map((x, y) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </Grid>
          )}

          <TextField
            className={classes.textField}
            required
            label="Remarks"
            InputLabelProps={{
              shrink: true,
            }}
            name="remarks"
            value={remarks}
            onChange={(e: any) => setRemarks(e.target.value)}
            size="small"
          />
          <Button
            // disabled={!remarks}
            style={{
              marginTop: "0.7rem",
              height: 40,
              color: "white",
              backgroundColor:
                remarks && verifyAll ? "green" : verifyAll ? "#ada75c" : "grey",
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Approve
          </Button>
        </Grid>
      </Card>
      <ToastContainer />
    </>
  );
}


