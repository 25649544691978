const sidebarStyles = {
  menuSliderContainer: {
    width: 250,
    background: "#FFFFFF",
    height: "100%",
  },
  avatar: {
    margin: "0.5rem auto",
  },
  listItem: {
    color: "#e03a3c",
  },
  iconButton: {
    fill: "#FFFFFF",
  },
};

export default sidebarStyles;
