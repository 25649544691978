import axios from "axios";
import { toast } from "react-toastify";

export const header = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

export const getRequest = async (url: string) => {
  const response = await axios.get(url, header());
  return response.data;
};

export const postRequest = async (url: string, data: any) => {
  const response = await axios.post(url, data, header());
  return response.data;
};

export const patchRequest = async (url: string, data: any) => {
  const response = await axios.patch(url, data, header());
  return response.data;
};

export const deleteRequest = async (url: string) => {
  const response = await axios.delete(url, header());
  return response.data;
};

export const downloadRequest = async (url: string, fileName: string) => {
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      fileName.split("_").slice(2).join("_").split("_")[0]
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const uploadRequest = async (url: string, data: any) => {
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const response = await axios.post(url, data, header);
  return response.data;
};

export const exportRequest = async (url: string, data: any) => {
  const response = await axios.post(url, data, {
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
};

export const successNotification = (message: string) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 15000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const errorNotification = (message: string) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

};
