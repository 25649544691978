import React from "react";
import { Route, Switch } from "react-router-dom";
import Tickets from "../../pvvnl/Tickets/Tickets";
import TicketsList from "../../pvvnl/Ticket/TicketsList";
import EditTicket from "../../pvvnl/Tickets/EditTicket/EditTicket";
import Employees from "../../pvvnl/Employee/Employees";
import AddEmployee from "../../pvvnl/Employee/AddEmployee/AddEmployee";
import LocationList from "../../pvvnl/Location/LocationList";
import Survey from "../../pvvnl/Survey/Survey";
import AddEditSurvey from "../../pvvnl/Survey/AddEditSurvey";
import AdminDashboard from "../../pvvnl/Dashboard/AdminDashboard";
import AeitDashboard from "../../pvvnl/Dashboard/AeitDashboard";
import FreezeItemListing from "../../pvvnl/FreezeItem/FreezeItemListing";
import AddEditFreezeItem from "../../pvvnl/FreezeItem/AddEditFreezeItem";
import AdminDocuments from "../../pvvnl/AdminDocuments/AdminDocuments";
import QuarterlyReport from "../../pvvnl/QuarterlyReport/QuarterlyReport";
import ChangeSelfPassword from "../../pvvnl/Employee/ChangeSelfPassword";
import Aiet from "../../pvvnl/Aeit/Aeit";
import AddAiet from "../../pvvnl/Aeit/AddAeit/AddAeit";
import SideBar from "../../pvvnl/SideBar/SideBar";

export default function PvvnlRoute() {
  return (
    <>
      <SideBar />
      <div style={{ padding: 20 }}>
        <Switch>
          <Route exact path={"/tickets"} component={Tickets} />
          <Route exact path={"/ticketsList"} component={TicketsList} />
          <Route exact path={"/edit/:id"} component={EditTicket} />
          <Route exact path={"/employees"} component={Employees} />
          <Route exact path={"/addEmployee"} component={AddEmployee} />
          <Route exact path={"/pivotTable"} component={LocationList} />
          <Route exact path={"/survey"} component={Survey} />
          <Route exact path={"/addEditSurvey"} component={AddEditSurvey} />
          <Route exact path={"/adminDashboard"} component={AdminDashboard} />
          <Route exact path={"/AeitDashboard"} component={AeitDashboard} />
          <Route exact path={"/Aeit"} component={Aiet} />
          <Route exact path={"/addAeit"} component={AddAiet} />
          <Route exact path={"/freezeItems"} component={FreezeItemListing} />
          <Route
            exact
            path={"/addEditFreezeItem"}
            component={AddEditFreezeItem}
          />
          <Route exact path={"/surveyPDF"} component={AdminDocuments} />
          <Route exact path={"/quaterlyReport"} component={QuarterlyReport} />
          <Route
            exact
            path={"/changePassword"}
            component={ChangeSelfPassword}
          />
        </Switch>
      </div>
    </>
  );
}
