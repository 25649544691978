import axios from "axios";
import { UrlConstants } from "../../../global/UrlConstants";
import { getRequest, patchRequest, postRequest } from "../../../global/utils";

export const getAllCitiFromSurveyAPI = () => {
    const url = `${UrlConstants.baseUrl}/getAllCitiFromSurvey`
    return getRequest(url)
}

export const addEmployeeAPI = (employeeData: { id: any; name: any; phone: any; city: any; password: any; role: any; status: any; }) => {
    const url = `${UrlConstants.baseUrl}/addEmployee/loggedInUserId/${localStorage.getItem("id")}`
    const data = employeeData
    return postRequest(url, data)
}

export const updateEmployeeAPI = (employeeData: { id: any; name: any; phone: any; city: any; password: any; role: any; status: any; }) => {
    const url = `${UrlConstants.baseUrl}/updateEmployee/loggedInUserId/${localStorage.getItem("id")}`;
    const data = employeeData;
    return patchRequest(url, data)
}

