import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  getAllCirclesAPI,
  getAllDivisionByCircleAPI,
} from "./AddressComponentService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TypographyHeading: {
      color: "black",
      paddingTop: "1.2rem",
      paddingBottom: "0.7rem",
      paddingLeft: "2rem",
      textAlign: "left",
    },
    Typography: {
      color: "black",
      paddingTop: "0.3rem",
      paddingLeft: "0.5rem",
      textAlign: "left",
    },
    input: {
      minWidth: 290,
      minHeight: 20,
    },
    select: {
      width: 295,
      height: 27,
    },
    mainBox: {
      backgroundColor: "#f2f1ed",
    },
    root: {
      justifyContent: "center",
      width: 360,
      marginLeft: "35rem",
      alignContent: "left",
    },
  })
);

export default function AddressComponent(props: any) {
  const classes = useStyles();
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [circleOptions, setCircleOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [selectedRadioValue, setSelectedRadioValue] = useState("");

  useEffect(() => {
    document.title = "Engineers";
    if (circleOptions.length === 0) {
      getCircles();
    }
  }, [selectedRadioValue]);

  const getCircles = async () => {
    //code
    const response = await getAllCirclesAPI();
    setCircleOptions(response);
  };

  const handleLocationChange = async (event: any) => {
    props.setTicketData({
      ...props.ticketData,
      [event.target.name]: event.target.value.toUpperCase(),
    });
    if (event.target.name === "circle") {
      const response = await getAllDivisionByCircleAPI(event);
      setCityOptions(response);
      setDivisionOptions(response);
    }
  };

  return (
    <>
      <Grid className={classes.input} item xs>
        <Typography className={classes.Typography}>
          *End Location Address
        </Typography>
        <Box>
          <input
            required
            style={{ textTransform: "uppercase" }}
            className={classes.input}
            autoComplete="new-password"
            id="substation"
            name="substation"
            onChange={handleLocationChange}
          />
        </Box>
      </Grid>
      <Grid className={classes.input} item xs>
        <Typography className={classes.Typography}>Landmark</Typography>
        <Box>
          <input
            style={{ textTransform: "uppercase" }}
            className={classes.input}
            autoComplete="new-password"
            name="landmark"
            onChange={handleLocationChange}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Typography className={classes.Typography}>
          * Circle/District
        </Typography>
        <select
          className={classes.select}
          id="circle"
          name="circle"
          value={props.ticketData.circle}
          onChange={handleLocationChange}
        >
          <option value="pleaseSelect">Please Select</option>
          {circleOptions?.map((x, y) => (
            <option key={y} value={x}>
              {x}
            </option>
          ))}
        </select>
      </Grid>
      <Grid className={classes.input} item xs>
        <Typography className={classes.Typography}>Division/City</Typography>
        <Box>
          <select
            className={classes.select}
            id="division"
            name="division"
            value={props.ticketData.division}
            onChange={handleLocationChange}
          >
            <option value="pleaseSelect">Please Select</option>
            {divisionOptions?.map((x, y) => (
              <option key={y} value={x}>
                {x}
              </option>
            ))}
          </select>
        </Box>
      </Grid>
      {/* <Grid item xs>
        <Typography className={classes.Typography}>Pincode</Typography>
        <Box>
          <input
            style={{ textTransform: "uppercase" }}
            className={classes.input}
            autoComplete="off"
            id="pinCode"
            name="pinCode"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            maxLength={6}
            onChange={handleLocationChange}
          />
        </Box>
      </Grid> */}
    </>
  );
}
