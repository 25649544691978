import { UrlConstants } from "../UrlConstants";
import { postRequest } from "../utils";

export const createTicketAPI = (ticketData: {
  complainantName: string;
  complainantContactNo: string;
  complainantDesignation: string;
  projectName: string;
  product: string;
  machineMake: string;
  problemType: string;
  circle: string;
  division: string;
  substation: string;
  landmark: string;
  pinCode: string;
  uxb1jsi364g4453780: string;
  modelNo: string;
}) => {
  const url = `${UrlConstants.CoreUrl}/public/createTicket`;
  const data = ticketData;
  return postRequest(url, data);
};
