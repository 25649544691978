
import { UrlConstants } from "../../global/UrlConstants";
import { postRequest } from "../../global/utils";

export const getHarwareCountsAPI = (userCircle: string | null) => {
    const url = `${UrlConstants.baseUrl}/getAeitDashboard/hardwareCount/loggedInUserId/${localStorage.getItem("id")}`;
    const data = { text: userCircle };
    return postRequest(url, data)
}

export const getTicketCountsAPI = (userCircle: string | null) => {
    const url = `${UrlConstants.baseUrl}/getAeitDashboard/count/loggedInUserId/${localStorage.getItem("id")}`;
    const data = { text: userCircle };
    return postRequest(url, data)
}