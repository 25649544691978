import { Button, Grid, IconButton } from "@mui/material";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CustomTable from "../../global/CustomTable/CustomTable";
import { useEffect, useMemo, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UrlConstants } from "../../global/UrlConstants";
import axios from "axios";
import {
  deleteSurveyByIdAPI,
  employeeById,
  exportSurveyAPI,
  getSurveyByItemNameAPI,
  getSurveyByMultipleCityAPI,
  getSurveyBySingleCityAPI,
  getSurveyCitiesAPI,
  uploadSurveyAPI,
} from "./SurveyService";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    myDropZone: {
      position: "relative",
      width: "100%",
      height: "10px",
    },
  })
);

export default function Survey(props: any) {
  const classes = useStyles();
  let history = useHistory();
  const [redirectionalState, setRedirectionalState] = useState(
    props.history.location.state
  );
  const isSuperAdmin = localStorage.getItem("role") === "superAdmin";
  const isAEIT = localStorage.getItem("role") === "aeit";
  const loggedInUserCity = localStorage.getItem("city");
  const [selectedCity, setSelectedCity] = useState("Saharanpur");
  const [cityOptions, setCityOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cityOptionsForAeit, setCityOptionsForAeit] = useState(
    JSON.parse(loggedInUserCity ?? "")
  );
  const [rows, setRows] = useState([]);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [idOption, setIdOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [circleOption, setCircleOption] = useState([]);
  const [divisionOption, setDivisionOption] = useState([]);
  const [subdivisionOption, setSubdivisionOption] = useState([]);
  const [endLocationAddressOption, setEndLocationAddressOption] = useState([]);
  const [itHardwareNameOption, setItHardwareNameOption] = useState([]);
  const [machineMakeOption, setMachineMakeOption] = useState([]);
  const [modelOption, setModelOption] = useState([]);
  const [serialNoOption, setSerialNoOption] = useState([]);
  const [upsBatteryStatusOption, setUpsBatteryStatusOption] = useState([]);
  const [windowsTypeOption, setWindowsTypeOption] = useState([]);
  const [zoneOption, setZoneOption] = useState([]);
  const [domainJoiningStatusOption, setDomainJoiningStatusOption] = useState(
    []
  );
  const [utilityContactPersonNameOption, setUtilityContactPersonNameOption] =
    useState([]);
  const [
    utilityContactPersonContactOption,
    setUtilityContactPersonContactOption,
  ] = useState([]);

  useEffect(() => {
    document.title = "Survey";
    updateCityInLocalStorage();
    if (cityOptions.length === 0) {
      getSurveyCities();
    }
  }, [cityOptionsForAeit]);

  const updateCityInLocalStorage = async () => {
    const user = await employeeById();
    localStorage.setItem("city", user?.city);
  };

  const deleteRow = (row: any) => {
    const confirmBox = window.confirm(
      `Do you want to delete Survey of ${row.city} [ ${row.nameOfUtilityContactPerson} - ${row.phoneNoOfUtilityContactPerson} ]`
    );
    if (confirmBox === true) {
      const secondConfirmBox = window.confirm(
        `You can't recover this Survey. Do you really want to delete Survey of ${row.city} [ ${row.nameOfUtilityContactPerson} - ${row.phoneNoOfUtilityContactPerson} ]`
      );
      if (secondConfirmBox === true) {
        const secondConfirmBox = window.confirm(
          `Final Confirmation to delete Survey of ${row.city} [ ${row.nameOfUtilityContactPerson} - ${row.phoneNoOfUtilityContactPerson} ]`
        );
        if (secondConfirmBox === true) {
          const response = deleteSurveyByIdAPI(row);
          response.then(function (response) {
            toast.success("Successfully Deleted!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            window.location.reload();
          });
        }
      }
    }
  };

  const columns = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "zone",
      header: "Zone",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: cityOption,
    },
    {
      accessorKey: "circle",
      header: "Circle",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: circleOption,
    },
    {
      accessorKey: "division",
      header: "Division",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: divisionOption,
    },
    // {
    //   accessorKey: "subdivision", header: "Sub Division", size: 210, filterVariant: 'select',
    //   filterSelectOptions: subdivisionOption,
    // },
    {
      accessorKey: "endLocationAddress",
      header: "End Location Address",
      size: 420,
      filterVariant: "select",
      filterSelectOptions: endLocationAddressOption,
    },
    {
      accessorKey: "itHardwareName",
      header: "IT Hardware Name(Computer/ 600 VA UPS/ 3 KVA UPS/PRINTERS)",
      size: 520,
      filterVariant: "select",
      filterSelectOptions: itHardwareNameOption,
    },
    {
      accessorKey: "machineMake",
      header: "Machine Make",
      size: 120,
      filterVariant: "select",
      filterSelectOptions: machineMakeOption,
    },
    {
      accessorKey: "model",
      header: "Model",
      size: 120,
      filterVariant: "select",
      filterSelectOptions: modelOption,
    },
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: serialNoOption,
    },
    {
      accessorKey: "upsBatteryStatus",
      header: "Ups Battery status",
      size: 200,
      filterVariant: "select",
      filterSelectOptions: upsBatteryStatusOption,
    },
    {
      accessorKey: "windowsType",
      header: "Windows Type",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: windowsTypeOption,
    },
    {
      accessorKey: "domainJoiningStatus",
      header: "Domain Joining Status (Domain/Without Domain)",
      size: 400,
      filterVariant: "select",
      filterSelectOptions: domainJoiningStatusOption,
    },
    {
      accessorKey: "utilityContactPersonName",
      header: "Name of Utility Contact Person",
      size: 270,
      filterVariant: "select",
      filterSelectOptions: utilityContactPersonNameOption,
    },
    {
      accessorKey: "utilityContactPersonContact",
      header: "Phone no of Utility Contact Person",
      size: 300,
      filterVariant: "select",
      filterSelectOptions: utilityContactPersonContactOption,
    },
    {
      accessorKey: "edit",
      enableColumnFilter: false,
      header: "Edit",
      size: 100,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <strong>
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              editRow(cell.row.original);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </strong>
      ),
    },
  ];

  const columnsForSuperAdmin = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "zone",
      header: "Zone",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: zoneOption,
    },
    {
      accessorKey: "circle",
      header: "Circle",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: circleOption,
    },
    {
      accessorKey: "division",
      header: "Division",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: divisionOption,
    },
    // {
    //   accessorKey: "subdivision", header: "Sub Division", size: 210,
    //   filterVariant: 'select',
    //   filterSelectOptions: subdivisionOption,
    // },
    {
      accessorKey: "endLocationAddress",
      header: "End Location Address",
      size: 420,
      filterVariant: "select",
      filterSelectOptions: endLocationAddressOption,
    },
    {
      accessorKey: "itHardwareName",
      header: "IT Hardware Name(Computer/ 600 VA UPS/ 3 KVA UPS/PRINTERS)",
      size: 520,
      filterVariant: "select",
      filterSelectOptions: itHardwareNameOption,
    },
    {
      accessorKey: "machineMake",
      header: "Machine Make",
      size: 120,
      filterVariant: "select",
      filterSelectOptions: machineMakeOption,
    },
    { accessorKey: "model", header: "Model", size: 120 },
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: serialNoOption,
    },
    {
      accessorKey: "upsBatteryStatus",
      header: "Ups Battery status",
      size: 200,
      filterVariant: "select",
      filterSelectOptions: upsBatteryStatusOption,
    },
    {
      accessorKey: "windowsType",
      header: "Windows Type",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: windowsTypeOption,
    },
    {
      accessorKey: "domainJoiningStatus",
      header: "Domain Joining Status (Domain/Without Domain)",
      size: 400,
      filterVariant: "select",
      filterSelectOptions: domainJoiningStatusOption,
    },
    {
      accessorKey: "utilityContactPersonName",
      header: "Name of Utility Contact Person",
      size: 270,
      filterVariant: "select",
      filterSelectOptions: utilityContactPersonNameOption,
    },
    {
      accessorKey: "utilityContactPersonContact",
      header: "Phone no of Utility Contact Person",
      size: 300,
      filterVariant: "select",
      filterSelectOptions: utilityContactPersonContactOption,
    },
    {
      accessorKey: "edit",
      enableColumnFilter: false,
      header: "Edit",
      size: 100,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <strong>
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              editRow(cell.row.original);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </strong>
      ),
    },
    {
      accessorKey: "Delete",
      enableColumnFilter: false,
      header: "Delete",
      size: 100,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <strong>
          <IconButton
            size="small"
            style={{ marginLeft: 2 }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              deleteRow(cell.row.original);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </strong>
      ),
    },
  ];

  const editRow = (event: any) => {
    history.push("/addEditSurvey", { data: event });
  };

  const getSurveys = async (city: String) => {
    setLoader(true);
    if (!isAEIT) {
      if (redirectionalState?.itemName) {
        const response = await getSurveyByItemNameAPI(redirectionalState);
        setRows(response);
        setDropDownOptions(response);
      } else {
        const response = await getSurveyBySingleCityAPI(city);
        setRows(response);
        setDropDownOptions(response);
      }
      setLoader(false);
    } else {
      const response = await getSurveyBySingleCityAPI(city);
      setRows(response);
      setDropDownOptions(response);
    }
    setLoader(false);
  };

  //Dropdown Filter Started
  const tableDataFiltered = (rows: any) => {
    const location = {
      zone: null,
      circle: [],
      division: [],
      endLocationAddress: [],
    };
    location.zone = rows.map((row: any) => row.zone);
    if (allEqual(location.zone)) {
      location.circle = removeDuplicates(rows.map((row: any) => row?.circle));
      location.division = removeDuplicates(
        rows.map((row: any) => row?.division)
      );
      location.endLocationAddress = removeDuplicates(
        rows.map((row: any) => row?.endLocationAddress)
      );
      if (allEqual(location?.circle)) {
        location.division = removeDuplicates(
          rows.map((row: any) => row?.division)
        );
        location.endLocationAddress = removeDuplicates(
          rows.map((row: any) => row?.endLocationAddress)
        );
        if (allEqual(location?.division)) {
          location.endLocationAddress = removeDuplicates(
            rows.map((row: any) => row?.endLocationAddress)
          );
        }
      }
    }
    handleZoneDropdownChange(location);
  };

  const handleClearFilter = (event: any) => {
    window.location.reload();
  };

  const handleZoneDropdownChange = (location: any) => {
    if (location?.circle?.length > 0) {
      setCircleOption(location?.circle);
      setComponentLoaded(true);
    }
    if (location?.division?.length > 0) {
      setDivisionOption(location?.division);
      setComponentLoaded(true);
    }
    if (location?.endLocationAddress?.length > 0) {
      setEndLocationAddressOption(location?.endLocationAddress);
      setComponentLoaded(true);
    }
  };

  const allEqual = (arr: any) => arr.every((val: any) => val === arr[0]);

  //Dropdown Filter Ended

  const setDropDownOptions = (response: any) => {
    const zone = response.map((r: any) => r.zone);
    setZoneOption(removeDuplicates(zone));
    const id = response.map((r: any) => r.id);
    setIdOption(removeDuplicates(id));
    const city = response.map((r: any) => r.city);
    setCityOption(removeDuplicates(city));
    const circle = response.map((r: any) => r.circle);
    setCircleOption(removeDuplicates(circle));
    const division = response.map((r: any) => r.division);
    setDivisionOption(removeDuplicates(division));
    const subdivision = response.map((r: any) => r.subdivision);
    setSubdivisionOption(removeDuplicates(subdivision));
    const endLocationAddress = response.map((r: any) => r.endLocationAddress);
    setEndLocationAddressOption(removeDuplicates(endLocationAddress));
    const itHardwareName = response.map((r: any) => r.itHardwareName);
    setItHardwareNameOption(removeDuplicates(itHardwareName));
    const machineMake = response.map((r: any) => r.machineMake);
    setMachineMakeOption(removeDuplicates(machineMake));
    const model = response.map((r: any) => r.model);
    setModelOption(removeDuplicates(model));
    const serialNo = response.map((r: any) => r.serialNo);
    setSerialNoOption(removeDuplicates(serialNo));
    const upsBatteryStatus = response.map((r: any) => r.upsBatteryStatus);
    setUpsBatteryStatusOption(removeDuplicates(upsBatteryStatus));
    const windowsType = response.map((r: any) => r.windowsType);
    setWindowsTypeOption(removeDuplicates(windowsType));
    const domainJoiningStatus = response.map((r: any) => r.domainJoiningStatus);
    setDomainJoiningStatusOption(removeDuplicates(domainJoiningStatus));
    const utilityContactPersonName = response.map(
      (r: any) => r.utilityContactPersonName
    );
    setUtilityContactPersonNameOption(
      removeDuplicates(utilityContactPersonName)
    );
    const utilityContactPersonContact = response.map(
      (r: any) => r.utilityContactPersonContact
    );
    setUtilityContactPersonContactOption(
      removeDuplicates(utilityContactPersonContact)
    );
  };

  function removeDuplicates(arr: any) {
    return arr?.filter(
      (item: any, index: any) => item && arr?.indexOf(item) === index
    );
  }

  const getSurveyCities = async () => {
    if (!isAEIT) {
      const response = await getSurveyCitiesAPI();
      setSelectedCity(response[0]);
      setCityOptions(
        redirectionalState ? ["All", ...response] : response.concat("All")
      );
      //code
      getSurveys(redirectionalState ? "All" : response[0]);
    } else {
      const user = await employeeById();
      const response = await getSurveyByMultipleCityAPI(user);
      setRows(response);
    }
  };

  const handleOnClick = () => {
    history.push("/addEditSurvey", { selectedCity: selectedCity });
  };

  const handleCityChange = async (event: any) => {
    setLoader(true);
    setSelectedCity(event.target.value);
    const response = await getSurveyBySingleCityAPI(event.target.value);
    setRows(response);
    setDropDownOptions(response);
    setLoader(false);
  };

  const onFileDropped = (event: any) => {
    if (event.target.files[0]?.name) {
      const response = uploadSurveyAPI(event.target.files[0]);
      response
        .then(function (response) {
          toast.success(response.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.reload();
        })
        .catch(function (error) {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const handleExportData = (rows: any[]) => {
    const ids = rows.map((row) => row.original.id);
    const response = exportSurveyAPI(ids);
    response
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedCity} - Survey` + ".xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  return (
    <Box overflow={"scroll"} style={{ height: "600px" }}>
      <div style={{ maxWidth: "100%", marginTop: 20 }}>
        {!isAEIT && (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
              xl={4}
              style={{ maxWidth: "100%", marginBottom: 15 }}
            >
              <label
                style={{
                  paddingRight: "1rem",
                  color: "black",
                }}
              >
                Select Zone
              </label>
              <select
                style={{
                  width: 295,
                  height: 27,
                }}
                name="city"
                onChange={handleCityChange}
              >
                {cityOptions.map((x, y) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
              <Button
                size="small"
                style={{
                  color: "white",
                  backgroundColor: "#f44336",
                  marginLeft: 8,
                  minWidth: 120,
                  marginTop: 12,
                  marginBottom: 12,
                }}
                variant="outlined"
                onClick={handleOnClick}
              >
                Add Survey
              </Button>
            </Grid>
          </>
        )}
        {isAEIT && (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
              xl={4}
              style={{ maxWidth: "100%", marginBottom: 15 }}
            >
              <label
                style={{
                  paddingRight: "1rem",
                  color: "black",
                }}
              >
                Select Zone
              </label>
              <select
                style={{
                  width: 295,
                  height: 27,
                }}
                name="city"
                onChange={handleCityChange}
              >
                {cityOptionsForAeit?.map((x: any, y: any) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </Grid>
          </>
        )}
        <Grid item lg={12} sm={12} xs={12}>
          <CustomTable
            onFileDropped={onFileDropped}
            data={rows}
            loader={loader}
            columns={isSuperAdmin ? columnsForSuperAdmin : columns}
            handleExportData={handleExportData}
            tableDataFiltered={tableDataFiltered}
            handleClearFilter={handleClearFilter}
          />
        </Grid>
        <ToastContainer />
      </div>
    </Box>
  );
}
