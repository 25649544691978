import React from "react";
import { Route, Switch } from "react-router-dom";
import Tickets from "../../dvvnl/Tickets/Tickets";
import TicketsList from "../../dvvnl/Ticket/TicketsList";
import EditTicket from "../../dvvnl/Tickets/EditTicket/EditTicket";
import Employees from "../../dvvnl/Employee/Employees";
import AddEmployee from "../../dvvnl/Employee/AddEmployee/AddEmployee";
import LocationList from "../../dvvnl/Location/LocationList";
import Survey from "../../dvvnl/Survey/Survey";
import AddEditSurvey from "../../dvvnl/Survey/AddEditSurvey";
import AdminDashboard from "../../dvvnl/Dashboard/AdminDashboard";
import AeitDashboard from "../../dvvnl/Dashboard/AeitDashboard";
import FreezeItemListing from "../../dvvnl/FreezeItem/FreezeItemListing";
import AddEditFreezeItem from "../../dvvnl/FreezeItem/AddEditFreezeItem";
import AdminDocuments from "../../dvvnl/AdminDocuments/AdminDocuments";
import QuarterlyReport from "../../dvvnl/QuarterlyReport/QuarterlyReport";
import ChangeSelfPassword from "../../dvvnl/Employee/ChangeSelfPassword";
import Aiet from "../../dvvnl/Aeit/Aeit";
import AddAiet from "../../dvvnl/Aeit/AddAeit/AddAeit";
import SideBar from "../../dvvnl/SideBar/SideBar";

export default function DvvnlRoute() {
  return (
    <>
      <SideBar />
      <div style={{ padding: 20 }}>
        <Switch>
          <Route exact path={"/tickets"} component={Tickets} />
          <Route exact path={"/ticketsList"} component={TicketsList} />
          <Route exact path={"/edit/:id"} component={EditTicket} />
          <Route exact path={"/employees"} component={Employees} />
          <Route exact path={"/addEmployee"} component={AddEmployee} />
          <Route exact path={"/survey"} component={Survey} />
          <Route exact path={"/addEditSurvey"} component={AddEditSurvey} />
          <Route exact path={"/adminDashboard"} component={AdminDashboard} />
          <Route exact path={"/IT_NodalDashboard"} component={AeitDashboard} />
          <Route exact path={"/IT-Nodal"} component={Aiet} />
          {/* <Route exact path={"/Aeit"} component={Aiet} /> */}
          <Route exact path={"/addIT-Nodal"} component={AddAiet} />
          <Route exact path={"/freezeItems"} component={FreezeItemListing} />
          <Route
            exact
            path={"/addEditFreezeItem"}
            component={AddEditFreezeItem}
          />
          <Route exact path={"/surveyPDF"} component={AdminDocuments} />
          <Route exact path={"/quaterlyReport"} component={QuarterlyReport} />
          <Route
            exact
            path={"/changePassword"}
            component={ChangeSelfPassword}
          />
        </Switch>
      </div>
    </>
  );
}
