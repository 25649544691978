import { UrlConstants } from "../../global/UrlConstants";
import { getRequest, postRequest } from "../../global/utils";

export const getAdminTicketByStatusAndDateRange = async (
  status: any,
  fromDate: String,
  toDate: String,
  circle: String
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getTickets/admin/${status}/${fromDate}/${toDate}/${circle ?? "ALL"}`;
  return getRequest(url);
};

export const getAeitTicket = async (
  fromDate: String,
  toDate: String,
  status: any
) => {
  const url = `${UrlConstants.baseUrl}/aeit/tickets/${fromDate}/${toDate}/${status}`;
  return getRequest(url);
};

export const getAeitFilteredTicket = async (
  fromDate: String,
  toDate: String,
  status: any,
  data: any
) => {
  const url = `${UrlConstants.baseUrl}/aeit/tickets/filter/${fromDate}/${toDate}/${status}`;
  return postRequest(url, data);
};

export const approveTicketByAeitAPI = (ticketData: any[]) => {
  const url = `${
    UrlConstants.baseUrl
  }/approveByAeit/loggedInUserId/${localStorage.getItem("id")}`;
  const data = ticketData;
  return postRequest(url, data);
};
