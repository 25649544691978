import { Card, CardHeader } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import CustomCard from "../../global/CustomCard/CustomCard";
import axios from "axios";
import { UrlConstants } from "../../global/UrlConstants";
import { useHistory } from "react-router-dom";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Chip, Typography } from "@mui/material";
import moment from "moment";
import {
  getHarwareCountsAPI,
  getTicketCountsAPI,
} from "./AeitDashboardService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: "#f2f1ed",
    },
  })
);

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
  value,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#030303"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${name}: ${value?.toFixed(0) ?? 0}`}
    </text>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function AeitDashboard() {
  let history = useHistory();
  const classes = useStyles();
  const userCircle = localStorage.getItem("circle");
  const [ticketCount, setTicketCount] = useState({
    OPEN: 0,
    CLOSED: 0,
    currentMonthCreatedTicket: 0,
    currentMonthClosedTicket: 0,
    todayCreatedTicket: 0,
    todayClosedTicket: 0,
    activeEngineers: 0,
    totalSurvey: 0,
    totalCircles: 0,
    adminDocuments: 0,
    freezeItemCount: 0,
    pivotTableCircle: 0,
  });

  const [hardwareCount, setHardwareCount] = useState({
    totalSurveyHardwareCount: [],
    totalFreezeHardwareCount: [],
  });

  useEffect(() => {
    document.title = "Dashboard";
    getTicketCounts();
    getHarwareCounts();
  }, []);

  const getTicketCounts = async () => {
    const response = await getTicketCountsAPI(userCircle);
    setTicketCount(response);
  };

  const getHarwareCounts = async () => {
    const response = await getHarwareCountsAPI(userCircle);
    setHardwareCount(response);
  };

  const handleOnClick = () => {};

  const handleRedirectWithHardwareFilter = async (row: any) => {
    //code to redirect
  };

  const handleTicketRedirection = async (
    fromDate: any,
    toDate: any,
    status: any
  ) => {
    const format = "YYYY-MM-DD";
    const dateRange = [
      moment(fromDate).format(format),
      moment(toDate).format(format),
    ];
    history.push("/tickets", { dateRange: dateRange, status: status });
  };

  const handleFreezeRedirectWithHardwareFilter = async (itemName: any) => {
    history.push("/freezeItems", { itemName: itemName });
  };

  return (
    <Box style={{ backgroundColor: "#ffffff" }}>
      <Grid
        container
        spacing={2}
        style={{ marginTop: 10, backgroundColor: "#ffffff" }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <CustomCard
            cardHeight="8rem"
            // title="Total Tickets"
            cardColor="#fcffde"
            count={(ticketCount?.OPEN ?? 0) + (ticketCount?.CLOSED ?? 0)}
            // onClick={() => history.push("/tickets")}
            titleComponent={
              <Typography
                variant="h5"
                onClick={() =>
                  handleTicketRedirection(
                    "2023-03-05",
                    moment().format("YYYY-MM-DD"),
                    "ALL"
                  )
                }
              >
                {"Tickets From 1 July is "}{" "}
                {(ticketCount?.OPEN ?? 0) + (ticketCount?.CLOSED ?? 0)}
              </Typography>
            }
            subtitle={
              <>
                <Chip
                  size="small"
                  label={`Open: ${ticketCount?.OPEN ?? 0}`}
                  onClick={() =>
                    handleTicketRedirection(
                      "2023-03-05",
                      moment().format("YYYY-MM-DD"),
                      "OPEN"
                    )
                  }
                  style={{ color: "white", backgroundColor: "#a1a118" }}
                />
                <Chip
                  size="small"
                  label={`Closed: ${ticketCount?.CLOSED ?? 0}`}
                  onClick={() =>
                    handleTicketRedirection(
                      "2023-03-05",
                      moment().format("YYYY-MM-DD"),
                      "CLOSED"
                    )
                  }
                  style={{
                    margin: "0.1rem",
                    color: "white",
                    backgroundColor: "#a1a118",
                  }}
                />
              </>
            }
          ></CustomCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <CustomCard
            cardHeight="8rem"
            // title="Created Tickets In Current Month"
            color="#141414"
            cardColor="#ffeade"
            // count={ticketCount.currentMonthCreatedTicket}
            // onClick={() => history.push("/tickets")}
            titleComponent={
              <Typography
                variant="h5"
                onClick={() =>
                  handleTicketRedirection(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    ),
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    ),
                    "ALL"
                  )
                }
              >
                {"Created Tickets In Current Month"}
              </Typography>
            }
            subtitle={
              <>
                <Chip
                  size="small"
                  label={`In ${moment().format("MMMM")} Month: ${
                    ticketCount.currentMonthCreatedTicket ?? 0
                  }`}
                  onClick={() =>
                    handleTicketRedirection(
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                      ),
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        0
                      ),
                      "ALL"
                    )
                  }
                  style={{ color: "white", backgroundColor: "#f75348" }}
                />
                {/* <Chip
                                    size="small"
                                    label={`Today: ${ticketCount.todayCreatedTicket ?? 0}`}
                                    onClick={() =>
                                        handleTicketRedirection(new Date(), new Date(), "ALL")}
                                    style={{ margin: "0.1rem", color: "white", backgroundColor: "#f75348" }}
                                /> */}
              </>
            }
          ></CustomCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CustomCard
            cardHeight="8rem"
            // title="Closed Tickets"
            cardColor="#e8ffde"
            // count={ticketCount.currentMonthClosedTicket}
            titleComponent={
              <Typography
                variant="h5"
                onClick={() =>
                  history.push("/tickets", {
                    dateRange: ["2023-03-05", moment().format("YYYY-MM-DD")],
                    status: "CLOSED",
                  })
                }
              >
                {"Closed Tickets In Current Month"}
              </Typography>
            }
            subtitle={
              <>
                <Chip
                  size="small"
                  label={`In Month: ${
                    ticketCount.currentMonthClosedTicket ?? 0
                  }`}
                  onClick={() =>
                    handleTicketRedirection(
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                      ),
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        0
                      ),
                      "CLOSED"
                    )
                  }
                  style={{ color: "white", backgroundColor: "#3a941c" }}
                />
                <Chip
                  size="small"
                  label={`Today: ${ticketCount.todayClosedTicket ?? 0}`}
                  onClick={() =>
                    handleTicketRedirection(new Date(), new Date(), "CLOSED")
                  }
                  style={{
                    margin: "0.1rem",
                    color: "white",
                    backgroundColor: "#3a941c",
                  }}
                />
              </>
            }
            // onClick={() => history.push("/tickets", { tabValue: "CLOSED" })}
          ></CustomCard>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={3}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '50h' }}
                >
                    <PieChart width={370} height={400} >
                        <Pie
                            data={[
                                { name: "Open Tickets", value: ticketCount.OPEN },
                                { name: "Closed Tickets", value: ticketCount.CLOSED },
                            ]}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={110}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    onClick={() => history.push("/tickets")}
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                </Grid> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingTop: 40 }}
        >
          {localStorage.getItem("account") !== "dvvnl" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomCard
                cardHeight="8rem"
                // title="Total Assets"
                color="#141414"
                count={ticketCount?.freezeItemCount ?? 0}
                // onClick={() => history.push("/freezeItems")}
                titleComponent={
                  <Typography
                    variant="h5"
                    onClick={() => history.push("/freezeItems", {})}
                  >
                    {"Assets under AMC"} {ticketCount?.freezeItemCount ?? 0}
                  </Typography>
                }
                hardwareCount={
                  <>
                    {hardwareCount?.totalFreezeHardwareCount?.map(
                      (data: any, index: any) => {
                        return (
                          <Chip
                            size="small"
                            label={`${data?.name}: ${data?.count}`}
                            onClick={() => {
                              handleFreezeRedirectWithHardwareFilter(data.name);
                            }}
                            style={{
                              margin: "0.1rem",
                              color: "white",
                              backgroundColor: "#686968",
                            }}
                          />
                        );
                      }
                    )}
                  </>
                }
              ></CustomCard>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
