import axios from "axios";
import { UrlConstants } from "../../global/UrlConstants";
import { deleteRequest, getRequest } from "../../global/utils";
import { StringConstants } from "../../global/StringConstants";

export const getAeitByStatus = async (status: any) => {
  const url = `${UrlConstants.baseUrl}/employee/${StringConstants.aeit}/${status}`;
  return getRequest(url);
};

export const deleteAeitAPI = (row: any) => {
  const url = `${UrlConstants.baseUrl}/deleteEmployee/${
    row.id
  }/loggedInUserId/${localStorage.getItem("id")}`;
  return deleteRequest(url);
};
