import { UrlConstants } from "../../global/UrlConstants";
import { getRequest, patchRequest, postRequest } from "../../global/utils";

export const getAllCirclesAPI = () => {
    const url = `${UrlConstants.baseUrl}/getAllCircles`;
    return getRequest(url);
}

export const getAllSubDivisionByDivisionAPI = (event: any) => {
    const url = `${UrlConstants.baseUrl}/getAllSubDivisionByDivision/${event.target.value}`;
    return getRequest(url)
}

export const getAllDivisionByCircleAPI = (event: any) => {
    const url = `${UrlConstants.baseUrl}/getAllDivisionByCircle/${event.target.value}`
    return getRequest(url)
}

export const addFreezeItemAPI = (surveyObj: { id: any; zone: any; city: any; circle: any; division: any; subdivision: any; endLocationAddress: any; itHardwareName: any; machineMake: any; model: any; serialNo: any; upsBatteryStatus: any; windowsType: any; domainJoiningStatus: any; utilityContactPersonName: any; utilityContactPersonContact: any; }) => {
    const url = `${UrlConstants.baseUrl}/addFreezeItem`;
    const data = surveyObj

    return postRequest(url, data)
}

export const updateFreezeItemAPI = (surveyObj: { id: any; zone: any; city: any; circle: any; division: any; subdivision: any; endLocationAddress: any; itHardwareName: any; machineMake: any; model: any; serialNo: any; upsBatteryStatus: any; windowsType: any; domainJoiningStatus: any; utilityContactPersonName: any; utilityContactPersonContact: any; }) => {
    const url = `${UrlConstants.baseUrl}/updateFreezeItem`;
    const data = surveyObj;
    return patchRequest(url, data)
}

