import { UrlConstants } from "../../global/UrlConstants";
import {
  downloadRequest,
  exportRequest,
  getRequest,
  postRequest,
} from "../../global/utils";
import { Email } from "./interfaces";

export const getRecepients = async (status: any) => {
  const url = `${UrlConstants.CoreUrl}/email/marketing/recepients/fetch`;
  return getRequest(url);
};

export const sendMarketingEmail = async (email: Email) => {
  const url = `${UrlConstants.CoreUrl}/email/marketing/send`;
  return postRequest(url, email);
};

export const downloadEmailSenders = () => {
  window.open(`${UrlConstants.CoreUrl}/email/marketing/sender/download`);
};

export const downloadEmailRecepients = () => {
  window.open(`${UrlConstants.CoreUrl}/email/marketing/recepients/download`);
};

export const downloadLogs = () => {
  window.open(`${UrlConstants.CoreUrl}/event/email/download`);
};
