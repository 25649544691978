import { UrlConstants } from "../../global/UrlConstants";
import { deleteRequest, getRequest, postRequest, uploadRequest, exportRequest } from "../../global/utils";

export const uploadFileAPI = async (file: any) => {
    const url = `${UrlConstants.baseUrl}/importFreezeItem`;
    const data = { file: file };
    return uploadRequest(url, data);
};

export const exportFreezeItemByIdAPI = (payload: any[]) => {
    const url = `${UrlConstants.baseUrl}/exportFreezeItemById`;
    const data = payload;
    return exportRequest(url, data)
}


export const getFreezeItemBySingleCircleAPI = (circle: string) => {
    const url = `${UrlConstants.baseUrl}/getFreezeItemByCircle/${circle}`
    return getRequest(url);
}

export const getFreezeItemByCityAPI = (city: String) => {
    const url = `${UrlConstants.baseUrl}/getFreezeItemByCity/${city}`
    return getRequest(url);
}

export const getFreezeItemByMultipleCirclesAPI = (user: any) => {
    const url = `${UrlConstants.baseUrl}/getFreezeItemByCircle/${JSON.parse(`${user?.data?.city}`)[0]}`;
    return getRequest(url)
}

export const getFreezeItemCitiesAPI = () => {
    const url = `${UrlConstants.baseUrl}/getFreezeItemCities`;
    return getRequest(url);
}

export const getFreezeItemByItemAPI = (response: any, redirectionalState: any) => {
    const url = `${UrlConstants.baseUrl}/getFreezeItemByItem`;
    const data = { text: redirectionalState?.itemName };
    return postRequest(url, data);
}

export const getLoggedInEmployeeAPI = () => {
    const url = `${UrlConstants.baseUrl}/employee/${localStorage.getItem("id")}`
    return getRequest(url)
}

export const deleteFreezeItemAPI = (row: any) => {
    const url = `${UrlConstants.baseUrl}/deleteFreezeItem/${row.id}/loggedInUserId/${localStorage.getItem("id")}`
    return deleteRequest(url);
}

