export const account = () => {
  return localStorage.getItem("account");
};

export const isDvvnl = () => {
  return localStorage.getItem("account") === "dvvnl";
};

export const isPvvnl = () => {
  return localStorage.getItem("account") === "pvvnl";
};

export const StringConstants = {
  aeit: account() === "dvvnl" ? `IT-Nodal` : `Aeit`,
  account: account(),
  isDvvnl: isDvvnl(),
  isPvvnl: isPvvnl(),

  pvvnl: "pvvnl",
  dvvnl: "dvvnl",
  viewComplaint: "viewComplaint",
  trackComplaint: "Track Complaint",
  track: "Track",
  complaintNo: "Complaint No.",
  text: "text",
};
