import { Dialog, DialogTitle, Grid, Paper } from "@material-ui/core";
import React, { useState, useEffect, useLayoutEffect } from "react";
import viewUserComplaintStyle from "./viewUserComplaint.styles";
import ViewUserComaplaintStepper from "./ViewUserComplaintStepper";
import {
  trackComplaintByComplaintNo,
  trackComplaintByPhone,
} from "./viewUserComplaintService";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";

interface ViewUserComplaint {
  history: any;
}

export default function ViewUserComplaint(props: ViewUserComplaint) {
  const classes = viewUserComplaintStyle;
  const history = useHistory();
  const [complaint, setcomplaint] = useState(
    props.history.location.state?.complaint ?? {}
  );
  const [complaintNo, setComplaintNo] = useState();
  const [complaintData, setComplaintData] = useState<any[]>([]);

  useEffect(() => {
    callTrackComplaintApi();
  }, []);

  const callTrackComplaintApi = async () => {
    let complaintData: any[];
    if (complaint.searchType === "complaintNo") {
      complaintData = await trackComplaintByComplaintNo(complaint.complaintNo);
    } else {
      complaintData = await trackComplaintByPhone(complaint.complaintNo);
    }
    if (complaintData) {
      setComplaintData(() => complaintData);
    } else {
      toast.error("No Record Found!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => history.goBack(), 2000);
    }
  };

  const ViewUserComplaintJsx = () => {
    return complaintData ? (
      <Grid style={classes.mainGrid}>
        <Grid style={classes.innerGrid}>
          <Paper style={classes.paper}>
            <h1>{`Track Complaint with ${complaint.searchType}: [ ${complaint.complaintNo} ]`}</h1>
            {complaintData?.map((data: any) => (
              <ViewUserComaplaintStepper
                complaintData={data}
              ></ViewUserComaplaintStepper>
            ))}
          </Paper>
        </Grid>
        <ToastContainer />
      </Grid>
    ) : (
      <>
        <ViewUserComaplaintStepper
          complaintData={[]}
        ></ViewUserComaplaintStepper>
        <ToastContainer />
      </>
    );
  };

  return ViewUserComplaintJsx();
}
