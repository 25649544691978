import axios from "axios";
import { UrlConstants } from "../../global/UrlConstants";
import { deleteRequest, getRequest, patchRequest } from "../../global/utils";

export const getEngineersByStatus = async (status: any) => {
  const url = `${UrlConstants.baseUrl}/getAllEngineers/${status}/loggedInUserId/${localStorage.getItem("id")}`;
  return getRequest(url);
};


export const changeSelfPasswordAPI = (newPassword: any) => {
  const url = `${UrlConstants.baseUrl}/changeSelfPassword/loggedInUserId/${localStorage.getItem("id")}`;
  const data = { text: newPassword };
  return patchRequest(url, data)
}

export const deleteEmployeeAPI = (row: any) => {
  const url = `${UrlConstants.baseUrl}/deleteEmployee/${row.id}/loggedInUserId/${localStorage.getItem("id")}`;
  return deleteRequest(url);
}

export const updatePasswordAPI = (id: string, newPassword: any) => {
  const url = `${UrlConstants.baseUrl}/updatePassword/loggedInUserId/${localStorage.getItem("id")}`;
  const data = { id: id, password: newPassword, };
  return patchRequest(url, data)
}