import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UrlConstants } from "../../global/UrlConstants";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { addAeitRemarks } from "./TicketServices";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Typography: {
      color: "black",
      paddingTop: "0.3rem",
      paddingLeft: "0.5rem",
      textAlign: "left",
    },
    input: {
      minWidth: 290,
      minHeight: 30,
      paddingLeft: 20,
    },
  })
);

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  selectedTicket: any;
  aeitRemarks: any;
}

export default function RemarkComponent(props: SimpleDialogProps) {
  const classes = useStyles();
  const history = useHistory();
  const { onClose, open } = props;
  const [aeitRemarks, setAeitRemarks] = useState(props.aeitRemarks);
  const [ticketData, setTicketData] = useState({ id: "", aeitRemarks: "" });

  const handleClose = () => {
    // onClose(selectedValue);
  };

  useEffect(() => {
    setAeitRemarks(props.selectedTicket.aeitRemarks);
  }, []);

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  const handleRemarkChange = (event: any) => {
    setAeitRemarks(event?.target?.value);
  };

  const handeSubmitButton = (e: any) => {
    ticketData.id = props.selectedTicket.id;
    ticketData.aeitRemarks = aeitRemarks;
    e.preventDefault();
    const response = addAeitRemarks(ticketData);
    response
      .then(function () {
        toast.success("Successfully Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch(function (error) {
        toast.error("Error while updating!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
    setTimeout(() => history.push("/tickets"), 700);
  };

  //code
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      // style={{
      //   // color: "black",
      //   paddingTop: "0.3rem",
      //   paddingLeft: "2rem",
      //   textAlign: "left",
      // }}
    >
      <DialogTitle>
        {"Ticket No: "}
        {props?.selectedTicket?.complaintNo}
        {/* {selectedValue.complaintNo} */}
      </DialogTitle>
      {/* <Typography
                style={{
                    color: "black",
                    paddingTop: "0.3rem",
                    paddingLeft: "2rem",
                    textAlign: "left",
                }}
            >
                Add Remark
            </Typography> */}
      <Box className={classes.input}>
        <Typography className={classes.Typography}>
          Remarks by Verifier
        </Typography>
        <Box
          style={{
            color: "white",
            // backgroundColor: "#f44336",
            marginBottom: 20,
          }}
        >
          <input
            className={classes.input}
            autoComplete="new-password"
            name="aeitRemarks"
            // value={props?.selectedTicket?.aeitRemarks}
            value={aeitRemarks}
            onChange={handleRemarkChange}
          />
        </Box>
      </Box>
      <Button
        style={{
          color: "white",
          backgroundColor: "#f44336",
          marginBottom: 20,
          marginRight: "7.5rem",
          marginLeft: "7.5rem",
        }}
        type="submit"
        onClick={handeSubmitButton}
      >
        Submit
      </Button>
      <Button
        variant="outlined"
        style={{
          color: "white",
          backgroundColor: "#900080",
          marginBottom: 20,
          marginRight: "7.5rem",
          marginLeft: "7.5rem",
        }}
        onClick={() => history.push("/tickets")}
      >
        Cancel
      </Button>

      {/* <TextField
                className={classes.textField}
                label="Remarks by Verifier"
                InputLabelProps={{
                    shrink: true,
                }}
                name="aeitRemarks"
                value={props?.selectedTicket?.aeitRemarks}
                onChange={handleRemarkChange}
                size="small"
            /> */}

      <ToastContainer />
    </Dialog>
  );
}
