import { useState } from "react";
import Grid from "@mui/material/Grid";
import { createStyles, makeStyles } from "@material-ui/core";
import { Theme } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    element: {
      margin: 4,
      // width: 150,
      height: 27,
      width: 105,
    },
  })
);

export default function CustomRangePicker(props: any) {
  const classes = useStyles();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const handleFromDateChange = (e: any) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e: any) => {
    setToDate(e.target.value);
    if (fromDate) {
      props.handleDateRangeChange([fromDate, e.target.value]);
    } else {
      //need to add validation to highlight -> Please fill fromDate
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid></Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{ paddingTop: "1.5rem" }}
        >
          <label className={classes.element}>{props?.label}</label>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <input
            disabled={props?.disabled}
            className={classes.element}
            type="date"
            name="fromDate"
            defaultValue={props.fromDate}
            onSelect={handleFromDateChange}
          />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <input
            disabled={props?.disabled}
            className={classes.element}
            type="date"
            name="toDate"
            defaultValue={props.toDate}
            onChange={handleToDateChange}
          />
        </Grid>
      </Grid>
    </>
  );
}
