import { Grid } from "@mui/material";
import CustomTable from "../../global/CustomTable/CustomTable";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UrlConstants } from "../../global/UrlConstants";
import { Box, Button } from "@material-ui/core";
import axios from "axios";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { getPivotTableAPI } from "./LocationService";

//circle = zone, division = circle, subDivison = division
export default function LocationList() {
  const [rows, setRows] = useState([]);
  const history = useHistory();
  const isSuperAdmin = localStorage.getItem("role") === "superAdmin";
  const isAdmin = localStorage.getItem("role") === "Admin";
  const [selectedCity, setSelectedCity] = useState("MD OFFICE");
  const [cityOptions, setCityOptions] = useState([]);
  const [zoneOption, setZoneOption] = useState([]);
  const [circleOption, setCircleOption] = useState([]);
  const [divisionOption, setDivisionOption] = useState([]);

  useEffect(() => {
    document.title = "Freeze Item Details";
    getPivot();
  });

  const getPivot = async () => {
    if (isAdmin || isSuperAdmin) {
      const response = await getPivotTableAPI();
      setRows(response);
      setDropDownOptions(response);
    }
  };

  //Dropdown Filter Started
  const tableDataFiltered = (rows: any) => {
    const location = { zone: null, circle: [], division: [] };
    location.zone = rows.map((row: any) => row.zone);
    if (allEqual(location.zone)) {
      location.circle = removeDuplicates(rows.map((row: any) => row?.circle));
      location.division = removeDuplicates(
        rows.map((row: any) => row?.division)
      );
      if (allEqual(location?.circle)) {
        location.division = removeDuplicates(
          rows.map((row: any) => row?.division)
        );
      }
    }
    handleZoneDropdownChange(location);
  };

  const handleClearFilter = (event: any) => {
    window.location.reload();
  };

  const handleZoneDropdownChange = (location: any) => {
    if (location?.circle?.length > 0) {
      setCircleOption(location?.circle);
      setDivisionOption(location?.division);
    }
  };

  const allEqual = (arr: any) => arr.every((val: any) => val === arr[0]);

  //Dropdown Filter Ended

  const setDropDownOptions = (response: any) => {
    const zone = response.map((r: any) => r.zone);
    setZoneOption(removeDuplicates(zone));
    const circle = response.map((r: any) => r.circle);
    setCircleOption(removeDuplicates(circle));
    const division = response.map((r: any) => r.division);
    setDivisionOption(removeDuplicates(division));
    const subdivision = response.map((r: any) => r.subdivision);
  };

  function removeDuplicates(arr: any) {
    return arr?.filter(
      (item: any, index: any) => item && arr?.indexOf(item) === index
    );
  }

  const handleCellClick = (cell: any) => {
    let filterData = {
      columnName: cell.column.id ?? "",
      zone: cell.row.original.zone ?? "",
      circle: cell.row.original.circle ?? "",
      division: cell.row.original.division ?? "",
    };
    history.push("/freezeItems", { columnFilter: filterData });
  };

  const columns = [
    {
      header: "Serial No",
      size: 60,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "zone",
      header: "Zone",
      size: 160,
      filterVariant: "select",
      filterSelectOptions: zoneOption,
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.zone === "Grand Total" ||
        cell.row.original.circle.includes("Total") ? (
          <strong>{cell.row.original.zone}</strong>
        ) : (
          cell.row.original.zone
        ),
    },
    {
      accessorKey: "circle",
      header: "Circle",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: circleOption,
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.zone === "Grand Total" ||
        cell.row.original.circle.includes("Total") ? (
          <strong>{cell.row.original.circle}</strong>
        ) : (
          cell.row.original.circle
        ),
    },
    {
      accessorKey: "division",
      header: "Division",
      size: 200,
      filterVariant: "select",
      filterSelectOptions: divisionOption,
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.zone === "Grand Total" ||
        cell.row.original.circle.includes("Total") ? (
          <strong>{cell.row.original.division}</strong>
        ) : (
          cell.row.original.division
        ),
    },
    {
      accessorKey: "computer",
      enableColumnFilter: false,
      header: "Computer",
      size: 100,
      Cell: (cell: any) => (
        <Button
          style={{
            maxWidth: "40px",
            maxHeight: "20px",
            minWidth: "40px",
            minHeight: "20px",
          }}
          onClick={() => handleCellClick(cell)}
        >
          {cell.row.original.zone === "Grand Total" ||
          cell.row.original.circle.includes("Total") ? (
            <strong>{cell.row.original.computer}</strong>
          ) : (
            cell.row.original.computer
          )}
        </Button>
      ),
    },
    {
      accessorKey: "_800vaups",
      enableColumnFilter: false,
      header: "800 VA UPS",
      size: 100,
      Cell: (cell: GridRenderCellParams) => (
        <Button
          style={{
            maxWidth: "40px",
            maxHeight: "20px",
            minWidth: "40px",
            minHeight: "20px",
          }}
          onClick={() => handleCellClick(cell)}
        >
          {cell.row.original.zone === "Grand Total" ||
          cell.row.original.circle.includes("Total") ? (
            <strong>{cell.row.original._800vaups}</strong>
          ) : (
            cell.row.original._800vaups
          )}
        </Button>
      ),
    },
    {
      accessorKey: "_600vaups",
      enableColumnFilter: false,
      header: "600 VA UPS",
      size: 100,
      Cell: (cell: GridRenderCellParams) => (
        <Button
          style={{
            maxWidth: "40px",
            maxHeight: "20px",
            minWidth: "40px",
            minHeight: "20px",
          }}
          onClick={() => handleCellClick(cell)}
        >
          {cell.row.original.zone === "Grand Total" ||
          cell.row.original.circle.includes("Total") ? (
            <strong>{cell.row.original._600vaups}</strong>
          ) : (
            cell.row.original._600vaups
          )}
        </Button>
      ),
    },
    {
      accessorKey: "_3kvaups",
      enableColumnFilter: false,
      header: "3KVA UPS",
      size: 100,
      Cell: (cell: GridRenderCellParams) => (
        <Button
          style={{
            maxWidth: "40px",
            maxHeight: "20px",
            minWidth: "40px",
            minHeight: "20px",
          }}
          onClick={() => handleCellClick(cell)}
        >
          {cell.row.original.zone === "Grand Total" ||
          cell.row.original.circle.includes("Total") ? (
            <strong>{cell.row.original._3kvaups}</strong>
          ) : (
            cell.row.original._3kvaups
          )}
        </Button>
      ),
    },
    {
      accessorKey: "grandTotal",
      enableColumnFilter: false,
      header: "Grand Total",
      size: 100,
      Cell: (cell: GridRenderCellParams) => (
        <Button
          style={{
            maxWidth: "40px",
            maxHeight: "20px",
            minWidth: "40px",
            minHeight: "20px",
          }}
          onClick={() => handleCellClick(cell)}
        >
          {<strong>{cell.row.original.grandTotal}</strong>}
        </Button>
      ),
    },
  ];

  return (
    <Box overflow={"scroll"} style={{ height: "600px" }}>
      <div style={{ maxWidth: "100%" }}>
        <Grid item lg={12} sm={12} xs={12}>
          <CustomTable
            pageSize={201}
            data={rows}
            columns={columns}
            handleZoneDropdownChange={handleZoneDropdownChange}
            tableDataFiltered={tableDataFiltered}
            handleClearFilter={handleClearFilter}
          />
        </Grid>
        <ToastContainer />
      </div>
    </Box>
  );
}
