import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Login from "./global/Login/Login";
import Layout from "./global/Layout/Layout";
import MainApp from "./MainApp";
import AddTicket from "./global/AddTicket/AddTicket";
import TrackComplaint from "./global/TrackComplaint/TrackComplaint";
import ViewUserComplaint from "./global/TrackComplaint/ViewUserComplaint/ViewUserComplaint";
import EmailMarketing from "./integration/emailMarketing/EmailMarketing";
import { ToastContainer } from "react-toastify";
import Config from "./integration/emailMarketing/config/Config";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path={"/"} component={MainApp} />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/addTicket"} component={AddTicket} />
        <Route exact path={"/trackComplaint"} component={TrackComplaint} />
        <Route exact path={"/viewComplaint"} component={ViewUserComplaint} />
        <Route exact path={"/email/marketing"} component={EmailMarketing} />
        <Route exact path={"/email/marketing/config"} component={Config} />
        <Layout></Layout>
      </Switch>
      <ToastContainer />
    </div>
  );
}

export default App;
