
import axios from "axios";
import { UrlConstants } from "../../global/UrlConstants";
import { getRequest, postRequest } from "../utils";

export const getUnReadCountAPI = () => {
    const url = `${UrlConstants.baseUrl}/newsCount`
    return getRequest(url);
}

export const getUnReadNotification = () => {
    const url = `${UrlConstants.baseUrl}/unReadNews`;
    return getRequest(url);
}

export const newsMarkAsRead = (data: any) => {
    const url = `${UrlConstants.baseUrl}/newsMarkAsRead`;
    return postRequest(url, data);
}