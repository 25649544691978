import axios from "axios";
import { UrlConstants } from "../../global/UrlConstants";
import { downloadRequest, getRequest, patchRequest } from "../../global/utils";


export const getAdminDocumentsAPI = () => {
    const url = `${UrlConstants.baseUrl}/getAdminDocuments/loggedInUserId/${localStorage.getItem("id")}`;
    return getRequest(url);
}

export const downloadDocumentAPI = (data: any) => {
    const url = `${UrlConstants.baseUrl}/document/download/${data?.name}`;
    downloadRequest(url, data?.name);
}

export const viewDocumentsAPI = (fileName: string) => {
    const url = `${UrlConstants.baseUrl}/document/view/${fileName}`;
    return url;
}