import { UrlConstants } from "../../../global/UrlConstants";
import {
  downloadRequest,
  getRequest,
  patchRequest,
  postRequest,
  uploadRequest,
} from "../../../global/utils";

export const viewDocumentsAPI = (fileName: string) => {
  const url = `${UrlConstants.baseUrl}/document/view/${fileName}`;
  return url;
};

export const downloadDocumentAPI = (data: any) => {
  const url = `${UrlConstants.baseUrl}/document/download/${data}`;
  downloadRequest(url, data);
};

export const updateTicketAPI = (ticketData: {
  id: string;
  serialNo: string | null;
  complaintNo: string;
  complaintDatetime: string | null;
  circle: string;
  division: string;
  complainantName: string;
  complainantDesignation: string;
  complainantContactNo: string;
  defectiveItemName: string | null;
  uxb1jsi364g4453780: string;
  engineerAssigned: string;
  engineerAssignedDateTime: string;
  engineerContactNo: string;
  complaintAttemptsFirstDateAndTime: string | null;
  complaintAttemptsSecondDateAndTime: string | null;
  complaintAttemptsThirdDateAndTime: string | null;
  locationCode: string | null;
  complaintCompletionDatetime: string | null;
  status: string | null;
  actionTakenAndSpareUsed: string | null;
  oldSerialNoMbHddTft: string | null;
  newSerialNoMbHddTft: string | null;
  remarks: string | null;
  aeitRemarks: string | null;
  projectName: string;
  product: string;
  machineMake: string;
  problemType: string;
  substation: string;
  landmark: string;
  pinCode: string;
  docPath: string;
  aeitStatus: string | null;
  approverName: string | null;
  approverPhone: string | null;
}) => {
  let subUrl =
    localStorage.getItem("role") === "superAdmin"
      ? `admin/updateTicket/loggedInUserId/${localStorage.getItem("id")}`
      : `engineer/updateTicket`;
  const url = `${UrlConstants.baseUrl}/${subUrl}`;
  const data = ticketData;
  return patchRequest(url, data);
};

export const uploadDocumentAPI = async (fileData: any) => {
  const url = `${UrlConstants.baseUrl}/document`;
  const data = fileData;
  return uploadRequest(url, data);
};

export const getTicketByIdAPI = (id: string) => {
  const url = `${UrlConstants.baseUrl}/getTicket/id/${id}`;
  const data = { text: id };
  return postRequest(url, data);
};
