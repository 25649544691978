import {
  Box,
  Button,
  Chip,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState, useMemo } from "react";
import CustomTable from "../../global/CustomTable/CustomTable";
import { Link, useHistory } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Tab, Tabs, TextField } from "@mui/material";
import {
  adminUnapproveTicket,
  adminUpdateTicket,
  approveTicketByAeitAPI,
  deleteTicketByComplaintNumber,
  exportTicketsAPI,
  getAdminTicketByStatusAndDateRange,
  getAEITTicketByCircleStatusAndDateRange,
  getEngTicketByStatusAndDateRange,
  importTicketsAPI,
  ticketsClosedBeyondSLA,
  ticketsClosedUnderSLA,
  viewDocumentsAPI,
} from "./TicketServices";
import CustomRangePicker from "../../global/CustomRangePicker/CustomRangePicker";
import { getEngineersByStatus } from "../Employee/EmployeeService";
import ReAssignComponent from "./ReAssignComponent";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UrlConstants } from "../../global/UrlConstants";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { MRT_RowSelectionState } from "material-react-table";
import RemarkComponent from "./RemarkComponent";
import { StringConstants } from "../../global/StringConstants";

const emails = ["username@gmail.com", "user02@gmail.com"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigIndicator: {
      height: 5,
    },
    element: {
      margin: 4,
      width: 150,
      height: 27,
    },
    typography: {},
    firstGridItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      padding: "0.5rem",
    },
    secondGridItems: {},
    thirdGridItems: {},
    button: {},
  })
);

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Tickets(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [redirectionalState, setRedirectionalState] = useState(
    props.history.location.state
  );
  const loginUserPhone = localStorage.getItem("phone");
  const loginUserName = localStorage.getItem("userName");
  const isAdmin = localStorage.getItem("role") === "Admin";
  const isSuperAdmin = localStorage.getItem("role") === "superAdmin";
  const isAdminSuperAdmin = isAdmin || isSuperAdmin ? true : false;
  const isAEIT = localStorage.getItem("role") === StringConstants.aeit;
  const userCircle = localStorage.getItem("circle");
  const loggedInUserPhone = localStorage.getItem("phone");
  const [rows, setRows] = useState([]);
  const [tabValue, setTabValue] = useState(
    props.history.location.state?.tabValue ?? redirectionalState
      ? redirectionalState.status
      : "OPEN"
  );
  const [columnFilter, setColumnFilter] = useState(
    props.history.location.state?.columnFilter ?? ""
  );
  const [circleFilter, setCircleFilter] = useState(
    props.history.location.state?.circle
  );
  const [OPEN, setOPEN] = useState(false);
  const [OPENRemarks, setOPENRemarks] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({ aeitRemarks: "" });
  const [engineersList, setengineersList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedPrimaryOption, setSelectedPrimaryOption] = useState("All");
  const [secondaryOptionsList, setSecondaryOptionsList] = useState([]);
  const [defaultDateTime, setDefaultDateTime] = useState(
    redirectionalState
      ? redirectionalState.dateRange
      : ["2023-03-05", moment().format("YYYY-MM-DD")]
  );
  const [zoneOption, setZoneOption] = useState([]);
  const [circleOption, setCircleOption] = useState([]);
  const [divisionOption, setDivisionOption] = useState([]);
  const [subStationOption, setSubStationOption] = useState([]);
  const [uxb1jsi364g4453780Option, setUxb1jsi364g4453780Option] = useState([]);
  const [serialNoOption, setSerialNo] = useState([]);
  const [isFreezedOption, setIsFreezedOption] = useState([]);
  const [complaintNoOption, setComplaintNo] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [problemType, setProblemType] = useState([]);
  const [defectiveItemName, setDefectiveItemName] = useState([]);
  const [machineMake, setMachineMake] = useState([]);
  const [complaintDatetimeOption, setComplaintDatetime] = useState([]);
  const [complainantNameOption, setComplainantName] = useState([]);
  const [approverPhoneOption, setApproverPhoneOption] = useState([]);
  const [engineerAssignedOption, setEngineerAssigned] = useState([]);
  const [engineerContactNoOption, setEngineerContactNo] = useState([]);
  const [aeitStatusOption, setAeitStatus] = useState([]);
  const [complaintCompletionDatetimeOption, setComplaintCompletionDatetime] =
    useState([]);
  const [componentLoaded, setComponentLoaded] = useState(false);

  useEffect(() => {
    document.title = "Tickets";
    getTickets(defaultDateTime[0], defaultDateTime[1]);
  }, [tabValue]);
  // useEffect(() => {
  //   if (isPageNeedRefreshed) {
  //     window.location.reload()
  //   }
  //   setisPageNeedRefreshed(false)
  // }, [tabValue]);

  // const refreshPage = () => {

  // }

  const getTickets = async (fromDate: String, toDate: String) => {
    setLoader(true);
    let response;
    if (tabValue === "OPEN") {
      if (isAdmin || isSuperAdmin) {
        response = await getAdminTicketByStatusAndDateRange(
          "OPEN",
          fromDate,
          toDate,
          circleFilter
        );
      } else if (isAEIT) {
        response = await getAEITTicketByCircleStatusAndDateRange(
          "OPEN",
          userCircle,
          fromDate,
          toDate
        );
      } else {
        response = await getEngTicketByStatusAndDateRange(
          loggedInUserPhone,
          "OPEN",
          fromDate,
          toDate
        );
      }
    } else if (tabValue === "CLOSED") {
      if (isAdmin || isSuperAdmin) {
        response = await getAdminTicketByStatusAndDateRange(
          "CLOSED",
          fromDate,
          toDate,
          circleFilter
        );
      } else if (isAEIT) {
        response = await getAEITTicketByCircleStatusAndDateRange(
          "CLOSED",
          userCircle,
          fromDate,
          toDate
        );
      } else {
        response = await getEngTicketByStatusAndDateRange(
          loggedInUserPhone,
          "CLOSED",
          fromDate,
          toDate
        );
      }
    } else if (tabValue === "ALL") {
      if (isAdmin || isSuperAdmin) {
        if (columnFilter.columnName === "ticketsClosedUnderSLA") {
          response = await ticketsClosedUnderSLA(
            circleFilter ?? "ALL",
            fromDate,
            toDate
          );
        } else if (columnFilter.columnName === "ticketsClosedBeyondSLA") {
          response = await ticketsClosedBeyondSLA("ALL", fromDate, toDate);
        } else {
          response = await getAdminTicketByStatusAndDateRange(
            "ALL",
            fromDate,
            toDate,
            circleFilter
          );
        }
      } else if (isAEIT) {
        response = await getAEITTicketByCircleStatusAndDateRange(
          "ALL",
          userCircle,
          fromDate,
          toDate
        );
      } else {
        response = await getEngTicketByStatusAndDateRange(
          loggedInUserPhone,
          "ALL",
          fromDate,
          toDate
        );
      }
    } else if (tabValue === "Approved") {
      if (isAdmin || isSuperAdmin) {
        response = await getAdminTicketByStatusAndDateRange(
          "Approved",
          fromDate,
          toDate,
          circleFilter
        );
      } else if (isAEIT) {
        response = await getAEITTicketByCircleStatusAndDateRange(
          "Approved",
          userCircle,
          fromDate,
          toDate
        );
      } else {
        response = await getEngTicketByStatusAndDateRange(
          loggedInUserPhone,
          "Approved",
          fromDate,
          toDate
        );
      }
    }

    if (columnFilter.columnName === "isFreezed") {
      let filteredData = response.filter(
        (data: any) => data.isFreezed === columnFilter.columnValue
      );
      if (circleFilter) {
        filteredData = filteredData.filter(
          (data: any) => data.circle === circleFilter
        );
      }
      //code
      setRows(filteredData ?? []);
      setDropDownOptions(filteredData);
      setLoader(false);
    } else if (columnFilter.columnName === "problemType") {
      let filteredProduct = response.filter(
        (data: any) => data.product === columnFilter.productName
      );
      let filteredData = filteredProduct.filter(
        (data: any) => data.problemType === columnFilter.columnValue
      );
      if (circleFilter) {
        filteredData = filteredData.filter(
          (data: any) => data.circle === circleFilter
        );
      }
      setRows(filteredData ?? []);
      setDropDownOptions(filteredData);
      setLoader(false);
    } else if (columnFilter.columnName === "aeitStatus") {
      let filteredData = response.filter(
        (data: any) => data.aeitStatus === columnFilter.columnValue
      );
      if (circleFilter) {
        filteredData = filteredData.filter(
          (data: any) => data.circle === circleFilter
        );
      }
      setRows(filteredData ?? []);
      setDropDownOptions(filteredData);
      setLoader(false);
    } else {
      setRows(response ?? []);
      setDropDownOptions(response);
      setLoader(false);
    }
  };

  //Dropdown Filter Started
  const tableDataFiltered = (rows: any) => {
    const location = { zone: null, circle: [], division: [], substation: [] };
    location.zone = rows.map((row: any) => row.zone);
    if (allEqual(location.zone)) {
      location.circle = removeDuplicates(rows.map((row: any) => row?.circle));
      location.division = removeDuplicates(
        rows.map((row: any) => row?.division)
      );
      location.substation = removeDuplicates(
        rows.map((row: any) => row?.substation)
      );
      if (allEqual(location?.circle)) {
        location.division = removeDuplicates(
          rows.map((row: any) => row?.division)
        );
        location.substation = removeDuplicates(
          rows.map((row: any) => row?.substation)
        );
        if (allEqual(location?.division)) {
          location.substation = removeDuplicates(
            rows.map((row: any) => row?.substation)
          );
          if (allEqual(location?.substation)) {
            setComplaintNo(
              removeDuplicates(rows.map((row: any) => row?.complaintNo))
            );
            setMachineMake(
              removeDuplicates(rows.map((row: any) => row?.machineMake))
            );
            setProductOption(
              removeDuplicates(rows.map((row: any) => row?.product))
            ); // can create issue
            setProblemType(
              removeDuplicates(rows.map((row: any) => row?.setProblemType))
            ); // can create issue
            setSerialNo(
              removeDuplicates(rows.map((row: any) => row?.serialNo))
            );
            setUxb1jsi364g4453780Option(
              removeDuplicates(rows.map((row: any) => row?.uxb1jsi364g4453780))
            );
            setDefectiveItemName(
              removeDuplicates(rows.map((row: any) => row?.defectiveItemName))
            );
            setComplaintDatetime(
              removeDuplicates(rows.map((row: any) => row?.complaintDatetime))
            );
            setComplainantName(
              removeDuplicates(rows.map((row: any) => row?.complainantName))
            );
            setApproverPhoneOption(
              removeDuplicates(rows.map((row: any) => row?.approverPhone))
            );
            setEngineerAssigned(
              removeDuplicates(rows.map((row: any) => row?.engineerAssigned))
            );
            setEngineerContactNo(
              removeDuplicates(rows.map((row: any) => row?.engineerContactNo))
            );
          }
        }
      }
    }
    handleZoneDropdownChange(location);
  };

  const handleClearFilter = (event: any) => {
    window.location.reload();
  };

  const handleZoneDropdownChange = (location: any) => {
    if (location?.circle?.length > 0) {
      setCircleOption(location?.circle);
      setComponentLoaded(true);
    }
    if (location?.division?.length > 0) {
      setDivisionOption(location?.division);
      setComponentLoaded(true);
    }
    if (location?.substation?.length > 0) {
      setSubStationOption(location?.substation);
      setComponentLoaded(true);
    }
  };

  const allEqual = (arr: any) => arr.every((val: any) => val === arr[0]);
  //Dropdown Filter Ended

  const setDropDownOptions = (response: any) => {
    const zones = response.map((r: any) => r.zone);
    setZoneOption(removeDuplicates(zones));
    const circles = response.map((r: any) => r.circle);
    setCircleOption(removeDuplicates(circles));
    const division = response.map((r: any) => r.division);
    setDivisionOption(removeDuplicates(division));
    const substation = response.map((r: any) => r.substation);
    setSubStationOption(removeDuplicates(substation));
    const uxb1jsi364g4453780 = response.map((r: any) => r.uxb1jsi364g4453780);
    setUxb1jsi364g4453780Option(removeDuplicates(uxb1jsi364g4453780));
    const serialNo = response.map((r: any) => r.serialNo);
    setSerialNo(removeDuplicates(serialNo));
    const isFreezed = response.map((r: any) => r.isFreezed);
    setIsFreezedOption(removeDuplicates(isFreezed));
    const complaintNo = response.map((r: any) => r.complaintNo);
    setComplaintNo(removeDuplicates(complaintNo));
    const product = response.map((r: any) => r.product);
    setProductOption(removeDuplicates(product));
    const problemType = response.map((r: any) => r.problemType);
    setProblemType(removeDuplicates(problemType));
    const complaintDatetime = response.map((r: any) => r.complaintDatetime);
    setComplaintDatetime(removeDuplicates(complaintDatetime));
    const complainantName = response.map((r: any) => r.complainantName);
    setComplainantName(removeDuplicates(complainantName));
    const approverPhone = response.map((r: any) => r.approverPhone);
    setApproverPhoneOption(removeDuplicates(approverPhone));
    const engineerAssigned = response.map((r: any) => r.engineerAssigned);
    setEngineerAssigned(removeDuplicates(engineerAssigned));
    const engineerContactNo = response.map((r: any) => r.engineerContactNo);
    setEngineerContactNo(removeDuplicates(engineerContactNo));
    const complaintCompletionDatetime = response.map(
      (r: any) => r.complaintCompletionDatetime
    );
    setComplaintCompletionDatetime(
      removeDuplicates(complaintCompletionDatetime)
    );
    const aeitStatus = response.map((r: any) => r.aeitStatus);
    setAeitStatus(removeDuplicates(aeitStatus));
    const defectiveItemName = response.map((r: any) => r.defectiveItemName);
    setDefectiveItemName(removeDuplicates(defectiveItemName));
    const machineMake = response.map((r: any) => r.machineMake);
    setMachineMake(removeDuplicates(machineMake));
  };

  function removeDuplicates(arr: any) {
    return arr?.filter(
      (item: any, index: any) => item && arr?.indexOf(item) === index
    );
  }

  const redirectToEdit = (event: any) => {
    if (isAdminSuperAdmin) {
      // const response = await getEngineersByStatus("Active");
      const response = "";
      return (
        <Link
          target="_blank"
          to={{ pathname: `/edit/${event}`, state: { id: response } }}
        >
          {" "}
          <EditIcon fontSize="small" />{" "}
        </Link>
      );
    } else {
      return (
        <Link target="_blank" to={{ pathname: `/edit/${event}` }}>
          {" "}
          <EditIcon fontSize="small" />{" "}
        </Link>
      );
    }
  };

  const columnsForEmployee = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "zone",
      header: "Zone",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: zoneOption,
    },
    {
      accessorKey: "circle",
      header: "Circle",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: circleOption,
    },
    {
      accessorKey: "division",
      header: "Division",
      size: 160,
      filterVariant: "select",
      filterSelectOptions: divisionOption,
    },
    {
      accessorKey: "substation",
      header: "End Location",
      size: 350,
      filterVariant: "select",
      filterSelectOptions: subStationOption,
    },
    {
      accessorKey: "complaintNo",
      header: "Complaint No",
      size: 120,
      filterVariant: "select",
      filterSelectOptions: complaintNoOption,
    },
    {
      accessorKey: "uxb1jsi364g4453780",
      header: "Serial No",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: uxb1jsi364g4453780Option,
    },
    {
      accessorKey: "complaintDatetime",
      header: "Complaint Date & Time",
      size: 200,
      enableSorting: false,
      filterVariant: "select",
      filterSelectOptions: complaintDatetimeOption,
    },
    {
      accessorKey: "complainantName",
      header: "Complainant Name",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: complainantNameOption,
    },
    {
      accessorKey: "complainantContactNo",
      header: "Complainant Contact No",
      size: 200,
    },
    {
      accessorKey: "View/Edit",
      enableColumnFilter: false,
      header: "View/Edit",
      size: 190,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <strong>{redirectToEdit(cell.row.original.id)}</strong>
      ),
    },
  ];

  const columnsForAEIT = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "isFreezed",
      header: "FREEZE STATUS",
      size: 181,
      filterVariant: "select",
      filterSelectOptions: isFreezedOption,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.isFreezed === "FREEZED" ? (
            <Typography style={{ color: "#009900" }}>
              {cell.row.original.isFreezed}
            </Typography>
          ) : (
            <Typography style={{ color: "#f44336" }}>
              {cell.row.original.isFreezed}
            </Typography>
          )}
        </>
      ),
    },
    {
      accessorKey: "zone",
      header: "Zone",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: zoneOption,
    },
    {
      accessorKey: "circle",
      header: "Circle",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: circleOption,
    },
    {
      accessorKey: "division",
      header: "Division",
      size: 160,
      filterVariant: "select",
      filterSelectOptions: divisionOption,
    },
    {
      accessorKey: "substation",
      header: "End Location",
      size: 350,
      filterVariant: "select",
      filterSelectOptions: subStationOption,
    },
    {
      accessorKey: "complaintNo",
      header: "Complaint No",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: complaintNoOption,
    },
    {
      accessorKey: "product",
      header: "Product",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: productOption,
    },
    {
      accessorKey: "problemType",
      header: "Problem Type",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: problemType,
    },
    {
      accessorKey: "uxb1jsi364g4453780",
      header: "Serial No",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: uxb1jsi364g4453780Option,
    },
    {
      accessorKey: "complaintDatetime",
      header: "Complaint DateTime",
      size: 200,
      enableSorting: false,
      filterVariant: "select",
      filterSelectOptions: complaintDatetimeOption,
    },
    {
      accessorKey: "complainantName",
      header: "Complainant Name",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: complainantNameOption,
    },
    {
      accessorKey: "complainantContactNo",
      header: "Complainant No",
      size: 160,
    },
    {
      accessorKey: "aeitStatus",
      header: "Varification",
      size: 10,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.aeitStatus === "Approved" ? (
            <Typography style={{ color: "#009900" }}>Approved</Typography>
          ) : (
            <Typography style={{ color: "#f44336" }}>
              {cell.row.original.aeitStatus}
            </Typography>
          )}
        </>
      ),
    },
    {
      accessorKey: "approverPhone",
      header: "Verified By",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: approverPhoneOption,
    },
    {
      accessorKey: "aeitRemarks",
      header: "Remarks by Verifier",
      size: 160,
    },
    {
      accessorKey: "docPath",
      header: "Document",
      size: 210,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.docPath && (
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              handleViewDocument(cell.row.original);
            }}
          >
            <InsertPhotoIcon fontSize="small" />
          </IconButton>
        ),
    },
    {
      accessorKey: "View/Edit",
      enableColumnFilter: false,
      header: "View/Edit",
      size: 110,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <strong>{redirectToEdit(cell.row.original.id)}</strong>
      ),
    },
    {
      accessorKey: "aeitStatus",
      header: "Action",
      size: 300,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {!["Approved"].includes(cell.row.original.aeitStatus) && (
            <Button
              size="small"
              style={{
                color: "white",
                backgroundColor:
                  cell.row.original.aeitStatus === "Approved"
                    ? "#f44336"
                    : "#008000",
                // marginTop: 20,
                marginRight: 20,
                // marginLeft: 4,
                // marginBottom: 20,
                // minWidth: 120,
              }}
              // type="submit"
              onClick={() =>
                cell.row.original.aeitStatus !== "Approved" &&
                handleApproveButton(cell.row.original)
              }
            >
              {cell.row.original.aeitStatus === "Approved"
                ? "UnApprove"
                : "Approve"}
            </Button>
          )}
          {
            !["Approved"].includes(cell.row.original.aeitStatus) && (
              <Chip
                size="small"
                label="Add Remark"
                onClick={() => {
                  handleAddRemarks(cell.row.original);
                }}
                style={{ color: "white", backgroundColor: "#f44336" }}
              />
            )
            // (<Button
            //   size="small"
            //   style={{
            //     color: "white",
            //     backgroundColor: "#f75f31",
            //     marginTop: 20,
            //     marginLeft: 4,
            //     marginBottom: 20,
            //     minWidth: 120,
            //   }}
            //   onClick={() => handleRejectButton(cell.row.original)
            //   }
            // >
            //   {"Reject"}
            // </Button>)
          }
        </>
      ),
    },
  ];

  const columnsForAdmin = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "isFreezed",
      header: "FREEZE STATUS",
      size: 181,
      filterVariant: "select",
      filterSelectOptions: isFreezedOption,
      // showColumnFilters: false,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.isFreezed === "FREEZED" ? (
            <Typography style={{ color: "#009900" }}>
              {cell.row.original.isFreezed}
            </Typography>
          ) : (
            <Typography style={{ color: "#f44336" }}>
              {cell.row.original.isFreezed}
            </Typography>
          )}
        </>
      ),
    },
    {
      accessorKey: "zone",
      header: "Zone",
      size: 180,
      filterVariant: "select",
      // showColumnFilters: false,
      filterSelectOptions: zoneOption,
    },
    {
      accessorKey: "circle",
      header: "Circle",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: circleOption,
    },
    {
      accessorKey: "division",
      header: "Division",
      size: 160,
      filterVariant: "select",
      filterSelectOptions: divisionOption,
    },
    {
      accessorKey: "substation",
      header: "End Location",
      size: 350,
      filterVariant: "select",
      filterSelectOptions: subStationOption,
    },
    {
      accessorKey: "complaintNo",
      header: "Complaint No",
      size: 190,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "lect",
      },
      filterVariant: "select",
      filterSelectOptions: complaintNoOption,
    },
    {
      accessorKey: "product",
      header: "Product",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: productOption,
    },
    {
      accessorKey: "problemType",
      header: "Problem Type",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: problemType,
    },
    {
      accessorKey: "machineMake",
      header: "Machine Make",
      size: 200,
      filterVariant: "select",
      filterSelectOptions: machineMake,
    },
    {
      accessorKey: "uxb1jsi364g4453780",
      header: "Serial No",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: uxb1jsi364g4453780Option,
    },
    {
      accessorKey: "defectiveItemName",
      header: "Defective Item name",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: defectiveItemName,
    },
    {
      accessorKey: "complaintDatetime",
      header: "Complaint Date & Time",
      size: 290,
      enableSorting: true,
      filterVariant: "select",
      filterSelectOptions: complaintDatetimeOption,
    },
    {
      accessorKey: "complainantName",
      header: "Complainant Name",
      size: 260,
      filterVariant: "select",
      filterSelectOptions: complainantNameOption,
    },
    {
      accessorKey: "engineerAssigned",
      header: "Engineer Assigned",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: engineerAssignedOption,
    },
    {
      accessorKey: "engineerContactNo",
      header: "Engineer Contact Number",
      size: 310,
      filterVariant: "select",
      filterSelectOptions: engineerContactNoOption,
    },
    {
      accessorKey: "aeitStatus",
      header: "Varification",
      size: 210,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.aeitStatus === "Approved" ? (
            <Typography style={{ color: "#009900" }}>Approved</Typography>
          ) : (
            <Typography style={{ color: "#f44336" }}>
              {cell.row.original.aeitStatus}
            </Typography>
          )}
        </>
      ),
      filterVariant: "select",
      filterSelectOptions: aeitStatusOption,
    },
    {
      accessorKey: "approverPhone",
      header: "Verified By",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: approverPhoneOption,
    },
    {
      accessorKey: "aeitRemarks",
      header: "Remarks by Verifier",
      size: 160,
    },
    {
      accessorKey: "complaintCompletionDatetime",
      header: "Complaint Completion Date & Time",
      size: 390,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: (cell: GridRenderCellParams) => (
        <TextField
          variant="standard"
          disabled={true}
          type="datetime-local"
          style={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
          name="complaintDatetime"
          defaultValue={cell.row.original.complaintCompletionDatetime}
          size="small"
        />
      ),
      filterVariant: "select",
      filterSelectOptions: complaintCompletionDatetimeOption,
    },
    {
      accessorKey: "Re-Assign",
      header: "Re-Assign",
      enableColumnFilter: false,
      size: 210,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <Chip
          size="small"
          label="Re-assign"
          onClick={() => {
            handleReAssign(cell.row.original);
          }}
          style={{ color: "white", backgroundColor: "#f44336" }}
        />
      ),
    },
    {
      accessorKey: "docPath",
      header: "Document",
      size: 210,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.docPath && (
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              handleViewDocument(cell.row.original);
            }}
          >
            <InsertPhotoIcon fontSize="small" />
          </IconButton>
        ),
    },
    {
      accessorKey: "View/Edit",
      enableColumnFilter: false,
      header: "View/Edit",
      size: 190,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <strong>{redirectToEdit(cell.row.original.id)}</strong>
      ),
    },
  ];

  const columnsForSuperAdmin = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "isFreezed",
      header: "FREEZE STATUS",
      size: 181,
      filterVariant: "select",
      filterSelectOptions: isFreezedOption,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.isFreezed === "FREEZED" ? (
            <Typography style={{ color: "#009900" }}>
              {cell.row.original.isFreezed}
            </Typography>
          ) : (
            <Typography style={{ color: "#f44336" }}>
              {cell.row.original.isFreezed}
            </Typography>
          )}
        </>
      ),
    },
    {
      accessorKey: "zone",
      header: "Zone",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: zoneOption,
    },
    {
      accessorKey: "circle",
      header: "Circle",
      size: 180,
      filterVariant: "select",
      filterSelectOptions: circleOption,
    },
    {
      accessorKey: "division",
      header: "Division",
      size: 160,
      filterVariant: "select",
      filterSelectOptions: divisionOption,
    },
    {
      accessorKey: "substation",
      header: "End Location",
      size: 350,
      filterVariant: "select",
      filterSelectOptions: subStationOption,
    },
    {
      accessorKey: "complaintNo",
      header: "Complaint No",
      // minSize: 200,
      // maxSize: 200,
      size: 220,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "lect",
      },
      filterVariant: "select",
      filterSelectOptions: complaintNoOption,
    },
    {
      accessorKey: "product",
      header: "Product",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: productOption,
    },
    {
      accessorKey: "problemType",
      header: "Problem Type",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: problemType,
    },
    {
      accessorKey: "machineMake",
      header: "Machine Make",
      size: 200,
      filterVariant: "select",
      filterSelectOptions: machineMake,
    },
    {
      accessorKey: "uxb1jsi364g4453780",
      header: "Serial No",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: uxb1jsi364g4453780Option,
    },
    {
      accessorKey: "defectiveItemName",
      header: "Defective Item name",
      size: 150,
      filterVariant: "select",
      filterSelectOptions: defectiveItemName,
    },
    {
      accessorKey: "complaintDatetime",
      header: "Complaint Date & Time",
      size: 290,
      enableSorting: false,
      filterVariant: "select",
      filterSelectOptions: complaintDatetimeOption,
    },
    {
      accessorKey: "complainantName",
      header: "Complainant Name",
      size: 260,
      filterVariant: "select",
      filterSelectOptions: complainantNameOption,
    },
    {
      accessorKey: "engineerAssigned",
      header: "Engineer Assigned",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: engineerAssignedOption,
    },
    {
      accessorKey: "engineerContactNo",
      header: "Engineer Contact Number",
      size: 310,
      filterVariant: "select",
      filterSelectOptions: engineerContactNoOption,
    },
    {
      accessorKey: "aeitStatus",
      header: "Varification",
      size: 210,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.aeitStatus === "Approved" ? (
            <Typography style={{ color: "#009900" }}>Approved</Typography>
          ) : (
            <Typography style={{ color: "#f44336" }}>
              {cell.row.original.aeitStatus}
            </Typography>
          )}
        </>
      ),
      filterVariant: "select",
      filterSelectOptions: aeitStatusOption,
    },
    {
      accessorKey: "approverPhone",
      header: "Verified By",
      size: 250,
      filterVariant: "select",
      filterSelectOptions: approverPhoneOption,
    },
    {
      accessorKey: "aeitRemarks",
      header: "Remarks by Verifier",
      size: 160,
    },
    {
      accessorKey: "complaintCompletionDatetime",
      header: "Complaint Completion Date & Time",
      size: 390,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: (cell: GridRenderCellParams) => (
        <TextField
          variant="standard"
          disabled={true}
          type="datetime-local"
          style={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
          name="complaintDatetime"
          defaultValue={cell.row.original.complaintCompletionDatetime}
          size="small"
        />
      ),
      filterVariant: "select",
      filterSelectOptions: complaintCompletionDatetimeOption,
    },
    {
      accessorKey: "Re-Assign",
      header: "Re-Assign",
      enableColumnFilter: false,
      size: 210,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <Chip
          size="small"
          label="Re-assign"
          onClick={() => {
            handleReAssign(cell.row.original);
          }}
          style={{ color: "white", backgroundColor: "#f44336" }}
        />
      ),
    },
    {
      accessorKey: "docPath",
      header: "Document",
      enableColumnFilter: false,
      size: 210,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.docPath && (
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              handleViewDocument(cell.row.original);
            }}
          >
            <InsertPhotoIcon fontSize="small" />
          </IconButton>
        ),
    },
    {
      accessorKey: "aeitStatus",
      header: StringConstants.aeit + " Action",
      size: 300,
      Cell: (cell: GridRenderCellParams) => (
        <>
          <Button
            size="small"
            style={{
              color: "white",
              backgroundColor:
                cell.row.original.aeitStatus === "Approved"
                  ? "#f44336"
                  : "#008000",
              // marginTop: 20,
              // marginRight: 20,
              // marginLeft: 4,
              // marginBottom: 20,
              // minWidth: 120,
            }}
            // type="submit"
            onClick={() =>
              cell.row.original.aeitStatus === "Approved"
                ? handleUnApproveButton(cell.row.original)
                : handleApproveButton(cell.row.original)
            }
          >
            {cell.row.original.aeitStatus === "Approved"
              ? "UnApprove"
              : "Approve"}
          </Button>
          {
            // !["UnApproved", "Approved"].includes(cell.row.original.aeitStatus) &&
            <Chip
              size="small"
              label="Add Remark"
              onClick={() => {
                handleAddRemarks(cell.row.original);
              }}
              style={{ color: "white", backgroundColor: "#f44336" }}
            />
            // (<Button
            //   size="small"
            //   style={{
            //     color: "white",
            //     backgroundColor: "#f75f31",
            //     marginTop: 20,
            //     marginLeft: 4,
            //     marginBottom: 20,
            //     minWidth: 120,
            //   }}
            //   onClick={() => handleRejectButton(cell.row.original)
            //   }
            // >
            //   {"Reject"}
            // </Button>)
          }
        </>
      ),
    },
    {
      accessorKey: "View/Edit",
      enableColumnFilter: false,
      header: "View/Edit",
      size: 190,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) => (
        <strong>{redirectToEdit(cell.row.original.id)}</strong>
      ),
    },
    {
      accessorKey: "Delete",
      enableColumnFilter: false,
      header: "Delete",
      width: 120,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (params: GridRenderCellParams) => (
        <strong>
          <IconButton
            size="small"
            style={{ marginLeft: 2 }}
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={() => {
              deleteRow(params.row.original.complaintNo);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </strong>
      ),
    },
  ];

  const handleViewDocument = (data: any) => {
    window.open(viewDocumentsAPI(data?.docPath));
  };

  const deleteRow = (complaintNumber: string) => {
    const confirmBox = window.confirm(
      "Do you really want to delete Ticket No: ".concat(complaintNumber)
    );
    if (confirmBox === true) {
      const finalConfirmBox = window.confirm(
        "Do you really want to delete Ticket No: "
          .concat(complaintNumber)
          .concat(" Permanently")
      );
      if (finalConfirmBox === true) {
        const response = deleteTicketByComplaintNumber(complaintNumber);
        response.then(function (response) {
          toast.success("Successfully Deleted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.reload();
        });
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedPrimaryOption("All");
    setSecondaryOptionsList([]);
    setTabValue(newValue);
    if (newValue === "OPEN") {
    } else {
      setRows([]);
    }
  };

  const handleReAssign = async (row: any) => {
    setOPEN(true);
    const response = await getEngineersByStatus("Active");
    setengineersList(response);
    setSelectedTicket(row);
  };

  const handleAddRemarks = async (row: any) => {
    setOPENRemarks(true);
    setSelectedTicket(row);
  };

  const handleClose = (value: string) => {
    setOPEN(false);
  };

  const handleDateRangeChange = (date: any) => {
    getTickets(date[0], date[1]);
    setDefaultDateTime(date);
  };

  const handleApproveButton = (selectedTicket: any) => {
    const response = adminUpdateTicket(
      selectedTicket,
      loginUserPhone,
      loginUserName
    );
    response
      .then(function (response) {
        toast.success("Ticket Approved!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        window.location.reload();
        setTimeout(() => history.push("/tickets"), 700);
      })
      .catch(function (error) {
        toast.error("Error while updating!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleUnApproveButton = (selectedTicket: any) => {
    const response = adminUnapproveTicket(selectedTicket);
    response
      .then(function (response) {
        toast.success("Ticket UnApproved!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => history.push("/tickets"), 700);
      })
      .catch(function (error) {
        toast.error("Error while updating!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const onFileDropped = (event: any) => {
    if (event.target.files[0]?.name) {
      const response = importTicketsAPI(event.target.files[0]);
      response
        .then(function (response) {
          toast.success("Ticket Imported Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.reload();
        })
        .catch(function (error) {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const handleExportData = (rows: any[]) => {
    const payload = rows.map((row) => row.original.id);
    const response = exportTicketsAPI(payload);
    response
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Exported-Tickets` + ".xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  const handleApproveByAeit = (rows: any[], selectedRow: any) => {
    //code
    let data = Object?.keys(selectedRow)?.map(
      (r: string) => rows?.at(Number(r))?.original?.id
    );
    const response = approveTicketByAeitAPI(data);
    response
      .then(function (response) {
        toast.success("Ticket Approved!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => history.push("/tickets"), 700);
      })
      .catch(function (error) {
        toast.error("You are not authorized!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const tabComponent = () => {
    return (
      <Tabs
        value={tabValue}
        classes={{ indicator: classes.bigIndicator }}
        onChange={handleTabChange}
        textColor="inherit"
        TabIndicatorProps={{ style: { background: "#ffffff" } }}
        aria-label="secondary tabs example"
      >
        <Tab value="OPEN" label="OPEN" />
        <Tab style={{ marginLeft: "0.5rem" }} value="CLOSED" label="CLOSED" />
        <Tab
          style={{ marginLeft: "0.5rem" }}
          value="Approved"
          label="APPROVED"
        />
        <Tab style={{ marginLeft: "0.5rem" }} value="ALL" label="ALL" />
      </Tabs>
    );
  };

  const complaintDatetimeRangePicker = () => {
    return (
      (isAdminSuperAdmin || isAEIT) && (
        <CustomRangePicker
          label="Filter "
          fromDate={defaultDateTime[0]}
          toDate={defaultDateTime[1]}
          handleDateRangeChange={handleDateRangeChange}
        />
      )
    );
  };

  const tableComponent = () => {
    return (
      <CustomTable
        isAEIT={isAEIT}
        showAeitApproveButton={isAEIT}
        enableRowSelection={isAEIT}
        handleApproveByAeit={handleApproveByAeit}
        loader={loader}
        onFileDropped={onFileDropped}
        data={rows}
        handleExportData={handleExportData}
        columns={
          isSuperAdmin
            ? columnsForSuperAdmin
            : isAdmin
            ? columnsForAdmin
            : isAEIT
            ? columnsForAEIT
            : columnsForEmployee
        }
        tableDataFiltered={tableDataFiltered}
        handleClearFilter={handleClearFilter}
        componentLoaded={componentLoaded}
      />
    );
  };

  const reAssignComponent = () => {
    return (
      <ReAssignComponent
        open={OPEN}
        onClose={handleClose}
        engineersList={engineersList}
        selectedTicket={selectedTicket}
      />
    );
  };

  const remarksComponent = () => {
    return (
      <RemarkComponent
        open={OPENRemarks}
        onClose={handleClose}
        selectedTicket={selectedTicket}
        aeitRemarks={selectedTicket?.aeitRemarks}
      />
    );
  };

  return (
    <>
      <Box overflow={"scroll"} style={{ height: "600px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justifyContent="flex-end">
              <Grid item>{complaintDatetimeRangePicker()}</Grid>
              {/* <Grid item>
              {selectFilterComponent()}
            </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {tabComponent()}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item>{tableComponent()}</Item>
          </Grid>
          <Item>{reAssignComponent()}</Item>
          <Item>{remarksComponent()}</Item>
        </Grid>
        <ToastContainer />
      </Box>
    </>
  );
}
