import { useMemo } from "react";
import MaterialReactTable, {
  MRT_RowSelectionState,
} from "material-react-table";
import { createTheme, Grid, ThemeProvider, useTheme } from "@mui/material";
import { Box, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";

const CustomTable = (props: any) => {
  const globalTheme = useTheme();
  const isAccessToExport =
    localStorage.getItem("role") === "superAdmin" ||
    localStorage.getItem("role") === "Admin" ||
    localStorage.getItem("role") === "Aeit" ||
    localStorage.getItem("role") === "IT-Nodal"
      ? true
      : false;
  const [progress, setProgress] = useState(0);
  const [dataNotLoaded, setIsDataNotLoaded] = useState(true);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  useEffect(() => {
    const reloadCount = Number(localStorage.getItem("reloadCount"));
    if (reloadCount < 1) {
      // setTimeout(function () {
      localStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
      // }, 500);
      if (dataNotLoaded) {
        const timer = setInterval(() => {
          setProgress((oldProgress: number) => {
            if (oldProgress === 100) {
              return 0;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
          setIsDataNotLoaded(() => false);
        }, 500);

        return () => {
          clearInterval(timer);
        };
      }
    }
  }, []);

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            // default:
            //   globalTheme.palette.mode === "light"
            //     ? "rgb(254,255,244)" //random light yellow color for the background in light mode
            //     : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const handleExportRows = (rows: any[]) => {
    props.handleExportData(rows);
  };

  const selection = (e: any) => {};

  const allEqual = (arr: any) => arr.every((val: any) => val === arr[0]);

  const filteredData = (data: any) => {
    let zone = data.map((row: any) => row.zone);
    if (allEqual(zone)) {
      props?.tableDataFiltered(data);
    }
  };

  const removeEndLocationColumn = (array: any) => {
    if (localStorage.getItem("account") === "dvvnl") {
      // const index = array.findIndex(
      //   (obj: any) => obj["header"] === "End Location"
      // );
      // if (index !== -1) {
      //   array.splice(index, 1);
      // }
      const isFreezedindex = array.findIndex(
        (obj: any) => obj["header"] === "FREEZE STATUS"
      );
      if (isFreezedindex !== -1) {
        array.splice(isFreezedindex, 1);
      }
      const circleSurvey = array.findIndex(
        (obj: any) => obj["header"] === "Circle (For Survey)"
      );
      if (circleSurvey !== -1) {
        array.splice(circleSurvey, 1);
      }
      return array;
    } else {
      return array;
    }
  };

  const table = () => {
    return props.loader ? (
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          color="inherit"
          variant="determinate"
          value={progress}
        />
        <MaterialReactTable columns={[]} data={[]} />
      </Box>
    ) : (
      <div style={{ height: "auto" }}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableMultiRowSelection
            selectAllMode="all"
            enableRowSelection={props?.enableRowSelection}
            getRowId={(row) => props.data.id} //give each row a more useful id
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ rowSelection }}
            columns={removeEndLocationColumn(props?.columns)}
            data={props.data}
            // enableRowNumbers={true}
            // enableHiding={false}
            enableSorting={false}
            enableColumnActions={false}
            muiTablePaginationProps={{
              rowsPerPageOptions: [20, 50, 100, 1000],
              showFirstButton: false,
              showLastButton: false,
              sx: {
                ".MuiPaper-root": {
                  backgroundColor: "rosybrown",
                  color: "black",
                },
                ".MuiTablePagination-menuItem": {
                  ":hover": {
                    backgroundColor: "turquoise",
                  },
                  backgroundColor: "yellow",
                },
                ".MuiTablePagination-menuItem.Mui-selected": {
                  ":hover": {
                    backgroundColor: "blue",
                  },
                  backgroundColor: "purple",
                },
              },
            }}
            muiTableProps={{
              sx: {
                tableLayout: "fixed",
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                flex: "0 0 auto",
              },
            }}
            muiTableBodyRowProps={{ hover: true }}
            enableDensityToggle={false}
            initialState={{
              density: "compact",
              showColumnFilters: true,
              pagination: { pageSize: props?.pageSize ?? 20, pageIndex: 0 },
              showGlobalFilter: true,
            }}
            muiSelectCheckboxProps={{
              color: "secondary", //makes all checkboxes use the secondary color
            }}
            muiTableHeadCellProps={{
              sx: {
                fontWeight: "bold",
                fontSize: "15px",
              },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <>
                {!props?.componentLoaded &&
                  props?.tableDataFiltered &&
                  filteredData(
                    table
                      .getPrePaginationRowModel()
                      .rows.map((row) => row.original)
                  )}
                <Box
                  sx={{
                    display: "flex",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid container>
                    {/* <Grid style={{ padding: 8 }}>
                      {props.onFileDropped && isAdmin && (
                        <label htmlFor="contained-button-file">
                          <input
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={(files) => props.onFileDropped(files)}
                          />
                          <Button variant="outlined" component="span">
                            {props?.onFileDroppedText ?? "Import"}
                          </Button>
                        </label>
                      )}
                    </Grid> */}
                    <Grid style={{ padding: 8 }}>
                      {props.handleExportData && isAccessToExport && (
                        <Button
                          disabled={
                            table.getPrePaginationRowModel().rows.length === 0
                          }
                          onClick={() =>
                            handleExportRows(
                              table.getPrePaginationRowModel().rows
                            )
                          }
                          // startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Export
                        </Button>
                      )}
                    </Grid>
                    <Grid style={{ padding: 8 }}>
                      {props.handleClearFilter && isAccessToExport && (
                        <Button
                          onClick={() => props?.handleClearFilter()}
                          // startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Clear Filter
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>

                {props.showAeitApproveButton &&
                  Object.keys(rowSelection).length > 0 && (
                    <>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box></Box>
                      <Box>
                        <Grid style={{ padding: 0 }}>
                          <Button
                            onClick={() =>
                              props.handleApproveByAeit(
                                table.getPrePaginationRowModel().rows,
                                rowSelection
                              )
                            }
                            variant="outlined"
                            component="span"
                            style={{
                              color: "white",
                              backgroundColor: "#008000",
                            }}
                          >
                            {`Approve ${
                              Object.keys(rowSelection).length
                            } Selected Tickets`}
                          </Button>
                        </Grid>
                      </Box>
                    </>
                  )}
              </>
            )}
          />
        </ThemeProvider>
      </div>
    );
  };

  return table();
};
export default CustomTable;
