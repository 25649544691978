import { UrlConstants } from "../../UrlConstants";
import { getRequest } from "../../utils";

export const trackComplaintByPhone = (phone: string | undefined) => {
  const url = `${UrlConstants.CoreUrl}/public/track/complaint/phone/${phone}`;
  return getRequest(url);
};

export const trackComplaintByComplaintNo = (
  complaintNo: string | undefined
) => {
  const url = `${UrlConstants.CoreUrl}/public/track/complaint/complaintNo/${complaintNo}`;
  return getRequest(url);
};
