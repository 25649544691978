import { Grid, IconButton } from "@mui/material";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CustomTable from "../../global/CustomTable/CustomTable";
import { useEffect, useMemo, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DownloadIcon from "@mui/icons-material/Download";
import {
  downloadDocumentAPI,
  getAdminDocumentsAPI,
  viewDocumentsAPI,
} from "./AdminDocumentService";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    myDropZone: {
      position: "relative",
      width: "100%",
      height: "10px",
    },
  })
);

export default function AdminDocuments() {
  const classes = useStyles();
  let history = useHistory();
  const isSuperAdmin = localStorage.getItem("role") === "superAdmin";
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [nameOption, setNameOption] = useState([]);

  const columns = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "name",
      header: "Name.",
      size: 150,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.name.split("_").slice("2").join("_").split("_")[0]}
        </>
      ),
      filterVariant: "select",
      filterSelectOptions: nameOption,
    },
    {
      header: "View",
      size: 210,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.name && (
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              handleViewDocument(cell.row.original);
            }}
          >
            <InsertPhotoIcon fontSize="small" />
          </IconButton>
        ),
    },
    {
      header: "Download",
      size: 210,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.name && (
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              handleDownloadDocument(cell.row.original);
            }}
          >
            <DownloadIcon fontSize="small" />
          </IconButton>
        ),
    },
  ];

  const columnsForSuperAdmin = [
    {
      header: "Serial No",
      size: 90,
      Cell: (row: any) => {
        return <div>{row.row.index + 1}</div>;
      },
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 120,
      Cell: (cell: GridRenderCellParams) => (
        <>
          {cell.row.original.name
            .split("_")
            .slice("2")
            .join("_")
            .substr(
              cell.row.original.name
                .split("_")
                .slice("2")
                .join("_")
                .split("_")[0]
            )}
        </>
      ),
    },
    {
      header: "View",
      size: 210,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.name && (
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              handleViewDocument(cell.row.original);
            }}
          >
            <InsertPhotoIcon fontSize="small" />
          </IconButton>
        ),
    },
    {
      header: "Download",
      size: 210,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.name && (
          <IconButton
            size="small"
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              handleDownloadDocument(cell.row.original);
            }}
          >
            <DownloadIcon fontSize="small" />
          </IconButton>
        ),
    },
  ];

  useEffect(() => {
    document.title = "Documents";
    getAdminDocuments();
  }, []);

  const handleViewDocument = (data: any) => {
    window.open(viewDocumentsAPI(data?.name));
  };

  const handleDownloadDocument = async (data: any) => {
    await downloadDocumentAPI(data);
  };

  const getAdminDocuments = async () => {
    setLoader(true);
    const response = await getAdminDocumentsAPI();
    setRows(response);
    const docNames = response.map((r: any) => r.name);
    setNameOption(removeDuplicates(docNames));
    setLoader(false);
  };

  function removeDuplicates(arr: any) {
    return arr?.filter(
      (item: any, index: any) => item && arr?.indexOf(item) === index
    );
  }

  return (
    <Box overflow={"scroll"} style={{ height: "600px" }}>
      <div style={{ maxWidth: "100%", marginTop: 20 }}>
        <Grid item lg={12} sm={12} xs={12}>
          <CustomTable
            data={rows}
            loader={loader}
            columns={isSuperAdmin ? columnsForSuperAdmin : columns}
          />
        </Grid>
        <ToastContainer />
      </div>
    </Box>
  );
}
