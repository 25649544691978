import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { authenticated } from "./LoginService";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import {
  createStyles,
  Dialog,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    Typography: {
      color: "black",
      paddingTop: "0.3rem",
      paddingLeft: "0.5rem",
      textAlign: "left",
    },
    input: {
      minWidth: 290,
      minHeight: 20,
    },
    select: {
      width: 180,
      height: 27,
    },
    mainBox: {
      backgroundColor: "#f2f1ed",
    },
    root: {
      width: 400,
    },
  })
);

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://axisinfoline.com/">
        Axis Info
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    document.title = "Login";
  }, []);

  const handleValidation = (data: any) => {
    if (!data.account) {
      toast.error("Please Select Project!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!data.phone) {
      toast.error("Please Enter Username!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!data.password) {
      toast.error("Please Enter password!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    return true;
  };

  const handleInputChange = (event: any) => {
    localStorage.setItem("account", event?.target?.name);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const phone = data.get("phone") ?? "";
    const employee = {
      phone: phone.toString().toLocaleLowerCase(),
      password: data.get("password"),
      account: localStorage.getItem("account"),
    };
    if (handleValidation(employee)) {
      try {
        const response = await authenticated(employee);
        localStorage.setItem("role", response.role);
        localStorage.setItem("reloadCount", "0");
        localStorage.setItem("userName", response.name);
        localStorage.setItem("phone", response.phone);
        localStorage.setItem("circle", response.circle);
        localStorage.setItem("city", response.city);
        localStorage.setItem("id", response.id);
        localStorage.setItem("token", response.token);
        localStorage.setItem("account", response.account);
        if (response) {
          if (response.role === "Admin" || response.role === "superAdmin") {
            history.push({
              pathname: "/adminDashboard",
            });
          } else if (response.role === "Surveyor") {
            history.push({
              pathname: "/survey",
            });
          } else if (response.role === "aeit") {
            history.push({
              pathname: "/aeitDashboard",
            });
          } else {
            history.push({
              pathname: "/tickets",
            });
          }
        } else {
          toast.error("Incorrect Phone Number or Password!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error: any) {
        toast.error("Incorrect Phone Number or Password!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleSelectDialogue = (account: string) => {
    localStorage.setItem("account", account);
    setOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid style={{ width: 400 }} container component="main">
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            component={Paper}
            elevation={0}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#FFFFFF" }}>
                <LockOutlinedIcon style={{ marginLeft: 2, color: "#e03a3c" }} />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Grid item xs>
                  <Typography className={classes.Typography}>
                    {/* * Project Name */}
                  </Typography>
                  <select
                    disabled={true}
                    required
                    className={classes.select}
                    id="projectName"
                    name="projectName"
                    value={localStorage.getItem("account") ?? ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Project</option>
                    <option value="pvvnl">PVVNL</option>
                    <option value="dvvnl">DVVNL</option>
                    <option value="MVVNL" disabled>
                      MVVNL
                    </option>
                    <option value="PUVVNL" disabled>
                      PUVVNL
                    </option>
                    <option value="KESCO" disabled>
                      KESCO
                    </option>
                    <option value="DMRC" disabled>
                      DMRC
                    </option>
                  </select>
                </Grid>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="Username or Phone Number"
                  name="phone"
                  autoComplete="phone"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{
                    color: "white",
                    backgroundColor: "#f44336",
                    marginTop: 20,
                    marginLeft: 4,
                    marginBottom: 28,
                    minWidth: 120,
                  }}
                >
                  Sign In
                </Button>
                <Copyright sx={{ mt: 4 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <ToastContainer />
      <Dialog
        // onClose={handleClose}
        open={open}
        style={{
          // color: "black",
          paddingTop: "0.3rem",
          paddingLeft: "2rem",
          textAlign: "left",
        }}
      >
        <DialogTitle>{"Please Select Project"}</DialogTitle>
        <Button
          style={{
            color: "white",
            backgroundColor: "#f44336",
            marginBottom: 20,
            marginRight: "7.5rem",
            marginLeft: "7.5rem",
          }}
          // type="submit"
          onClick={() => handleSelectDialogue("pvvnl")}
        >
          PVVNL
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "white",
            backgroundColor: "#900080",
            marginBottom: 20,
            marginRight: "7.5rem",
            marginLeft: "7.5rem",
          }}
          onClick={() => handleSelectDialogue("dvvnl")}
        >
          DVVNL
        </Button>
        <ToastContainer />
      </Dialog>
    </div>
  );
}
