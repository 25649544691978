import { Box, Chip, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import CustomCard from "../../global/CustomCard/CustomCard";
import CustomRangePicker from "../../global/CustomRangePicker/CustomRangePicker";
import SLAVerification from "./SLAVerification";
import {
  getAEITOneCircleQuarterReportCountAPI,
  getAEITQuarterlyReportCountForProductAndIssueCountAPI,
  getAEITQuarterReportCountAPI,
  getAEITQuarterReportCountForProductAndIssueCount,
  getAEITQuarterReportCountForProductAndIssueCountAPI,
  getCirclesAPI,
  getFirstTimeAEITCircleQuarterReportCountAPI,
  getFirstTimeAEITQuarterReportCountForProductAndIssueCountAPI,
  getFirstTimeQuarterlyReportCountForProductAndIssueCountAPI,
  getFirstTimeQuarterReportCountAPI,
  getQuarterlyReportCountForProductAndIssueCountAPI,
  getQuarterlyReportCountForProductAndIssueFirstTimeForAllAPI,
  getQuarterReportCount,
  getQuarterReportCountAPI,
} from "./QuaterlyReportService";

const useStyles = makeStyles((theme) => ({
  TypographyHeading: {
    color: "black",
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(0.7),
    paddingLeft: theme.spacing(2),
    textAlign: "left",
  },
  Typography: {
    color: "black",
    paddingTop: theme.spacing(0.3),
    paddingLeft: theme.spacing(0.5),
    textAlign: "left",
  },
  input: {
    minWidth: 290,
    minHeight: 20,
  },
  select: {
    width: 295,
    height: 27,
  },
  mainBox: {
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    justifyContent: "center",
    width: 360,
    marginLeft: theme.spacing(35),
    alignContent: "left",
  },
}));

export default function QuarterlyReport(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const isAEITFirstCapital = localStorage.getItem("role") === "Aeit";
  const isAEIT = localStorage.getItem("role") === "aeit" || isAEITFirstCapital;
  const userCircle = localStorage.getItem("circle");
  const [quarterlyData, setQuarterlyData] = useState({
    totalTickets: 0,
    totalApprovedTickets: 0,
    totalCompletedTicketsBefore24Hours: 0,
    totalCompletedTicketsAfter24Hours: 0,
    totalTicketsUnderAMC: 0,
    totalUnApprovedTicketZone: 0,
    totalUnApprovedTicketCircle: 0,
    totalUnApprovedTicketDivision: 0,
    totalUnApprovedTicketSubstation: 0,
    totalUnApproved: 0,
  });
  const [forProductAndIssue, setForProductAndIssue] = useState({});
  const [defaultDateTime, setDefaultDateTime] = useState(["", ""]);
  const [circleOptions, setCircleOptions] = useState([]);
  const [selectedCircle, setSelectedCircle] = useState();

  useEffect(() => {
    const getCirclesIfNeeded = () => {
      if (!isAEIT && circleOptions.length === 0) {
        getCircles();
      }
    };

    getCirclesIfNeeded();
    getQuaterlyReportFirstTime();
    getQuarterlyReportCountForProductAndIssueFirstTime();
  }, []);

  const handleSLALocationChange = async (event: any) => {
    const userCircle = event.target.value;

    if (userCircle === "ALL") {
      await getQuaterlyReportFirstTimeForAll();
      await getQuarterlyReportCountForProductAndIssueFirstTimeForAll();
    } else {
      const fromDate = "2023-03-05";
      const toDate = "2023-06-04";
      const response = await getAEITQuarterReportCountAPI(
        defaultDateTime,
        userCircle
      );
      setQuarterlyData(response);

      const response1 = await getAEITQuarterReportCountForProductAndIssueCount(
        defaultDateTime,
        userCircle
      );
      setForProductAndIssue(response1);
      setSelectedCircle(userCircle);
    }
  };

  const getCircles = async () => {
    const circles = await getCirclesAPI();
    setCircleOptions(circles);
  };

  const getQuaterlyReportFirstTimeForAll = async () => {
    if (!isAEIT) {
      const response = await getQuarterReportCount(defaultDateTime);
      setQuarterlyData(response);
    } else {
      const response = await getFirstTimeAEITCircleQuarterReportCountAPI(
        defaultDateTime,
        userCircle
      );
      setQuarterlyData(response);
    }
  };

  const getQuaterlyReportFirstTime = async () => {
    const pvvnl = localStorage.getItem("account") === "pvvnl";
    const dvvnl = localStorage.getItem("account") === "dvvnl";
    if (pvvnl) {
      const fromDate = "2023-03-05";
      const toDate = "2023-06-04";
      if (!isAEIT) {
        const response = await getFirstTimeQuarterReportCountAPI(
          fromDate,
          toDate
        );
        setQuarterlyData(response);
        setDefaultDateTime([fromDate, toDate]);
      } else {
        const response = await getAEITOneCircleQuarterReportCountAPI(
          fromDate,
          toDate,
          userCircle
        );
        setQuarterlyData(response);
        setDefaultDateTime([fromDate, toDate]);
      }
    } else if (dvvnl) {
      const fromDate = "2023-07-01";
      const toDate = "2023-10-30";
      if (!isAEIT) {
        const response = await getFirstTimeQuarterReportCountAPI(
          fromDate,
          toDate
        );
        setQuarterlyData(response);
        setDefaultDateTime([fromDate, toDate]);
      } else {
        const response = await getAEITOneCircleQuarterReportCountAPI(
          fromDate,
          toDate,
          userCircle
        );
        setQuarterlyData(response);
        setDefaultDateTime([fromDate, toDate]);
      }
    }
  };

  const getQuarterlyReportCountForProductAndIssueFirstTimeForAll = async () => {
    if (!isAEIT) {
      const response =
        await getQuarterlyReportCountForProductAndIssueFirstTimeForAllAPI(
          defaultDateTime
        );
      setForProductAndIssue(response);
    } else {
      const response =
        await getAEITQuarterReportCountForProductAndIssueCountAPI(
          defaultDateTime,
          userCircle
        );
      setForProductAndIssue(response);
    }
  };

  const getQuarterlyReportCountForProductAndIssueFirstTime = async () => {
    const pvvnl = localStorage.getItem("account") === "pvvnl";
    const dvvnl = localStorage.getItem("account") === "dvvnl";
    if (pvvnl) {
      const fromDate = "2023-03-05";
      const toDate = "2023-06-04";
      const response =
        await getQuarterlyReportCountForProductAndIssueFirstTimeImpl(
          fromDate,
          toDate,
          isAEIT,
          userCircle
        );
      setForProductAndIssue(response);
      setDefaultDateTime([fromDate, toDate]);
    } else if (dvvnl) {
      const fromDate = "2023-07-01";
      const toDate = "2023-10-30";
      const response =
        await getQuarterlyReportCountForProductAndIssueFirstTimeImpl(
          fromDate,
          toDate,
          isAEIT,
          userCircle
        );
      setForProductAndIssue(response);
      setDefaultDateTime([fromDate, toDate]);
    }
  };

  const getQuarterlyReportCountForProductAndIssueFirstTimeImpl = async (
    fromDate: any,
    toDate: any,
    isAEIT: any,
    userCircle: any
  ) => {
    if (!isAEIT) {
      return await getFirstTimeQuarterlyReportCountForProductAndIssueCountAPI(
        fromDate,
        toDate
      );
    } else {
      return await getFirstTimeAEITQuarterReportCountForProductAndIssueCountAPI(
        fromDate,
        toDate,
        userCircle
      );
    }
  };

  const getQuaterlyReport = async (fromDate: string, toDate: string) => {
    if (!isAEIT) {
      const response = await getQuarterReportCountAPI(fromDate, toDate);
      setQuarterlyData(response);
      setDefaultDateTime([fromDate, toDate]);
    } else {
      const response = await getAEITOneCircleQuarterReportCountAPI(
        fromDate,
        toDate,
        userCircle
      );
      setQuarterlyData(response);
      setDefaultDateTime([fromDate, toDate]);
    }
  };

  const getQuarterlyReportCountForProductAndIssue = async (
    fromDate: string,
    toDate: string
  ) => {
    if (!isAEIT) {
      const response = await getQuarterlyReportCountForProductAndIssueCountAPI(
        fromDate,
        toDate
      );
      setForProductAndIssue(response);
      setDefaultDateTime([fromDate, toDate]);
    } else {
      const response =
        await getAEITQuarterlyReportCountForProductAndIssueCountAPI(
          fromDate,
          toDate,
          userCircle
        );
      setForProductAndIssue(response);
      setDefaultDateTime([fromDate, toDate]);
    }
  };

  const handleQuarterChange = async (event: any) => {
    const pvvnl = localStorage.getItem("account") === "pvvnl";
    const dvvnl = localStorage.getItem("account") === "dvvnl";
    if (event.target.value === "1") {
      if (pvvnl) {
        const fromDate = "2023-03-05";
        const toDate = "2023-06-04";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      } else if (dvvnl) {
        const fromDate = "2023-07-01";
        const toDate = "2023-10-30";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      }
    } else if (event.target.value === "2") {
      if (pvvnl) {
        const fromDate = "2023-06-05";
        const toDate = "2023-09-04";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      } else if (dvvnl) {
        const fromDate = "2023-10-31";
        const toDate = "2024-01-01";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      }
    } else if (event.target.value === "3") {
      if (pvvnl) {
        const fromDate = "2023-09-05";
        const toDate = "2023-12-04";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      } else if (dvvnl) {
        const fromDate = "2024-01-02";
        const toDate = "2024-04-30";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      }
    } else if (event.target.value === "4") {
      if (pvvnl) {
        const fromDate = "2023-12-05";
        const toDate = "2024-03-04";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      } else if (dvvnl) {
        const fromDate = "2024-05-01";
        const toDate = "2024-08-30";
        getQuaterlyReport(fromDate, toDate);
        getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
      }
    } else if (event.target.value === "5") {
      const fromDate = "2024-03-05";
      const toDate = "2024-06-04";
      getQuaterlyReport(fromDate, toDate);
      getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
    } else if (event.target.value === "6") {
      const fromDate = "2024-06-05";
      const toDate = "2024-09-04";
      getQuaterlyReport(fromDate, toDate);
      getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
    } else if (event.target.value === "7") {
      const fromDate = "2024-09-05";
      const toDate = "2024-12-04";
      getQuaterlyReport(fromDate, toDate);
      getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
    } else if (event.target.value === "8") {
      const fromDate = "2024-12-05";
      const toDate = "2025-03-04";
      getQuaterlyReport(fromDate, toDate);
      getQuarterlyReportCountForProductAndIssue(fromDate, toDate);
    } else {
      setQuarterlyData({
        totalTickets: 0,
        totalApprovedTickets: 0,
        totalCompletedTicketsBefore24Hours: 0,
        totalCompletedTicketsAfter24Hours: 0,
        totalTicketsUnderAMC: 0,
        totalUnApprovedTicketZone: 0,
        totalUnApprovedTicketCircle: 0,
        totalUnApprovedTicketDivision: 0,
        totalUnApprovedTicketSubstation: 0,
        totalUnApproved: 0,
      });
      setForProductAndIssue({});
      setDefaultDateTime(["", ""]);
    }
  };

  const QuarterSelectDropdown = () => {
    return (
      <>
        <Grid item>
          <label style={{ marginRight: "1rem" }}>Select Quarter</label>
          <select
            required
            className={classes.select}
            id="machineMake"
            name="machineMake"
            onChange={handleQuarterChange}
          >
            <option value="1">Quarter 1</option>
            <option value="2">Quarter 2</option>
            <option value="3">Quarter 3</option>
            <option value="4">Quarter 4</option>
            <option value="5">Quarter 5</option>
            <option value="6">Quarter 6</option>
            <option value="7">Quarter 7</option>
            <option value="8">Quarter 8</option>
          </select>
        </Grid>
      </>
    );
  };

  const handleDateRangeChange = (date: any) => {
    setDefaultDateTime(date);
    getQuaterlyReport(date[0], date[1]);
    getQuarterlyReportCountForProductAndIssue(date[0], date[1]);
  };

  const complaintDatetimeRangePicker = () => {
    return (
      <CustomRangePicker
        label="Filter "
        fromDate={defaultDateTime[0]}
        toDate={defaultDateTime[1]}
        handleDateRangeChange={handleDateRangeChange}
      />
    );
  };

  const handleRedirection = (name: any) => {
    if (true) {
      if (name === "totalTickets") {
        if (!selectedCircle) {
          history.push("/ticketsList", {
            dateRange: defaultDateTime,
            status: "ALL",
            circle: selectedCircle,
          });
        }
      } else if (name === "totalApprovedTickets") {
        if (!selectedCircle) {
          history.push("/tickets", {
            dateRange: defaultDateTime,
            status: "Approved",
            circle: selectedCircle,
          });
        }
      } else if (name === "totalFreezedTickets") {
        history.push("/tickets", {
          dateRange: defaultDateTime,
          status: "ALL",
          columnFilter: { columnName: "isFreezed", columnValue: "FREEZED" },
          circle: selectedCircle,
        });
      } else if (name === "ticketsClosedUnderSLA") {
        history.push("/tickets", {
          dateRange: defaultDateTime,
          status: "ALL",
          columnFilter: {
            columnName: "ticketsClosedUnderSLA",
            columnValue: "",
          },
          circle: selectedCircle,
        });
      } else if (name === "ticketsClosedBeyondSLA") {
        history.push("/tickets", {
          dateRange: defaultDateTime,
          status: "ALL",
          columnFilter: {
            columnName: "ticketsClosedBeyondSLA",
            columnValue: "",
          },
          circle: selectedCircle,
        });
      } else if (name === "totalUnApprovedTicket") {
        history.push("/tickets", {
          dateRange: defaultDateTime,
          status: "ALL",
          columnFilter: { columnName: "aeitStatus", columnValue: "UnApproved" },
          circle: selectedCircle,
        });
      } else {
      }
    }
  };

  const handleTicketProblemRedirection = async (
    product: any,
    problemType: any,
    status: any
  ) => {
    if (!selectedCircle) {
      history.push("/tickets", {
        dateRange: defaultDateTime,
        status: status,
        columnFilter: {
          columnName: "problemType",
          columnValue: problemType,
          productName: product,
        },
      });
    }
  };

  const isAuthorizedToVerifySLA = () => {
    const role = localStorage.getItem("role") ?? "";
    const phone = localStorage.getItem("phone") ?? "";

    const isAEITFirstCapital = localStorage.getItem("role") === "Aeit";
    const isAEIT =
      localStorage.getItem("role") === "aeit" || isAEITFirstCapital;
    const isSuperAdminOrAdmin =
      ["superadmin", "admin", "superadmindvvnl", "admindvvnl"].includes(
        phone
      ) && ["superAdmin", "Admin"].includes(role);

    return isAEIT || isSuperAdminOrAdmin;
  };

  return (
    <>
      <Box overflow={"scroll"} style={{ height: "600px" }}>
        <Grid container spacing={2}>
          <Grid
            container
            spacing={0}
            style={{
              margin: "1rem",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Grid item style={{ marginTop: "0.1rem", marginRight: "0.8rem" }}>
              {QuarterSelectDropdown()}
            </Grid>
            <Grid item>{complaintDatetimeRangePicker()}</Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <>
              {isAuthorizedToVerifySLA() ? (
                <SLAVerification
                  handleSLALocationChange={handleSLALocationChange}
                  circleOptions={circleOptions}
                  dateRange={defaultDateTime}
                />
              ) : null}
            </>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {
              <CustomCard
                title="Total Tickets"
                cardColor="#fcffde"
                cardHeight="4rem"
                count={quarterlyData?.totalTickets ?? 0}
                onClick={() => handleRedirection("totalTickets")}
              />
            }
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {
              <CustomCard
                title="Total Approved Tickets"
                cardColor="#e8ffde"
                cardHeight="4rem"
                count={quarterlyData?.totalApprovedTickets ?? 0}
                onClick={() => handleRedirection("totalApprovedTickets")}
              />
            }
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {
              <CustomCard
                title="Tickets Closed Under SLA"
                cardColor="#e8ffde"
                cardHeight="4rem"
                count={quarterlyData?.totalCompletedTicketsBefore24Hours ?? 0}
                onClick={() => handleRedirection("ticketsClosedUnderSLA")}
              />
            }
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {
              <CustomCard
                title="Tickets Closed Beyond SLA"
                cardColor="#ffeade"
                cardHeight="4rem"
                count={quarterlyData?.totalCompletedTicketsAfter24Hours ?? 0}
                onClick={() => handleRedirection("ticketsClosedBeyondSLA")}
              />
            }
          </Grid>
          {localStorage.getItem("account") !== "dvvnl" && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {
                <CustomCard
                  title="Total Tickets Under AMC (Freezed Assets)"
                  cardColor="#e8ffde"
                  cardHeight="6rem"
                  count={quarterlyData?.totalTicketsUnderAMC ?? 0}
                  onClick={() => handleRedirection("totalFreezedTickets")}
                />
              }
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {
              <CustomCard
                title="Total UnApproved Tickets"
                cardColor="#ffeade"
                cardHeight="6rem"
                count={quarterlyData?.totalUnApproved ?? 0}
                onClick={() => handleRedirection("totalUnApprovedTicket")}
                subtitle={
                  <>
                    <Chip
                      size="small"
                      label={`Zone: ${quarterlyData?.totalUnApprovedTicketZone}`}
                      style={{
                        color: "white",
                        backgroundColor: "grey",
                        margin: "0.2rem",
                      }}
                    />
                    <Chip
                      size="small"
                      label={`Circle: ${quarterlyData?.totalUnApprovedTicketCircle}`}
                      style={{
                        color: "white",
                        backgroundColor: "grey",
                        margin: "0.2rem",
                      }}
                    />
                    <Chip
                      size="small"
                      label={`Division: ${quarterlyData?.totalUnApprovedTicketDivision}`}
                      style={{
                        color: "white",
                        backgroundColor: "grey",
                        margin: "0.2rem",
                      }}
                    />
                    <Chip
                      size="small"
                      label={`Substation: ${quarterlyData?.totalUnApprovedTicketSubstation}`}
                      style={{
                        color: "white",
                        backgroundColor: "grey",
                        margin: "0.2rem",
                      }}
                    />
                  </>
                }
              />
            }
          </Grid>
          {forProductAndIssue && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h5">{"Problem Types"}</Typography>
              {Object.keys(forProductAndIssue).map((dataMain: any) => {
                return (
                  <Grid>
                    <>
                      <label style={{ marginRight: "0.3rem" }}>
                        {dataMain}
                      </label>
                      {Object(forProductAndIssue)[dataMain][0]?.map(
                        (data: any, index: any) => {
                          return (
                            <Chip
                              size="small"
                              label={`${data.name}: ${data.count}`}
                              onClick={() =>
                                handleTicketProblemRedirection(
                                  dataMain,
                                  data.name,
                                  "ALL"
                                )
                              }
                              style={{
                                color: "white",
                                backgroundColor: "grey",
                                margin: "0.2rem",
                              }}
                            />
                          );
                        }
                      )}
                    </>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
        <ToastContainer />
      </Box>
    </>
  );
}
