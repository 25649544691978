import { Button, Grid } from "@material-ui/core";
import { Box, Container, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getEmailConfig } from "./ConfigService";
import { AppConfig } from "../interfaces";

export default function Config() {
  let history = useHistory();
  const [config, setConfig] = useState<AppConfig>({ emailPerSenderLimit: "" });

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const emailConfig = await getEmailConfig();
    setConfig({
      ...config,
      emailPerSenderLimit: emailConfig.emailPerSenderLimit,
    });
  };

  const handleInputChange = (event: any) => {
    setConfig({ ...config, [event.target.name]: event.target.value });
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Configuration
      </Typography>
      <Box
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email Per Sender Limit"
              name="emailPerSenderLimit"
              variant="outlined"
              type="number"
              inputProps={{
                min: "0",
                step: "1",
              }}
              value={config.emailPerSenderLimit}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => history.goBack()}
              variant="contained"
              color="default"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              //   onClick={handleSubmit}
              disabled={true}
              variant="contained"
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
