import { UrlConstants } from "../../global/UrlConstants";
import {
  deleteRequest,
  exportRequest,
  getRequest,
  patchRequest,
  postRequest,
  uploadRequest,
} from "../../global/utils";

export const uploadSurveyAPI = async (file: any) => {
  const url = `${UrlConstants.baseUrl}/importSurvey`;
  const data = { file: file };
  return uploadRequest(url, data);
};

export const exportSurveyAPI = async (ids: any) => {
  const url = `${UrlConstants.baseUrl}/exportSurveyById`;
  const data = ids;
  return exportRequest(url, data);
};

export const deleteSurveyByIdAPI = (row: any) => {
  const url = `${UrlConstants.baseUrl}/deleteSurvey/${
    row.id
  }/loggedInUserId/${localStorage.getItem("id")}`;
  return deleteRequest(url);
};

export const getSurveyBySingleCityAPI = (city: String) => {
  const url = `${UrlConstants.baseUrl}/getSurveyByCity`;
  const data = { text: city };
  return postRequest(url, data);
};

export const getSurveyByMultipleCityAPI = (user: any) => {
  const url = `${UrlConstants.baseUrl}/getSurveyByCity/${
    JSON.parse(`${user?.data?.city}`)[0]
  }`;
  return getRequest(url);
};

export const getSurveyBycirclesAPI = (circles: any) => {
  const url = `${UrlConstants.baseUrl}/getSurveyByCircle`;
  return postRequest(url, circles);
};

export const employeeById = async () => {
  const url = `${UrlConstants.baseUrl}/employee/${localStorage.getItem("id")}`;
  return await getRequest(url);
};

export const getSurveyCitiesAPI = () => {
  const url = `${UrlConstants.baseUrl}/surveyDistinctZone`;
  return getRequest(url);
};

export const getSurveyByItemNameAPI = (redirectionalState: any) => {
  const url = `${UrlConstants.baseUrl}/getSurveyByItemName/${redirectionalState?.itemName}`;
  return getRequest(url);
};

export const updateAeitStatusApproved = (
  selectedTicket: any,
  loginUserPhone: string | null,
  loginUserName: string | null
) => {
  const url = `${UrlConstants.baseUrl}/updateAeitStatus`;
  const data = {
    ...selectedTicket,
    aeitStatus: "Approved",
    approverPhone: loginUserPhone ?? "",
    approverName: loginUserName ?? "",
  };
  return patchRequest(url, data);
};

export const updateAeitStatusUnApproved = (selectedTicket: any) => {
  const url = `${UrlConstants.baseUrl}/updateAeitStatus`;
  const data = {
    ...selectedTicket,
    aeitStatus: "UnApproved",
    approverPhone: "",
    approverName: "",
  };
  return patchRequest(url, data);
};
