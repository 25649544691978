import React, { useEffect } from "react";
import trackComplaintStyle from "./trackComplaint.styles";
import { useState } from "react";
import { Button, Dialog, DialogTitle, Grid, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { StringConstants } from "../StringConstants";

const TrackComplaint = () => {
  const history = useHistory();
  const classes = trackComplaintStyle;
  const [open, setOpen] = useState(true);
  const [complaint, setcomplaint] = useState({
    searchType: "",
    complaintNo: "",
  });

  const handleOnclick = async (event: any) => {
    history.push(StringConstants.viewComplaint, {
      complaint: complaint,
    });
  };

  const handleSelectDialogue = (selectedSearchType: string) => {
    setcomplaint({ ...complaint, searchType: selectedSearchType });
    setOpen(false);
  };

  return (
    <Grid style={classes.mainGrid}>
      <Grid style={classes.innerGrid}>
        <Paper style={classes.paper}>
          <h1>{StringConstants.trackComplaint}</h1>
          <input
            onChange={(event: any) =>
              setcomplaint({ ...complaint, complaintNo: event?.target.value })
            }
            type={StringConstants.text}
            style={classes.input}
            placeholder={complaint.searchType}
          />
          <Button style={classes.button} onClick={handleOnclick}>
            {StringConstants.track}
          </Button>
        </Paper>
      </Grid>
      <Dialog
        // onClose={handleClose}
        open={open}
        style={{
          // color: "black",
          paddingTop: "0.3rem",
          paddingLeft: "2rem",
          textAlign: "left",
        }}
      >
        <DialogTitle>{"Search With"}</DialogTitle>
        <Button
          style={{
            color: "white",
            backgroundColor: "#f44336",
            marginBottom: 20,
            marginRight: "7.5rem",
            marginLeft: "7.5rem",
          }}
          // type="submit"
          onClick={() => handleSelectDialogue("phoneNo")}
        >
          phone
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "white",
            backgroundColor: "#900080",
            marginBottom: 20,
            marginRight: "7.5rem",
            marginLeft: "7.5rem",
          }}
          onClick={() => handleSelectDialogue("complaintNo")}
        >
          Complaint No
        </Button>
        {/* <ToastContainer /> */}
      </Dialog>
    </Grid>
  );
};

export default TrackComplaint;
