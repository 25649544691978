import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddressComponent from "./AddressComponent";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import { createTicketAPI } from "./AddTicketService";
import { redirectToLoginPage } from "../HelperFunction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    Typography: {
      color: "black",
      paddingTop: "0.3rem",
      paddingLeft: "0.5rem",
      textAlign: "left",
    },
    input: {
      minWidth: 290,
      minHeight: 20,
    },
    select: {
      width: 295,
      height: 27,
    },
    mainBox: {
      backgroundColor: "#f2f1ed",
    },
    root: {
      width: 400,
    },
  })
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function getSteps() {
  return [
    <b style={{ color: "black" }}>Personal Details</b>,
    <b style={{ color: "black" }}>Location Details</b>,
  ];
}

export default function AddTicket(props: any) {
  const classes = useStyles();
  let history = useHistory();
  const [redirectionalState, setRedirectionalState] = useState(
    props.history.location.state
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [project, setProject] = useState();
  const [ticketData, setTicketData] = useState({
    complainantName: "",
    complainantContactNo: "",
    complainantDesignation: "",
    projectName: "",
    product: "",
    machineMake: "",
    problemType: "",
    circle: "",
    division: "",
    substation: "",
    landmark: "",
    pinCode: "",
    uxb1jsi364g4453780: "",
    modelNo: "",
  });
  const [machineMakeOptions, setMachineMakeOptions] = useState<string[]>([]);
  const [modelNoOptions, setModelNoOptions] = useState<string[]>([]);
  const [problemTypeOptions, setProblemTypeOptions] = useState<string[]>([]);
  const [complaintNo, setComplaintNo] = useState("");
  const [modelNoValue, setModelNoValue] = useState("");
  const [modelNoBoxVisibility, setModelNoBoxVisibility] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      if (redirectionalState.commingFrom === "sidebar") {
        history.goBack();
      } else {
        redirectToLoginPage();
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  useEffect(() => {
    document.title = "Create Ticket";
  }, []);

  const handlePersonalDetailsValidation = () => {
    if (!ticketData.complainantName) {
      toast.error("Please Enter your name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.complainantContactNo) {
      toast.error("Please Enter your Phone Number!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.complainantDesignation) {
      toast.error("Please Enter your Complainant Designation!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.product) {
      toast.error("Please Select Project Name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.machineMake) {
      toast.error("Please Select Project Name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.problemType) {
      toast.error("Please Select Project Name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.uxb1jsi364g4453780) {
      toast.error("Please Select Machine serial number!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.projectName) {
      toast.error("Please Select Project Name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    return true;
  };

  const handleLocationValidation = () => {
    if (!ticketData.substation) {
      toast.error("Please Fill End Location Address!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.landmark) {
      toast.error("Please Fill landmark!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.circle) {
      toast.error("Please Fill circle!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.division) {
      toast.error("Please Fill division!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.circle && !ticketData.division) {
      toast.error("Please Select Circle!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!ticketData.circle) {
      toast.error("Please Select Project Name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (activeStep === steps.length - 1) {
      if (handleLocationValidation()) {
        if (modelNoValue) {
          ticketData.modelNo = modelNoValue;
        }
        const response = createTicketAPI(ticketData);
        response
          .then(function (response) {
            setComplaintNo(response);
            toast.success("Successfully Updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTicketData({
              complainantName: "",
              complainantContactNo: "",
              complainantDesignation: "",
              projectName: "",
              product: "",
              machineMake: "",
              problemType: "",
              circle: "",
              division: "",
              substation: "",
              landmark: "",
              pinCode: "",
              uxb1jsi364g4453780: "",
              modelNo: "",
            });
            setOpen(true);
          })
          .catch(function (error) {
            toast.error("Error while Adding Ticket!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      }
    } else {
      if (handlePersonalDetailsValidation()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleMachineMakeChange = (event: any) => {
    setTicketData({
      ...ticketData,
      [event.target.name]: event.target.value.toUpperCase(),
    });
    if (event.target.value === "DELL") {
      setModelNoValue("7100 MT");
      setModelNoOptions([
        "7100 MT",
        "INSPIRON 3472",
        "INSPIRON 3880",
        "OPTIPLEX 660S",
        "OPTIPLEX 7010",
        "OPTIPLEX 3010",
        "OPTIPLEX 3020",
        "OPTIPLEX 780",
        "OPTIPLEX 790",
        "OPTIPLEX 9020",
        "OPTIPLEX 990",
        "VOSTRO 3268",
        "VOSTRO 3010",
        "VOSTRO 3020",
        "VOSTRO 3040",
        "VOSTRO 3050",
        "VOSTRO 3268",
        "VOSTRO 3470",
      ]);
    } else if (event.target.value === "LENOVO") {
      setModelNoValue("N700");
      setModelNoOptions([
        "N700",
        "M710T",
        "M7705",
        "THINK CENTER M77",
        "V320 M10NS",
        "V503S M10TY",
      ]);
    } else if (event.target.value === "HP") {
      setModelNoValue("2480 MT");
      setModelNoOptions([
        "HP 8000 ELITE",
        "2480 MT",
        "260-A061IL",
        "270-P027IL",
        "280 G2 MT",
        "290 A000IL",
        "3090 MT",
        "3330 MT",
        "400 G2 MT",
        "406 G1",
        "6200 MT",
        "6300 MT",
        "7100 MT",
        "7380 MT",
        "8100 MT",
        "8300 MT",
        "ELITE 7100 MT",
        "ELITE 800G1",
        "ELITE 8300 MT",
        "290",
        "M126N",
        "P2-1331IL DESKTOP PC",
        "PRESARIO CQ3258IL",
        "PRO 3090 MT",
        "PRO 3300 MT",
        "PRO ONE 400 GS",
        "SLIMLINE 720",
        "SR NA",
      ]);
    } else if (event.target.value === "ACER") {
      setModelNoValue("ASPV8243");
      setModelNoOptions([
        "ASPV8243",
        "VERITON M200 H110",
        "VERITON ES2740G",
        "VERITON M200 H110",
        "VERITON S2670G"
      ]);
    } else if (event.target.value === "ASSEMBLED") {
      setModelNoValue("ASSEMBLED PC");
      setModelNoOptions(["ASSEMBLED PC", "VERITON M200 H110", "SR NA"]);
    } else if (event.target.value === "HCL") {
      setModelNoValue("BUSYBEE");
      setModelNoOptions(["BUSYBEE", "INFINITY PRO BL1110", "SR NA"]);
    } else {
      setModelNoValue("");
      setModelNoOptions([]);
    }
  };

  const handleModelNoChange = (event: any) => {
    // setModelNoValue(`${ticketData.machineMake}-${event.target.value.toUpperCase()}`)
    setModelNoValue(`${event.target.value.toUpperCase()}`);
  };

  const handleInputChange = (event: any) => {
    setTicketData({
      ...ticketData,
      [event.target.name]: event.target.value.toUpperCase(),
    });
    if (event.target.name === "projectName") {
      localStorage.setItem(
        "accountWhileCreatingTicket",
        event.target.value.toLowerCase()
      );
      setProject(event.target.value.toLowerCase());
    }
    if (event.target.value === "DESKTOP") {
      setTicketData({
        ...ticketData,
        product: "DESKTOP",
        machineMake: "DELL",
        problemType: "SYSTEM NOT WORKING",
        modelNo: "7100 MT",
      });
      setMachineMakeOptions([
        "DELL",
        "HP",
        "ACER",
        "LENOVO",
        "ASSEMBLED",
        "HCL",
      ]);
      setModelNoOptions([
        "7100 MT",
        "INSPIRON 3472",
        "INSPIRON 3880",
        "OPTIPLEX 660S",
        "OPTIPLEX 7010",
        "OPTIPLEX 3010",
        "OPTIPLEX 3020",
        "OPTIPLEX 780",
        "OPTIPLEX 790",
        "OPTIPLEX 9020",
        "OPTIPLEX 990",
        "VOSTRO 3268",
        "VOSTRO 3010",
        "VOSTRO 3020",
        "VOSTRO 3040",
        "VOSTRO 3050",
        "VOSTRO 3268",
        "VOSTRO 3470",
      ]);
      setProblemTypeOptions([
        "SYSTEM NOT WORKING",
        "DISPLAY ISSUE",
        "RESTARTING ISSUE",
        "POWER ON ISSUE",
        "DATE TIME ISSUE",
        "SYSTEM HANGING ISSUE",
        "HARD DISK NOT WORKING",
        "KEYBOARD NOT WORKING",
        "MOUSE NOT WORKING",
        "TFT NOT WORKING",
        "LAN PORT NOT WORKING",
      ]);
      setModelNoBoxVisibility(true);
    } else if (event.target.value === "UPS OFFLINE") {
      setTicketData({
        ...ticketData,
        product: "UPS OFFLINE",
        machineMake: "EMERSON 600/800 VA UPS",
        problemType: "POWER ON ISSUE",
      });
      setMachineMakeOptions([
        "EMERSON 600/800 VA UPS",
        "MICROTEK 600/800 VA UPS",
        "LUMINUS 600/800 VA UPS",
        "INTEX 600/800 VA UPS",
        "UNILINE 1KVA"
      ]);
      setProblemTypeOptions(["POWER ON ISSUE", "BACKUP ISSUE"]);
      setModelNoBoxVisibility(false);
    } else if (event.target.value === "UPS ONLINE") {
      setTicketData({
        ...ticketData,
        product: "UPS ONLINE",
        machineMake: "EMERSON 3KVA UPS",
        problemType: "POWER ON ISSUE",
      });
      setMachineMakeOptions(["EMERSON 3KVA UPS", "EMERSON 20KVA UPS"]);
      setProblemTypeOptions([
        "POWER ON ISSUE",
        "CHARGING ISSUE",
        "BACKUP ISSUE",
        "ERROR-01",
        "ERROR-27",
        "UPS NOT WORKING",
      ]);
      setModelNoBoxVisibility(false);
    } else if (event.target.value === "PRINTER") {
      setTicketData({
        ...ticketData,
        product: "PRINTER",
        machineMake: "HP",
        problemType: "POWER ON ISSUE",
      });
      setMachineMakeOptions(["HP", "PANTUM"]);
      setProblemTypeOptions([
        "LOGIC CARD FAULTY",
        "POWER SUPPLY",
        "PRINTER FAULTY",
        "NO POWER",
      ]);
      setModelNoBoxVisibility(false);
    } else if (event.target.value === "HEADSET") {
      setTicketData({
        ...ticketData,
        product: "HEADSET",
        machineMake: "PLANTRONICS",
        problemType: "NOT WORKING",
        modelNo: "HW121N",
      });
      setMachineMakeOptions(["PLANTRONICS"]);
      setProblemTypeOptions(["NOT WORKING"]);
      setModelNoOptions(["HW121N"]);
      setModelNoBoxVisibility(true);
    } else if (event.target.value === "Please select") {
      setTicketData({
        ...ticketData,
        product: "",
        machineMake: "",
        problemType: "",
      });
      setMachineMakeOptions([]);
      setProblemTypeOptions([]);
      setModelNoBoxVisibility(false);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography className={classes.Typography}>*Name</Typography>
            <Grid item xs>
              <Box>
                <input
                  style={{ textTransform: "uppercase" }}
                  required
                  maxLength={36}
                  className={classes.input}
                  autoComplete="new-password"
                  name="complainantName"
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid className={classes.input} item xs>
              <Typography className={classes.Typography}>*Phone</Typography>
              <Box>
                <input
                  required
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={13}
                  className={classes.input}
                  autoComplete="new-password"
                  name="complainantContactNo"
                  type="tel"
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid className={classes.input} item xs>
              <Typography className={classes.Typography}>
                *Designation
              </Typography>
              <Box>
                <input
                  required
                  style={{ textTransform: "uppercase" }}
                  maxLength={36}
                  className={classes.input}
                  autoComplete="new-password"
                  name="complainantDesignation"
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Typography className={classes.Typography}>
                * Project Name
              </Typography>
              <select
                required
                className={classes.select}
                id="projectName"
                name="projectName"
                value={ticketData.projectName}
                onChange={handleInputChange}
              >
                <option value="">Please Select</option>
                <option value="PVVNL">PVVNL</option>
                <option value="DVVNL" disabled>DVVNL</option>
                <option value="MVVNL" disabled>
                  MVVNL
                </option>
                <option value="PUVVNL" disabled>
                  PUVVNL
                </option>
                <option value="KESCO" disabled>
                  KESCO
                </option>
                <option value="DMRC" disabled>
                  DMRC
                </option>
              </select>
            </Grid>
            <Grid item xs>
              <Typography className={classes.Typography}>
                Product & Services
              </Typography>
              <select
                required
                className={classes.select}
                id="product"
                name="Product & Services"
                value={ticketData.product}
                onChange={handleInputChange}
              >
                <option value="">Please Select</option>
                <option value="DESKTOP">DESKTOP</option>
                <option value="UPS OFFLINE">UPS OFFLINE</option>
                {/* <option value="UPS ONLINE">UPS ONLINE</option> */}
                {project === "dvvnl" && (
                  <>
                    <option value="PRINTER">PRINTER</option>
                    <option value="HEADSET">HEADSET</option>
                  </>
                )}
              </select>
            </Grid>
            <Grid item xs>
              <Typography className={classes.Typography}>
                Machine Make
              </Typography>
              <select
                required
                className={classes.select}
                id="machineMake"
                name="machineMake"
                value={ticketData.machineMake}
                onChange={handleMachineMakeChange}
              >
                {machineMakeOptions.map((x, y) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </Grid>
            {modelNoBoxVisibility && (
              <Grid className={classes.input} item xs>
                <Typography className={classes.Typography}>
                  Model No.
                </Typography>
                <Box>
                  <select
                    // required
                    className={classes.select}
                    // name="modelNo"
                    // value={ticketData.modelNo}
                    onChange={handleModelNoChange}
                  >
                    {modelNoOptions.map((x, y) => (
                      <option key={y} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </Box>
              </Grid>
            )}
            <Grid item xs>
              <Typography className={classes.Typography}>
                Problem Type
              </Typography>
              <select
                className={classes.select}
                id="problemType"
                name="problemType"
                value={ticketData.problemType}
                onChange={handleInputChange}
              >
                {problemTypeOptions.map((x, y) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid className={classes.input} item xs>
              <Typography className={classes.Typography}>
                *Machine Serial No.
              </Typography>
              <Box>
                <input
                  required
                  style={{ textTransform: "uppercase" }}
                  maxLength={36}
                  className={classes.input}
                  autoComplete="new-password"
                  name="uxb1jsi364g4453780"
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
          </>
        );
      case 1:
        return (
          <AddressComponent
            ticketData={ticketData}
            setTicketData={setTicketData}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0.8rem",
      }}
    >
      <Box
        aria-disabled
        component="form"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
      >
        <Paper className={classes.root} elevation={6}>
          <Box style={{ position: "absolute", paddingLeft: "0.2rem" }}>
            <IconButton onClick={() => history.goBack()}>
              <KeyboardBackspaceIcon style={{ fill: "#000000" }} />
            </IconButton>
          </Box>
          <Typography
            variant="h4"
            sx={{ paddingTop: 6, paddingBottom: 3, fontFamily: "sans-serif" }}
          >
            Create Ticket
          </Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        style={{
                          marginTop: 20,
                          marginBottom: 28,
                          minWidth: 110,
                        }}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#f44336",
                          marginTop: 20,
                          marginBottom: 28,
                          minWidth: 110,
                        }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Ticket Created
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Your complaint number is: <strong>{complaintNo}</strong>
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer />
    </div>
  );
}
