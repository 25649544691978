import { Box, Card, Typography } from "@mui/material";
import { JsxElement } from "typescript";

interface CustomProps {
  cardColor?: string;
  cardWidth?: string;
  cardHeight?: string;
  color?: string;
  icon?: any;
  title?: string;
  titleComponent?: any;
  count?: any;
  onClick?: Function;
  subtitle?: any;
  hardwareCount?: any;
  padding?: any;
}

export default function CustomCard(props: CustomProps) {
  return (
    <>
      <Card
        onClick={() => props.onClick && props.onClick()}
        sx={{ boxShadow: 15 }}
        style={{
          // paddingTop: "2rem",
          width: props.cardWidth ? props.cardWidth : "auto",
          height: props.cardHeight ? props.cardHeight : "auto",
          backgroundColor: props.cardColor ? props.cardColor : "#FFFFFF",
          color: props?.color ? props?.color : "#141414",
          flexDirection: "column",
          padding: props.padding ?? "1rem",
          //   ...centerItemFlex,
          cursor: "pointer",
        }}
      >
        {props?.title && (<Typography variant="h5">{props.title} {" "} {props?.count}</Typography>)}
        {props?.titleComponent}
        {props?.subtitle}
        {props?.hardwareCount}
      </Card>
    </>
  );
}
