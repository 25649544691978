import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import { getEmployeeByIdAPI } from "./LayoutService";
import { StringConstants } from "../../global/StringConstants";
import DvvnlRoute from "./DvvnlRoute";
import PvvnlRoute from "./PvvnlRoute";

export default function Layout() {
  const Pages = () => {
    if (!StringConstants.account) {
      window.location.reload();
    } else {
      switch (StringConstants.account) {
        case StringConstants.pvvnl:
          return PvvnlRoute();
        case StringConstants.dvvnl:
          return DvvnlRoute();
        default:
        // TODO: Implement default case.
      }
    }
  };

  return <>{Pages()}</>;
}
