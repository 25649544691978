import { UrlConstants } from "../UrlConstants";
import { getRequest } from "../utils";

export const getAllDivisionByCircleAPI = (event: any) => {
  const url = `${
    UrlConstants.CoreUrl
  }/public/location/divisions/${event.target.value.toUpperCase()}/${localStorage.getItem(
    "accountWhileCreatingTicket"
  )}`;
  return getRequest(url);
};

export const getAllCirclesAPI = () => {
  const url = `${
    UrlConstants.CoreUrl
  }/public/location/circles/${localStorage.getItem(
    "accountWhileCreatingTicket"
  )}`;
  return getRequest(url);
};
