import { UrlConstants } from "../../global/UrlConstants";
import { getRequest, postRequest } from "../../global/utils";

export const getAEITQuarterReportCountAPI = (
  defaultDateTime: string[],
  userCircle: any
) => {
  const url = `${UrlConstants.baseUrl}/getAEITQuarterReport/count/${
    defaultDateTime[0]
  }/${defaultDateTime[1]}/loggedInUserId/${localStorage.getItem("id")}`;
  const data = { text: JSON.stringify([userCircle, ""]) };
  return postRequest(url, data);
};

export const getAEITOneCircleQuarterReportCountAPI = (
  fromDate: string,
  toDate: string,
  userCircle: string | null
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getAEITQuarterReport/count/${fromDate}/${toDate}/loggedInUserId/${localStorage.getItem(
    "id"
  )}`;
  const data = { text: userCircle };
  return postRequest(url, data);
};

export const getAEITQuarterReportCountForProductAndIssueCount = (
  defaultDateTime: string[],
  userCircle: any
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getAEITQuarterReportCountForProductAndIssue/count/${defaultDateTime[1]}/${
    defaultDateTime[1]
  }/loggedInUserId/${localStorage.getItem("id")}`;
  const data = { text: JSON.stringify([userCircle, ""]) };
  return postRequest(url, data);
};

export const getCirclesAPI = () => {
  const url = `${UrlConstants.baseUrl}/getAllCircles`;
  return getRequest(url);
};

export const getQuarterReportCount = (defaultDateTime: string[]) => {
  const url = `${UrlConstants.baseUrl}/getQuarterReport/count/${
    defaultDateTime[0]
  }/${defaultDateTime[1]}/loggedInUserId/${localStorage.getItem("id")}`;
  return getRequest(url);
};

export const getAEITQuarterReportCount = (
  defaultDateTime: string[],
  userCircle: string | null
) => {
  const url = `${UrlConstants.baseUrl}/getAEITQuarterReport/count/${
    defaultDateTime[0]
  }/${defaultDateTime[1]}/loggedInUserId/${localStorage.getItem("id")}`;
  const data = { text: userCircle };
  return postRequest(url, data);
};

export const getFirstTimeAEITCircleQuarterReportCountAPI = (
  defaultDateTime: string[],
  userCircle: string | null
) => {
  const url = `${UrlConstants.baseUrl}/getAEITQuarterReport/count/${
    defaultDateTime[0]
  }/${defaultDateTime[1]}/loggedInUserId/${localStorage.getItem("id")}`;
  const data = { text: userCircle };
  return postRequest(url, data);
};

export const getFirstTimeQuarterReportCountAPI = (
  fromDate: string,
  toDate: string
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getQuarterReport/count/${fromDate}/${toDate}/loggedInUserId/${localStorage.getItem(
    "id"
  )}`;
  return getRequest(url);
};

export const getFirstTimeQuarterlyReportCountForProductAndIssueCountAPI =
  async (fromDate: string, toDate: string) => {
    const url = `${
      UrlConstants.baseUrl
    }/getQuarterlyReportCountForProductAndIssue/count/${fromDate}/${toDate}/loggedInUserId/${localStorage.getItem(
      "id"
    )}`;
    return getRequest(url);
  };

export const getFirstTimeAEITQuarterReportCountForProductAndIssueCountAPI =
  async (fromDate: any, toDate: any, userCircle: any) => {
    const url = `${
      UrlConstants.baseUrl
    }/getAEITQuarterReportCountForProductAndIssue/count/${fromDate}/${toDate}/loggedInUserId/${localStorage.getItem(
      "id"
    )}`;
    const data = { text: userCircle };

    return postRequest(url, data);
  };

export const getAEITQuarterReportCountForProductAndIssueCountAPI = (
  defaultDateTime: string[],
  userCircle: string | null
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getAEITQuarterReportCountForProductAndIssue/count/${defaultDateTime[0]}/${
    defaultDateTime[1]
  }/loggedInUserId/${localStorage.getItem("id")}`;
  const data = { text: userCircle };
  return postRequest(url, data);
};

export const getQuarterlyReportCountForProductAndIssueFirstTimeForAllAPI = (
  defaultDateTime: string[]
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getQuarterlyReportCountForProductAndIssue/count/${defaultDateTime[0]}/${
    defaultDateTime[1]
  }/loggedInUserId/${localStorage.getItem("id")}`;
  return getRequest(url);
};

export const getAEITQuarterlyReportCountForProductAndIssueCountAPI = (
  fromDate: string,
  toDate: string,
  userCircle: string | null
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getAEITQuarterReportCountForProductAndIssue/count/${fromDate}/${toDate}/loggedInUserId/${localStorage.getItem(
    "id"
  )}`;
  const data = { text: userCircle };
  return postRequest(url, data);
};

export const getQuarterlyReportCountForProductAndIssueCountAPI = (
  fromDate: string,
  toDate: string
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getQuarterlyReportCountForProductAndIssue/count/${fromDate}/${toDate}/loggedInUserId/${localStorage.getItem(
    "id"
  )}`;
  return getRequest(url);
};

export const getQuarterReportCountAPI = (fromDate: string, toDate: string) => {
  const url = `${
    UrlConstants.baseUrl
  }/getQuarterReport/count/${fromDate}/${toDate}/loggedInUserId/${localStorage.getItem(
    "id"
  )}`;
  return getRequest(url);
};

export const approveTicketByAdminInBulkAPI = (
  props: any,
  ticketData: {
    approvedBy: string;
    approvedByPhone: string;
    approvedOn: string;
    remarks: string;
    otherStringData: string;
  }
) => {
  const url = `${UrlConstants.baseUrl}/approveTicketByAdminInBulk/${
    props?.dateRange[0]
  }/${props?.dateRange[1]}/loggedInUserId/${localStorage.getItem("id")}`;
  const data = ticketData;
  return postRequest(url, data);
};

export const approveTicketByAeitInBulkAPI = (
  props: any,
  ticketData: {
    approvedBy: string;
    approvedByPhone: string;
    approvedOn: string;
    remarks: string;
    otherStringData: string;
  }
) => {
  const url = `${UrlConstants.baseUrl}/approveTicketByAeitInBulk/${
    props?.dateRange[0]
  }/${props?.dateRange[1]}/loggedInUserId/${localStorage.getItem("id")}`;
  const data = ticketData;
  return postRequest(url, data);
};
