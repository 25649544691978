import React from "react";
import Table from "../../global/Table/Table";
import {
  approveTicketByAeitAPI,
  getAdminTicketByStatusAndDateRange,
  getAeitFilteredTicket,
  getAeitTicket,
} from "./TicketService";
import { useEffect, useState } from "react";
import moment from "moment";
import { Button, Grid } from "@material-ui/core";
import CustomRangePicker from "../../global/CustomRangePicker/CustomRangePicker";
import { ToastContainer, toast } from "react-toastify";

export default function TicketsList(props: any) {
  const [data, setData] = useState([]);
  const isAdmin = localStorage.getItem("role") === "Admin";
  const isSuperAdmin = localStorage.getItem("role") === "superAdmin";
  const isAdminSuperAdmin = isAdmin || isSuperAdmin ? true : false;
  const isAEITFirstCapital = localStorage.getItem("role") === "Aeit";
  const isAEIT = (localStorage.getItem("role") === "aeit") || isAEITFirstCapital;
  const [selectedFilters, setSelectedFilters] = useState<String[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<String[]>([]);
  const [redirectionalState, setRedirectionalState] = useState(
    props.history.location.state
  );
  const [defaultDateTime, setDefaultDateTime] = useState(
    redirectionalState
      ? redirectionalState.dateRange
      : ["2023-03-05", moment().format("YYYY-MM-DD")]
  );
  const [tabValue, setTabValue] = useState(
    props.history.location.state?.tabValue ?? redirectionalState
      ? redirectionalState.status
      : "OPEN"
  );

  useEffect(() => {
    document.title = "Tickets";
    getTickets(defaultDateTime[0], defaultDateTime[1]);
  }, [tabValue]);

  const getTickets = async (fromDate: String, toDate: String) => {
    let response;
    if (isAdminSuperAdmin) {
      response = await getAdminTicketByStatusAndDateRange(
        "ALL",
        fromDate,
        toDate,
        "ALL"
      );
    } else if (isAEIT) {
      response = await getAeitTicket(fromDate, toDate, "ALL");
    }
    setData(response);
  };

  const handleFilterChange = async (filter: any) => {
    const data = [...selectedFilters, filter];
    setSelectedFilters(() => data);
    const response = await getAeitFilteredTicket(
      defaultDateTime[0],
      defaultDateTime[1],
      "ALL",
      data
    );
    setData(response);
  };

  const handleApproveByAeit = () => {
    //code
    const response = approveTicketByAeitAPI(selectedRowIds);
    response
      .then(function (response: any) {
        toast.success("Ticket Approved!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      })
      .catch(function (error: any) {
        toast.error("Error while updating!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const complaintDatetimeRangePicker = () => {
    return (
      // (isAdminSuperAdmin || isAEIT) && (
      <CustomRangePicker
        label="Filter "
        fromDate={defaultDateTime[0]}
        toDate={defaultDateTime[1]}
        disabled
      // handleDateRangeChange={handleDateRangeChange}
      />
    );
  };

  const tableComponent = () => {
    return (
      <>
        <Grid container justifyContent="flex-start">
          <Grid item>{complaintDatetimeRangePicker()}</Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              style={{
                color: "white",
                backgroundColor: "GREEN",
                margin: 5,
                marginBottom: 12,
              }}
              variant="contained"
              color="primary"
              type="submit"
              // className={classes.button}
              onClick={() => handleApproveByAeit()}
            >
              Approve
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                color: "white",
                backgroundColor: "Grey",
                margin: 5,
                marginBottom: 12,
              }}
              variant="contained"
              color="primary"
              type="submit"
              // className={classes.button}
              onClick={() => window.location.reload()}
            >
              Reset Filters
            </Button>
          </Grid>
        </Grid>
        <Grid></Grid>
        <Table
          data={data}
          selectedFilters={selectedFilters}
          handleFilterChange={handleFilterChange}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
        />
        <ToastContainer />
      </>
    );
  };

  return <div>{tableComponent()}</div>;
}
