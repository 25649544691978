import { UrlConstants } from "../../global/UrlConstants";
import {
  deleteRequest,
  exportRequest,
  getRequest,
  patchRequest,
  postRequest,
  uploadRequest,
} from "../../global/utils";

export const getAdminTicketByStatusAndDateRange = async (
  status: any,
  fromDate: String,
  toDate: String,
  circle: String
) => {
  const url = `${
    UrlConstants.baseUrl
  }/getTickets/admin/${status}/${fromDate}/${toDate}/${circle ?? "ALL"}`;
  return getRequest(url);
};

export const ticketsClosedUnderSLA = async (
  status: any,
  fromDate: String,
  toDate: String
) => {
  const url = `${UrlConstants.baseUrl}/ticketsClosedUnderSLA/admin/${status}/${fromDate}/${toDate}`;
  return getRequest(url);
};

export const ticketsClosedBeyondSLA = async (
  status: any,
  fromDate: String,
  toDate: String
) => {
  const url = `${UrlConstants.baseUrl}/ticketsClosedBeyondSLA/admin/${status}/${fromDate}/${toDate}`;
  return getRequest(url);
};

export const getAEITTicketByCircleStatusAndDateRange = async (
  status: any,
  circle: any,
  fromDate: String,
  toDate: String
) => {
  const url = `${UrlConstants.baseUrl}/getTickets/aeit/${status}/${fromDate}/${toDate}`;
  const data = { text: circle };
  return postRequest(url, data);
};

export const getEngTicketByStatusAndDateRange = async (
  phone: any,
  status: any,
  fromDate: String,
  toDate: String
) => {
  const url = `${UrlConstants.baseUrl}/getTickets/${phone}/${status}/${fromDate}/${toDate}`;
  return getRequest(url);
};

export const reAssignTicketByAdminAPI = (ticketData: never[]) => {
  const url = `${
    UrlConstants.baseUrl
  }/admin/reAssign/loggedInUserId/${localStorage.getItem("id")}`;
  const data = ticketData;
  return patchRequest(url, data);
};

export const addAeitRemarks = (ticketData: {
  id: string;
  aeitRemarks: string;
}) => {
  const url = `${
    UrlConstants.baseUrl
  }/admin/aeitRemarks/loggedInUserId/${localStorage.getItem("id")}`;
  const data = ticketData;
  return patchRequest(url, data);
};

export const viewDocumentsAPI = (fileName: string) => {
  const url = `${UrlConstants.baseUrl}/document/view/${fileName}`;
  return url;
};

export const importTicketsAPI = async (file: any) => {
  const url = `${UrlConstants.baseUrl}/importTickets`;
  const data = { file: file };
  return uploadRequest(url, data);
};

export const exportTicketsAPI = async (ids: any) => {
  const url = `${UrlConstants.baseUrl}/exportTicketByComplaintNo`;
  const data = ids;
  return exportRequest(url, data);
};

export const adminUpdateTicket = (
  selectedTicket: any,
  loginUserPhone: string | null,
  loginUserName: string | null
) => {
  const url = `${
    UrlConstants.baseUrl
  }/admin/updateTicket/loggedInUserId/${localStorage.getItem("id")}`;
  const data = {
    ...selectedTicket,
    aeitStatus: "Approved",
    approverPhone: loginUserPhone ?? "",
    approverName: loginUserName ?? "",
  };
  return patchRequest(url, data);
};

export const adminUnapproveTicket = (selectedTicket: any) => {
  const url = `${UrlConstants.baseUrl}/admin/unApproveTicket`;
  const data = {
    ...selectedTicket,
    aeitStatus: "UnApproved",
    approverPhone: "",
    approverName: "",
  };
  return patchRequest(url, data);
};

export const deleteTicketByComplaintNumber = (complaintNumber: string) => {
  const url = `${
    UrlConstants.baseUrl
  }/deleteTicket/${complaintNumber}/loggedInUserId/${localStorage.getItem(
    "id"
  )}`;
  return deleteRequest(url);
};

export const approveTicketByAeitAPI = (ticketData: any[]) => {
  const url = `${
    UrlConstants.baseUrl
  }/approveByAeit/loggedInUserId/${localStorage.getItem("id")}`;
  const data = ticketData;
  return postRequest(url, data);
};
