import axios from "axios";
import { getRequest, patchRequest, postRequest } from "../../../global/utils";
import { UrlConstants } from "../../../global/UrlConstants";

export const updateAeitAPI = (employee: any) => {
  const url = `${
    UrlConstants.baseUrl
  }/updateEmployee/loggedInUserId/${localStorage.getItem("id")}`;
  const data = employee;
  return patchRequest(url, data);
};

export const addAeitAPI = (employee: any) => {
  const url = `${
    UrlConstants.baseUrl
  }/addEmployee/loggedInUserId/${localStorage.getItem("id")}`;
  const data = employee;
  return postRequest(url, data);
};

export const getAllCitiFromSurveyAPI = () => {
  const url = `${UrlConstants.baseUrl}/getAllCitiFromFreezeItem`;
  return getRequest(url);
};

export const getCirclesFromLocationAPI = () => {
  const url = `${UrlConstants.baseUrl}/location/circles`;
  return getRequest(url);
};

export const getCirclesAPI = () => {
  const url = `${UrlConstants.baseUrl}/getAllCirclesFromTickets`;
  return getRequest(url);
};
