import * as React from "react";
import { useState, useEffect, useLayoutEffect } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import moment from "moment";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <DoneOutlineIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function ViewUserComaplaintStepper(props: any) {
  const { complaintData } = props;
  const [activeStep, setActiveStep] = useState<number>();
  const [steps, setSteps] = useState([
    "Created",
    "Engineer Assigned",
    "Attempt",
    "Closed",
  ]);

  useEffect(() => {
    calculateStep();
  }, []);

  const calculateStep = () => {
    if (complaintData?.complainantName) {
      steps[0] = `[ ${complaintData.complaintNo} ] Created by ${
        complaintData.complainantName
      } on ${convertDateFormat(complaintData.complaintDatetime)}`;
      setActiveStep(0);
    }
    if (complaintData?.engineerAssigned) {
      steps[1] = `Engineer ${complaintData.engineerAssigned} [ ${complaintData.engineerContactNo} ] Assigned`;
      setActiveStep(1);
    }
    if (complaintData?.complaintAttemptsFirstDateAndTime) {
      steps[2] = `Attempt 1 ( ${convertDateFormat(
        complaintData.complaintAttemptsFirstDateAndTime
      )} )`;
      setActiveStep(2);
    }
    if (complaintData?.complaintAttemptsSecondDateAndTime) {
      steps[2] = `Attempt 1 ( ${convertDateFormat(
        complaintData.complaintAttemptsFirstDateAndTime
      )} ), Attempt 2 ( ${convertDateFormat(
        complaintData.complaintAttemptsSecondDateAndTime
      )} )`;
      setActiveStep(2);
    }
    if (complaintData?.complaintAttemptsThirdDateAndTime) {
      steps[2] = `Attempt 1 ( ${convertDateFormat(
        complaintData.complaintAttemptsFirstDateAndTime
      )} ), Attempt 2 ( ${convertDateFormat(
        complaintData.complaintAttemptsSecondDateAndTime
      )} ), Attempt 3 ( ${convertDateFormat(
        complaintData.complaintAttemptsThirdDateAndTime
      )} )`;
      setActiveStep(2);
    }
    if (complaintData?.complaintCompletionDatetime) {
      steps[3] = `Closed on ${complaintData.complaintCompletionDatetime}`;
      setActiveStep(3);
    }
  };

  const convertDateFormat = (dateTime: any) => {
    if (dateTime) {
      return moment(dateTime).format("hh:mm - DD/MM/YYYY");
    } else {
      return `NA`;
    }
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label: string) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
