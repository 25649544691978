import { Box, Grid, styled } from "@material-ui/core";
import React, { useState } from "react";

const data1 = [
  {
    id: "62ad592243f893e6847416272534ca2fPVV-8344552023-09-16T12:03",
    serialNo: 41832,
    complaintNo: "PVV-834455",
    complaintDatetime: "2023-09-16T12:03:00",
    zone: "MEERUT",
    circle: "EDC 2 MEERUT",
    division: "EDD 2 MEERUT",
    complainantName: "DEEPANKER",
    complainantDesignation: "E.A.",
    complainantContactNo: "8439634464",
    defectiveItemName: null,
    uxb1jsi364g4453780: "BQS48BS",
    engineerAssigned: "DEVVRAT",
    engineerAssignedDateTime: "2023-09-16T12:11:00",
    engineerContactNo: "9205894601",
    complaintAttemptsFirstDateAndTime: null,
    complaintAttemptsSecondDateAndTime: null,
    complaintAttemptsThirdDateAndTime: null,
    locationCode: null,
    complaintCompletionDatetime: null,
    status: "OPEN",
    actionTakenAndSpareUsed: null,
    oldSerialNoMbHddTft: null,
    newSerialNoMbHddTft: null,
    remarks: null,
    projectName: "pvvnl",
    product: "DESKTOP",
    machineMake: "DELL",
    problemType: "SYSTEM NOT WORKING",
    substation: "B.C. EDD 2 VICTORIA PARK MEERUT",
    landmark: "VICTORIA PARK ",
    pinCode: "",
    docPath: null,
    aeitStatus: "UnApproved",
    approverName: null,
    approverPhone: "BLANK",
    complaintAttendHours: null,
    complaintCompletionInDays: null,
    complaintCompletionInHour: null,
    isFreezed: "FREEZED",
    aeitRemarks: null,
    modelNo: "OPTIPLEX 780",
  },
  {
    id: "6a8ebd04b038dfaf41c4b55ddf6fb5d3PVV-0524762023-09-16T10:53",
    serialNo: 41829,
    complaintNo: "PVV-052476",
    complaintDatetime: "2023-09-16T10:53:00",
    zone: "MEERUT",
    circle: "EDC BAGPAT",
    division: "ETD BAGPAT",
    complainantName: "LALIT",
    complainantDesignation: "COMPUTER OPERTAOR",
    complainantContactNo: "7906732061",
    defectiveItemName: null,
    uxb1jsi364g4453780: "4VZZ6BS",
    engineerAssigned: "PRASHANT ",
    engineerAssignedDateTime: "2023-09-16T11:23:00",
    engineerContactNo: "9389004248",
    complaintAttemptsFirstDateAndTime: null,
    complaintAttemptsSecondDateAndTime: null,
    complaintAttemptsThirdDateAndTime: null,
    locationCode: null,
    complaintCompletionDatetime: null,
    status: "OPEN",
    actionTakenAndSpareUsed: null,
    oldSerialNoMbHddTft: null,
    newSerialNoMbHddTft: null,
    remarks: null,
    projectName: "pvvnl",
    product: "DESKTOP",
    machineMake: "DELL",
    problemType: "KEYBOARD NOT WORKING",
    substation: "TEST LAB 1 DELHI SAH ROAD BARAUT BAGHPAT ",
    landmark: "BARAUT",
    pinCode: "",
    docPath: null,
    aeitStatus: "UnApproved",
    approverName: null,
    approverPhone: "BLANK",
    complaintAttendHours: null,
    complaintCompletionInDays: null,
    complaintCompletionInHour: null,
    isFreezed: "FREEZED",
    aeitRemarks: null,
    modelNo: "OPTIPLEX 780",
  },
];

export default function Table(props: any) {
  const data = props.data;

  const handleCheckBoxChange = (event: any) => {
    const isChecked = event.target.checked;
    const rowId = event.target.id;
    if (isChecked) {
      props.setSelectedRowIds(() => [...props.selectedRowIds, rowId]);
    } else {
      const index = props.selectedRowIds.indexOf(rowId);
      const idRemoved = props.selectedRowIds.splice(index, 1);
      // props.setSelectedRowIds(() => idRemoved);
    }
  };

  function removeDuplicates(arr: any) {
    return arr?.filter(
      (item: any, index: any) => item && arr?.indexOf(item) === index
    );
  }

  const handleFilterChange = (event: any) => {
    const json = { name: event.target.id, value: event.target.value };
    props.handleFilterChange(json);
  };

  function camelCaseToSpaceFormatUpperCase(camelCaseString: any) {
    const regex = /([A-Z])/g;
    const spaceFormatString = camelCaseString
      .replace(regex, " $1")
      .toUpperCase();
    return spaceFormatString;
  }

  return (
    <Box
      style={{
        height: "480px",
        fontSize: "16px",
        overflowX: "scroll",
        overflowY: "scroll",
      }}
    >
      <table>
        <thead>
          <th>Select</th>
          <th>Row</th>
          {Object.keys(data[0] ?? [])
            .slice(1)
            .map((row) => (
              <th>
                <>{row.toUpperCase()}</>
                <select
                  // className={classes.select}
                  id={row}
                  // value={row.toUpperCase()}
                  onChange={handleFilterChange}
                >
                  <option>{row.toUpperCase()}</option>
                  {removeDuplicates(data.map((json: any) => json[row])).map(
                    (value: string) => (
                      <option value={value}>{value}</option>
                    )
                  )}
                </select>
              </th>
            ))}
        </thead>
        <tbody>
          {data.map((data: any, index: number) => (
            <tr key={data.id}>
              <input
                id={data.id}
                type="checkbox"
                onChange={handleCheckBoxChange}
              ></input>
              <td>{++index}</td>
              {Object.values(data)
                .slice(1)
                .map((row: any) => (
                  <td>{row}</td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
