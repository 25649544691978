import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import { Email } from "./interfaces";
import emailMarketing from "./emailMarketing.styles";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  downloadEmailRecepients,
  downloadEmailSenders,
  downloadLogs,
  sendMarketingEmail,
} from "./EmailMarketingService";
import SendIcon from "@mui/icons-material/Send";
import { errorNotification, successNotification } from "../../global/utils";
import { useHistory } from "react-router-dom";

export default function EmailMarketing() {
  const classes = emailMarketing;
  let history = useHistory();
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState<Email>({ subject: "", content: "" });

  const handleInputChange = (event: any) => {
    setEmail({ ...email, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      setProcessing(true);
      sendMarketingEmail(email);
      successNotification(
        "Emails are currently being processed. Please note that this operation may take some time. You can download logs to check the current status."
      );
      setEmail({ subject: "", content: "" });
      setTimeout(() => {
        setProcessing(false);
      }, 15000);
    }
  };

  const handleValidation = () => {
    if (!email.subject) {
      errorNotification("Subject cannot be empty.");
      return false;
    }
    if (!email.content) {
      errorNotification("Content cannot be empty.");
      return false;
    }
    return true;
  };

  return (
    <>
      <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Send Emails
        </Typography>
        <Button onClick={downloadLogs}>Download Logs</Button>
        {/* <Button onClick={downloadEmailSenders}>Download Senders</Button> */}
        <Button onClick={downloadEmailRecepients}>Download Recepients</Button>
        <Button onClick={() => history.push("/email/marketing/config")}>
          Configuration
        </Button>
        <Box
          sx={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                variant="outlined"
                value={email.subject}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Content"
                name="content"
                multiline
                rows={4}
                value={email.content}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleSubmit}
                disabled={processing}
                variant="contained"
                endIcon={<SendIcon />}
                color="primary"
                fullWidth
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
