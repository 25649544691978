import { StringConstants } from "./StringConstants";

const prod: boolean = true;

export const webUrl = prod
  ? "https://axisinfoline.com"
  : "http://localhost:3000";

const CoreUrl = () => {
  if (!prod) {
    return "http://localhost:9000";
  } else {
    return "https://backendapi.axisinfoline.com";
  }
};

const BaseUrl = () => {
  let account = localStorage.getItem("account");
  switch (account) {
    case StringConstants.pvvnl:
      return `${CoreUrl()}/pvvnl`;
    case StringConstants.dvvnl:
      return `${CoreUrl()}/dvvnl`;
  }
};

export const UrlConstants = {
  CoreUrl: CoreUrl(),
  baseUrl: BaseUrl(),
  loginUrl: `http://localhost:8080/auth/login`,
};
