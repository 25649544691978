const trackComplaintStyle = {
  mainGrid: {
    width: "100vw",
    height: "100vh",
    display: "grid",
    placeItems: "center",
    background: "linear-gradient(to right, #ff6b6b, #ffc0cb)",
  },
  innerGrid: {
    display: "flex",
  },
  paper: {
    width: "95vw",
    height: "25vh",
    borderRadius: "20px",
  },
  input: {
    height: "25px",
    fontSize: "16px",
    border: "none",
    borderRadius: "10px",
    backgroundColor: "#fce8e1",
  },
  button: {
    color: "white",
    width: "160px",
    fontWeight: "600",
    border: "2px solid white",
    borderRadius: "100px",
    background: "linear-gradient(to right, #ff6b6b, #ffc0cb)",
  },
};

export default trackComplaintStyle;
