import { Button, Grid, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { useHistory } from "react-router-dom";
import CustomTable from "../../global/CustomTable/CustomTable";
import { useEffect, useMemo, useState } from "react";
import { deleteAeitAPI, getAeitByStatus } from "./AeitService";
import { GridRenderCellParams } from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./ResetPassword";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Box, Chip, Typography } from "@material-ui/core";
import { UrlConstants } from "../../global/UrlConstants";
import { StringConstants } from "../../global/StringConstants";

// const rows = [...AietData];

export default function Aiet() {
  let history = useHistory();
  const [rows, setRows] = useState([]);
  const [tabValue, setTabValue] = useState("Active");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedEmployee, setSelectedEmployeeId] = useState();
  const [circleOption, setCircleOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [nameOption, setNameOption] = useState([]);
  const [phoneOption, setPhoneOption] = useState([]);
  const [statusOption, setStatusOption] = useState([]);

  useEffect(() => {
    document.title = "AEIT";
    getEngineers();
  }, [tabValue]);

  const getEngineers = async () => {
    setLoader(true);
    let response;
    if (tabValue === "Active") {
      response = await getAeitByStatus("Active");
      response.map((data: any) => {
        data.serialNo = data.id;
      });
    } else if (tabValue === "Inactive") {
      response = await getAeitByStatus("Inactive");
      response.map((data: any) => {
        data.serialNo = data.id;
      });
    }
    setRows(response ?? []);
    setDropDownOptions(response);
    setLoader(false);
  };

  const setDropDownOptions = (response: any) => {
    const circle = response.map((r: any) => r.circle);
    setCircleOption(removeDuplicates(circle));
    const city = response.map((r: any) => r.city);
    setCityOption(removeDuplicates(city));
    const name = response.map((r: any) => r.name);
    setNameOption(removeDuplicates(name));
    const phone = response.map((r: any) => r.phone);
    setPhoneOption(removeDuplicates(phone));
    const status = response.map((r: any) => r.status);
    setStatusOption(removeDuplicates(status));
  };

  function removeDuplicates(arr: any) {
    return arr?.filter(
      (item: any, index: any) => item && arr?.indexOf(item) === index
    );
  }

  const handleOnClick = () => {
    history.push("/add" + StringConstants.aeit);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const columns = [
    {
      accessorKey: "name",
      header: `${StringConstants.aeit} Name`,
      size: 220,
      filterVariant: "select",
      filterSelectOptions: nameOption,
    },
    {
      accessorKey: "phone",
      header: `${StringConstants.aeit} Username`,
      size: 240,
      filterVariant: "select",
      filterSelectOptions: phoneOption,
    },
    {
      accessorKey: "circle",
      header: "Circle (For Tickets)",
      size: 360,
      filterVariant: "select",
      filterSelectOptions: circleOption,
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.circle.includes(",") ? (
          JSON.parse(cell.row.original.circle)?.map((name: string) => (
            <Chip label={name} />
          ))
        ) : (
          <Chip label={JSON.parse(cell.row.original.circle)} />
        ),
    },
    {
      accessorKey: "city",
      header: "Circle (For Survey)",
      size: 260,
      Cell: (cell: GridRenderCellParams) =>
        cell.row.original.city.includes(",") ? (
          JSON.parse(cell.row.original.city)?.map((name: string) => (
            <Chip label={name} />
          ))
        ) : (
          <Chip label={JSON.parse(cell.row.original.city)} />
        ),
      filterVariant: "select",
      filterSelectOptions: cityOption,
    },
    {
      accessorKey: "status",
      header: "Status",
      width: 420,
      filterVariant: "select",
      filterSelectOptions: statusOption,
    },
    {
      accessorKey: "edit",
      enableColumnFilter: false,
      header: "Edit",
      width: 100,
      Cell: (cell: GridRenderCellParams) => (
        <strong>
          <IconButton
            style={{ marginLeft: 2, color: "#0000FF" }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              editRow(cell.row.original);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </strong>
      ),
    },
    {
      header: "Reset Password",
      width: 100,
      Cell: (cell: GridRenderCellParams) => (
        <strong>
          <Button
            size="small"
            onClick={() => {
              resetPasswordRow(cell.row.original);
            }}
          >
            <Typography
              variant="caption"
              style={{ marginLeft: 2, color: "#e03a3c" }}
            >
              Reset Password
            </Typography>
          </Button>
        </strong>
      ),
    },
    {
      header: "Delete",
      width: 100,
      Cell: (cell: GridRenderCellParams) => (
        <strong>
          <IconButton
            size="small"
            style={{ marginLeft: 2 }}
            tabIndex={cell.hasFocus ? 0 : -1}
            onClick={() => {
              deleteRow(cell.row.original);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </strong>
      ),
    },
  ];

  const editRow = (event: any) => {
    history.push("/add" + StringConstants.aeit, { data: event });
  };

  const resetPasswordRow = (row: any) => {
    setOpen(true);
    setSelectedEmployeeId(row);
  };

  const deleteRow = (row: any) => {
    const confirmBox = window.confirm(
      `Do you want to delete Employee: ${row.name} [ ${row.phone} ]`
    );
    if (confirmBox === true) {
      const secondConfirmBox = window.confirm(
        `You can't recover this Employee. Do you really want to delete Employee: ${row.name} [ ${row.phone} ]`
      );
      if (secondConfirmBox === true) {
        const secondConfirmBox = window.confirm(
          `Final Confirmation to delete Employee: ${row.name} [ ${row.phone} ]`
        );
        if (secondConfirmBox === true) {
          const response = deleteAeitAPI(row);
          response.then(function (response) {
            toast.success("Successfully Deleted!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            window.location.reload();
          });
        }
      }
    }
  };

  const handleClose = () => {
    // onClose(selectedValue);
  };

  return (
    <Box overflow={"scroll"} style={{ height: "550px" }}>
      <div style={{ maxWidth: "100%" }}>
        <Grid
          lg={12}
          sm={12}
          xs={12}
          item
          container
          spacing={2}
          style={{ marginTop: 2 }}
        >
          <Grid item lg={6} sm={6} xs={6}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
              TabIndicatorProps={{ style: { background: "#e03a3c" } }}
              aria-label="secondary tabs example"
            >
              <Tab value="Active" label="Active" />
              <Tab value="Inactive" label="Inactive" />
            </Tabs>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Stack
              spacing={2}
              direction="row"
              style={{
                float: "right",
                color: "blue",
                paddingRight: 20,
              }}
            >
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#f44336",
                  marginTop: 5,
                  // marginRight: 4,
                  // marginBottom: 20,
                  minWidth: 120,
                  // padding: 5,
                }}
                variant="outlined"
                onClick={handleOnClick}
              >
                Add {StringConstants.aeit}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid lg={12} sm={12} xs={12} item container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <CustomTable loader={loader} data={rows} columns={columns} />
          </Grid>
        </Grid>
        <Grid lg={12} sm={12} xs={12} item container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <ResetPassword
              open={open}
              onClose={handleClose}
              selectedEmployee={selectedEmployee}
            />
          </Grid>
        </Grid>
        <ToastContainer />
      </div>
    </Box>
  );
}
