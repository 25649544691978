import { useEffect, useState } from "react";
import CustomCard from "../../global/CustomCard/CustomCard";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Chip, Typography } from "@mui/material";
import moment from "moment";
import {
  getHarwareCountsAPI,
  getTicketCountsAPI,
} from "./AdminDashboardService";

export default function AdminDashboard() {
  let history = useHistory();
  const [ticketCount, setTicketCount] = useState({
    OPEN: 0,
    CLOSED: 0,
    currentMonthCreatedTicket: 0,
    currentMonthClosedTicket: 0,
    todayCreatedTicket: 0,
    todayClosedTicket: 0,
    activeEngineers: 0,
    totalSurvey: 0,
    totalCircles: 0,
    adminDocuments: 0,
    freezeItemCount: 0,
    pivotTableCircle: 0,
  });

  const [hardwareCount, setHardwareCount] = useState({
    totalSurveyHardwareCount: [],
    totalFreezeHardwareCount: [],
  });

  useEffect(() => {
    document.title = "Dashboard";
    getTicketCounts();
    getHarwareCounts();
  }, []);

  const getTicketCounts = async () => {
    const response = await getTicketCountsAPI();
    setTicketCount(response);
  };

  const getHarwareCounts = async () => {
    const response = await getHarwareCountsAPI();
    setHardwareCount(response);
  };

  const handleSurveyRedirectWithHardwareFilter = async (itemName: any) => {
    history.push("/survey", { itemName: itemName });
  };

  const handleFreezeRedirectWithHardwareFilter = async (itemName: any) => {
    history.push("/freezeItems", { itemName: itemName });
  };

  const handleTicketRedirection = async (
    fromDate: any,
    toDate: any,
    status: any
  ) => {
    const format = "YYYY-MM-DD";
    const dateRange = [
      moment(fromDate).format(format),
      moment(toDate).format(format),
    ];
    history.push("/tickets", { dateRange: dateRange, status: status });
  };

  const ticketFrom1July = () => {
    return (
      <CustomCard
        cardHeight="8rem"
        cardColor="#fcffde"
        titleComponent={
          <Typography
            variant="h5"
            onClick={() =>
              handleTicketRedirection(
                "2023-03-05",
                moment().format("YYYY-MM-DD"),
                "ALL"
              )
            }
          >
            {"Tickets From 1 July is "}{" "}
            {(ticketCount?.OPEN ?? 0) + (ticketCount?.CLOSED ?? 0)}
          </Typography>
        }
        subtitle={
          <>
            <Chip
              size="small"
              label={`Open: ${ticketCount?.OPEN ?? 0}`}
              onClick={() =>
                handleTicketRedirection(
                  "2023-03-05",
                  moment().format("YYYY-MM-DD"),
                  "OPEN"
                )
              }
              style={{ color: "white", backgroundColor: "#a1a118" }}
            />
            <Chip
              size="small"
              label={`Closed: ${ticketCount?.CLOSED ?? 0}`}
              onClick={() =>
                handleTicketRedirection(
                  "2023-03-05",
                  moment().format("YYYY-MM-DD"),
                  "CLOSED"
                )
              }
              style={{
                margin: "0.1rem",
                color: "white",
                backgroundColor: "#a1a118",
              }}
            />
          </>
        }
      ></CustomCard>
    );
  };

  const createdTicketThisMonth = () => {
    return (
      <CustomCard
        cardHeight="8rem"
        color="#141414"
        cardColor="#ffeade"
        titleComponent={
          <Typography
            variant="h5"
            onClick={() =>
              handleTicketRedirection(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 1,
                  0
                ),
                "ALL"
              )
            }
          >
            {"Created Tickets In Current Month"}
          </Typography>
        }
        subtitle={
          <>
            <Chip
              size="small"
              label={`In ${moment().format("MMMM")} Month: ${
                ticketCount.currentMonthCreatedTicket
              }`}
              onClick={() =>
                handleTicketRedirection(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  ),
                  "ALL"
                )
              }
              style={{ color: "white", backgroundColor: "#f75348" }}
            />
            <Chip
              size="small"
              label={`Today: ${ticketCount.todayCreatedTicket}`}
              onClick={() =>
                handleTicketRedirection(new Date(), new Date(), "ALL")
              }
              style={{
                margin: "0.1rem",
                color: "white",
                backgroundColor: "#f75348",
              }}
            />
          </>
        }
      ></CustomCard>
    );
  };

  const closedTicketThisMonth = () => {
    return (
      <CustomCard
        cardHeight="8rem"
        // title="Closed Tickets"
        cardColor="#e8ffde"
        // count={ticketCount.currentMonthClosedTicket}
        titleComponent={
          <Typography
            variant="h5"
            onClick={() =>
              history.push("/tickets", {
                dateRange: ["2023-03-05", moment().format("YYYY-MM-DD")],
                status: "CLOSED",
              })
            }
          >
            {"Closed Tickets In Current Month"}
          </Typography>
        }
        subtitle={
          <>
            <Chip
              size="small"
              label={`In ${moment().format("MMMM")} Month: ${
                ticketCount.currentMonthClosedTicket
              }`}
              onClick={() =>
                handleTicketRedirection(
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  ),
                  "CLOSED"
                )
              }
              style={{ color: "white", backgroundColor: "#3a941c" }}
            />
            <Chip
              size="small"
              label={`Today: ${ticketCount.todayClosedTicket}`}
              onClick={() =>
                handleTicketRedirection(new Date(), new Date(), "CLOSED")
              }
              style={{
                margin: "0.1rem",
                color: "white",
                backgroundColor: "#3a941c",
              }}
            />
          </>
        }
      ></CustomCard>
    );
  };

  const surveyCount = () => {
    return (
      <CustomCard
        // title="Total Survey"
        cardColor="#e8ffde"
        cardHeight="15rem"
        count={ticketCount?.totalSurvey ?? 0}
        // onClick={() => history.push("/survey")}
        titleComponent={
          <Typography variant="h5" onClick={() => history.push("/survey", {})}>
            {"Total Survey"} {ticketCount?.totalSurvey ?? 0}
          </Typography>
        }
        hardwareCount={
          <>
            {hardwareCount?.totalSurveyHardwareCount?.map(
              (data: any, index: any) => {
                return (
                  <Chip
                    size="small"
                    label={`${data?.name}: ${data?.count}`}
                    onClick={() => {
                      handleSurveyRedirectWithHardwareFilter(data.name);
                    }}
                    style={{
                      margin: "0.1rem",
                      color: "white",
                      backgroundColor: "#3a941c",
                    }}
                  />
                );
              }
            )}
          </>
        }
      />
    );
  };

  const assetUnderAMC = () => {
    return (
      <CustomCard
        cardHeight="10rem"
        // title="Total Assets"
        color="#141414"
        count={ticketCount?.freezeItemCount ?? 0}
        // onClick={() => history.push("/freezeItems")}
        titleComponent={
          <Typography
            variant="h5"
            onClick={() => history.push("/freezeItems", {})}
          >
            {"Assets under AMC"} {ticketCount?.freezeItemCount ?? 0}
          </Typography>
        }
        hardwareCount={
          <>
            {hardwareCount?.totalFreezeHardwareCount?.map(
              (data: any, index: any) => {
                return (
                  <Chip
                    size="small"
                    label={`${data?.name}: ${data?.count}`}
                    onClick={() => {
                      handleFreezeRedirectWithHardwareFilter(data.name);
                    }}
                    style={{
                      margin: "0.1rem",
                      color: "white",
                      backgroundColor: "#686968",
                    }}
                  />
                );
              }
            )}
          </>
        }
      ></CustomCard>
    );
  };

  const assetSummaryUnderAMC = () => {
    return (
      <CustomCard
        cardHeight="10rem"
        cardColor="#fcffde"
        onClick={() => history.push("/pivotTable")}
        titleComponent={
          <Typography
            variant="h5"
            onClick={() => history.push("/freezeItems", {})}
          >
            {"Assets Summary Under AMC"}{" "}
          </Typography>
        }
        subtitle={
          <Typography
            variant="h5"
            onClick={() => history.push("/freezeItems", {})}
          >
            {ticketCount?.pivotTableCircle ?? 0}
          </Typography>
        }
      ></CustomCard>
    );
  };

  return (
    <Box style={{ backgroundColor: "#ffffff" }}>
      <Grid
        container
        spacing={2}
        style={{ marginTop: 10, backgroundColor: "#ffffff" }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {ticketFrom1July()}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {createdTicketThisMonth()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          {closedTicketThisMonth()}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {surveyCount()}
        </Grid>
        {localStorage.getItem("account") !== "dvvnl" && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              {assetUnderAMC()}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              {assetSummaryUnderAMC()}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
