import { webUrl } from "./UrlConstants";

export const redirectToLoginPage = () => {
  window.location.replace(webUrl);
};

export const logOut = async () => {
  localStorage.clear();
  redirectToLoginPage(); // redirectToLoginPage() method not working
};
