import {
    Alert,
    AlertColor,
    Badge,
    Box,
    IconButton,
    Popper,
    Fade,
    Button,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    Stack,
    Chip,
    Paper
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { UrlConstants } from "../UrlConstants";
import axios from "axios";
import moment from "moment";
import zIndex from "@mui/material/styles/zIndex";
import { getUnReadCountAPI, getUnReadNotification, newsMarkAsRead } from "./CustomNotificationService";

const types = ["success", "info", "warning", "error"];

export default function CustomNotification() {
    const {
        notifications,
        markAsRead,
    } = useNotificationCenter();
    const [unreadCount, setUnreadCount] = useState(0);
    const [unreadNews, setUnreadNews] = useState([]);
    const [readNews, setReadNews] = useState([]);
    const [showUnreadOnly, setShowUnreadOnly] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isAdmin = localStorage.getItem("role") === "Admin";
    const isSuperAdmin = localStorage.getItem("role") === "superAdmin";
    const isAdminSuperAdmin = (isAdmin || isSuperAdmin) ? true : false;
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const toggleNotificationCenter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };

    const toggleFilter = (e: React.ChangeEvent) => {
        setShowUnreadOnly(!showUnreadOnly);
    };

    useEffect(() => {
        if (!isDataLoaded) {
            if (unreadCount == 0) {
                geUnReadCount();
                setIsDataLoaded(true)
            }
            if (unreadNews.length == 0) {
                getUnReadNews();
            }
            // if (readNews.length == 0) {
            //     getReadNews();
            // }
        }
    }, [unreadCount, unreadNews]);

    const geUnReadCount = async () => {
        if (isAdminSuperAdmin) {
            const response = await getUnReadCountAPI();
            setUnreadCount(() => response);
        }
    };

    const getUnReadNews = async () => {
        if (isAdminSuperAdmin) {
            const response = await getUnReadNotification();
            setUnreadNews(response);
        }
    };

    const handleClose = async (data: any) => {
        newsMarkAsRead(data);
        await getUnReadNews();
        await geUnReadCount()
    }

    return (
        < >
            <IconButton onClick={toggleNotificationCenter}>
                <Badge badgeContent={unreadCount} color="primary">
                    <MailIcon color="action" />
                </Badge>
            </IconButton>
            <Popper open={isOpen} anchorEl={anchorEl} transition style={{ zIndex: "9999999", marginTop: 5 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box>
                            <Box
                                sx={{
                                    background: "#666",
                                    padding: "8px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <Typography variant="h5" color="#fff">
                                    Latest Update
                                </Typography>
                                {/* <FormGroup sx={{ color: "#fff" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="secondary"
                                                onChange={toggleFilter}
                                                checked={showUnreadOnly}
                                            />
                                        }
                                        label="Show unread only"
                                    />
                                </FormGroup> */}
                            </Box>
                            <Stack
                                sx={{
                                    height: "400px",
                                    width: "min(60ch, 100ch)",
                                    padding: "12px",
                                    background: "#f1f1f1",
                                    borderRadius: "8px",
                                    overflowY: "auto"
                                }}
                                spacing={2}
                            >
                                {(
                                    (unreadCount === 0 && showUnreadOnly)) && (
                                        <h4>
                                            Your queue is empty! you are all set{" "}
                                            <span role="img" aria-label="dunno what to put">
                                                🎉
                                            </span>
                                        </h4>
                                    )}

                                {unreadNews?.map((data: any, index: any) => {
                                    return (<Paper variant="outlined" style={{ padding: "0.8rem" }} >
                                        <Box>
                                            <label
                                                style={{ color: "blue", backgroundColor: "white" }}
                                            >{data?.description}</label>
                                        </Box>

                                        <Chip
                                            size="small"
                                            label={`Close`}
                                            onClick={() => {
                                                handleClose(data);
                                            }}
                                            style={{ color: "green", backgroundColor: "white" }}
                                        />
                                    </Paper>)
                                })}


                            </Stack>
                            <Box
                                sx={{
                                    background: "#666",
                                    padding: "8px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                            </Box>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </>
    );
}

